import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './../layout.css';
import './../homeactivity.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDealData } from './../homeactivityactions';
import { Col, Row } from 'reactstrap';
import CountdownTimer from './../countdowntimer';

export const HighlightsHeader = props => {
  const dealsData = useSelector((state: any) => state.homeActivityReducer.dealData);
  const dispatch = useDispatch();

  useEffect(() => {
    //fetchDealData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
              <a href={`experience/${props?.deals?.[0]?.activity?.id}`}>
                <h4 className="text-center mb-3 font-weight-bold">DEAL OF THE DAY</h4>
              </a>
              <a href={`experience/${props?.deals?.[0]?.activity?.id}`}>
                <img src={props?.deals?.[0]?.dealImageOneUrl} className="img-fluid " alt="" />
              </a>
              <div className="row-timer">
                <a href={`experience/${props?.deals?.[0]?.activity?.id}`} style={{ width: '100%' }}>
                  <h5 className="mt-2 h5-timer">{props?.deals?.[0]?.name?.toUpperCase()}</h5>
                </a>
                {props?.deals?.[0]?.endDateTime && <CountdownTimer futureDateTime={new Date(props?.deals?.[0]?.endDateTime)} />}
              </div>
              <a href={`experience/${props?.deals?.[0]?.activity?.id}`}>{props?.deals?.[0]?.dealSummary}</a>
              <h6>
                <Link style={null} to={`experience/${props?.deals?.[1]?.activity?.id}`}></Link>
              </h6>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <a href={`experience/${props?.deals?.[1]?.activity?.id}`}>
                <h4 className="text-center mb-3 font-weight-bold">MOST POPULAR</h4>
                <img src={props?.deals?.[1]?.dealImageOneUrl} className="img-fluid" alt="" />
                <h5 className="mt-2">{props?.deals?.[1]?.name?.toUpperCase()}</h5>
                <h6>{props?.deals?.[1]?.dealSummary}</h6>
              </a>
              <h6>
                <Link style={null} to={`experience/${props?.deals?.[1]?.activity?.id}`}></Link>
              </h6>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <a href={`experience/${props?.deals?.[2]?.activity?.id}`}>
                <h4 className="text-center mb-3 font-weight-bold">MOST LIKED</h4>
                <img src={props?.deals?.[2]?.dealImageOneUrl} className="img-fluid" alt="" />
                <h5 className="mt-2">{props?.deals?.[2]?.name?.toUpperCase()}</h5>
                <h6>{props?.deals?.[2]?.dealSummary}</h6>
              </a>
              <h6>
                <Link style={null} to={`experience/${props?.deals?.[2]?.activity?.id}`}></Link>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default HighlightsHeader;
