import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'app/shared/util/tailwind';

const buttonVariants = cva(
  'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-text-sm tw-font-medium tw-transition-colors focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-sky-950 disabled:tw-pointer-events-none disabled:tw-opacity-50 dark:tw-focus-visible:tw-ring-sky-500',
  {
    variants: {
      variant: {
        default: 'tw-bg-sky-500 tw-text-sky-50 hover:tw-bg-sky-600 ',
        destructive: 'tw-bg-red-500 tw-text-sky-50  hover:tw-bg-red-500/90 ',
        outline: 'tw-border tw-border-sky-500 tw-bg-transparent tw-text-sky-500  hover:tw-bg-sky-100 ',
        secondary: 'tw-bg-sky-100 tw-text-sky-900  hover:tw-bg-sky-100/80',
        ghost: 'hover:tw-bg-sky-100 hover:tw-text-sky-900 dark:tw-hover:bg-sky-800 dark:tw-hover:text-sky-50',
        link: 'tw-text-sky-900 tw-underline-offset-4 hover:tw-underline dark:tw-text-sky-50',
      },
      size: {
        default: 'tw-h-9 tw-px-4 tw-py-2',
        sm: 'tw-h-8  tw-px-3 tw-text-xs',
        lg: 'tw-h-10  tw-px-8',
        icon: 'tw-h-9 tw-w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
