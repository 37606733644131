import { IWeekDayTimeAvailability } from 'app/shared/model/week-day-time-availability.model';
import { IActivitySchedule } from 'app/shared/model/activity-schedule.model';
import { IBooking } from 'app/shared/model/booking.model';
import { WeekDay } from 'app/shared/model/enumerations/week-day.model';

export interface IWeekDayTime {
  id?: number;
  weekDay?: WeekDay | null;
  fromTime24H?: string | null;
  toTime24H?: string | null;
  duration?: number | null;
  adultCount?: number | null;
  childCount?: number | null;
  infantCount?: number | null;
  weekDayTimeAvailabilities?: IWeekDayTimeAvailability[] | null;
  activitySchedule?: IActivitySchedule | null;
  bookings?: IBooking[] | null;
}

export const defaultValue: Readonly<IWeekDayTime> = {};
