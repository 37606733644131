import { IDiningDetails } from 'app/shared/model/dining-details.model';

export interface ICusine {
  id?: number;
  active?: boolean | null;
  cusineCode?: string | null;
  cusineName?: string | null;
  cusine?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  diningDetails?: IDiningDetails[] | null;
}

export const defaultValue: Readonly<ICusine> = {
  active: false,
};
