/* eslint-disable @next/next/no-img-element */
import React from 'react';

const Header = () => {
  return (
    <div className="">
      <div className="tw-bg-gray-300 tw-flex tw-justify-center tw-items-center tw-py-1">
        <a className="tw-text-xs tw-text-gray-900 tw-cursor-pointer tw-no-underline tw-font-semibold">SAVE ON BALI EXPERIENCE</a>
      </div>

      <div className="tw-relative tw-h-[120px]">
        <img
          src="https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?auto=format&fit=crop&q=80&w=3270&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="banner"
          className="tw-h-[120px] tw-object-cover tw-w-[100%] tw-opacity-30"
        />
        <h4 className="tw-absolute tw-font-semibold tw-text-3xl tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 ">
          EXPERIENCE BALI
        </h4>
      </div>
    </div>
  );
};

export default Header;
