import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Emailing from './emailing';
import EmailingDetail from './emailing-detail';
import EmailingUpdate from './emailing-update';
import EmailingDeleteDialog from './emailing-delete-dialog';

const EmailingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Emailing />} />
    <Route path="new" element={<EmailingUpdate />} />
    <Route path=":id">
      <Route index element={<EmailingDetail />} />
      <Route path="edit" element={<EmailingUpdate />} />
      <Route path="delete" element={<EmailingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmailingRoutes;
