import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './dining-details.reducer';

export const DiningDetailsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const diningDetailsEntity = useAppSelector(state => state.diningDetails.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="diningDetailsDetailsHeading">
          <Translate contentKey="activitiesApp.diningDetails.detail.title">DiningDetails</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="activitiesApp.diningDetails.name">Name</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.name}</dd>
          <dt>
            <span id="about">
              <Translate contentKey="activitiesApp.diningDetails.about">About</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.about}</dd>
          <dt>
            <span id="priceRange">
              <Translate contentKey="activitiesApp.diningDetails.priceRange">Price Range</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.priceRange}</dd>
          <dt>
            <span id="features">
              <Translate contentKey="activitiesApp.diningDetails.features">Features</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.features}</dd>
          <dt>
            <span id="specialDiets">
              <Translate contentKey="activitiesApp.diningDetails.specialDiets">Special Diets</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.specialDiets}</dd>
          <dt>
            <span id="notes">
              <Translate contentKey="activitiesApp.diningDetails.notes">Notes</Translate>
            </span>
          </dt>
          <dd>{diningDetailsEntity.notes}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="activitiesApp.diningDetails.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {diningDetailsEntity.createdDate ? (
              <TextFormat value={diningDetailsEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="activitiesApp.diningDetails.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {diningDetailsEntity.modifiedDate ? (
              <TextFormat value={diningDetailsEntity.modifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.diningDetails.mealType">Meal Type</Translate>
          </dt>
          <dd>
            {diningDetailsEntity.mealTypes
              ? diningDetailsEntity.mealTypes.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.mealTypeName}</a>
                    {diningDetailsEntity.mealTypes && i === diningDetailsEntity.mealTypes.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.diningDetails.cusine">Cusine</Translate>
          </dt>
          <dd>
            {diningDetailsEntity.cusines
              ? diningDetailsEntity.cusines.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.cusineName}</a>
                    {diningDetailsEntity.cusines && i === diningDetailsEntity.cusines.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/dining-details" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/dining-details/${diningDetailsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DiningDetailsDetail;
