import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import WeekDayTime from './week-day-time';
import WeekDayTimeDetail from './week-day-time-detail';
import WeekDayTimeUpdate from './week-day-time-update';
import WeekDayTimeDeleteDialog from './week-day-time-delete-dialog';

const WeekDayTimeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<WeekDayTime />} />
    <Route path="new" element={<WeekDayTimeUpdate />} />
    <Route path=":id">
      <Route index element={<WeekDayTimeDetail />} />
      <Route path="edit" element={<WeekDayTimeUpdate />} />
      <Route path="delete" element={<WeekDayTimeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default WeekDayTimeRoutes;
