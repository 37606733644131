import { useState } from 'react';
import React, { useEffect } from 'react';

const BookingTimer = ({ createdDate }) => {
  // const [inputDate, setInputDate] = useState(createdDate)
  //   const [seconds, setSeconds] = useState(600);
  //   const [isActive, setIsActive] = useState(true);

  //   useEffect(() => {
  //       let interval;

  //       if (isActive && seconds > 0) {
  //       interval = setInterval(() => {
  //           setSeconds((prevSeconds) => prevSeconds - 1);
  //       }, 1000);
  //       } else {
  //       clearInterval(interval);
  //       }

  //       return () => clearInterval(interval);
  // }, [isActive, seconds]);

  // const formatTime = (time) => {
  //   const minutes = Math.floor(time / 60);
  //   const remainingSeconds = time % 60;
  //   return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  // };

  // return (
  //   <div>

  //     <div>{formatTime(seconds)}</div>
  //     <span> remaining to confirm the booking</span>
  //   </div>
  // );

  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    const countdownDate = new Date(createdDate).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate + 10 * 60 * 1000 - now;

      // Calculate time remaining in minutes and seconds
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Calculate total time remaining in seconds
      const totalTimeRemaining = minutes * 60 + seconds;
      // Update state with time remaining
      setTimeRemaining(totalTimeRemaining);

      if (totalTimeRemaining <= 0) {
        clearInterval(interval);
        setTimeRemaining(0);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [createdDate]);

  // Format the time remaining into minutes and seconds
  const displayMinutes = Math.floor(timeRemaining / 60);
  const displaySeconds = timeRemaining % 60;

  return (
    <div>
      <p>
        {displayMinutes < 10 ? `0${displayMinutes}` : displayMinutes}:{displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds}
      </p>
      <span> remaining to confirm the booking</span>
    </div>
  );
};

export default BookingTimer;
