import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Cusine from './cusine';
import CusineDetail from './cusine-detail';
import CusineUpdate from './cusine-update';
import CusineDeleteDialog from './cusine-delete-dialog';

const CusineRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Cusine />} />
    <Route path="new" element={<CusineUpdate />} />
    <Route path=":id">
      <Route index element={<CusineDetail />} />
      <Route path="edit" element={<CusineUpdate />} />
      <Route path="delete" element={<CusineDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CusineRoutes;
