import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emailing.reducer';

export const EmailingDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const emailingEntity = useAppSelector(state => state.emailing.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="emailingDetailsHeading">
          <Translate contentKey="activitiesApp.emailing.detail.title">Emailing</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.id}</dd>
          <dt>
            <span id="dateCreated">
              <Translate contentKey="activitiesApp.emailing.dateCreated">Date Created</Translate>
            </span>
          </dt>
          <dd>
            {emailingEntity.dateCreated ? <TextFormat value={emailingEntity.dateCreated} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="bookingId">
              <Translate contentKey="activitiesApp.emailing.bookingId">Booking Id</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.bookingId}</dd>
          <dt>
            <span id="content">
              <Translate contentKey="activitiesApp.emailing.content">Content</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.content}</dd>
          <dt>
            <span id="activityId">
              <Translate contentKey="activitiesApp.emailing.activityId">Activity Id</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.activityId}</dd>
          <dt>
            <span id="fromAddress">
              <Translate contentKey="activitiesApp.emailing.fromAddress">From Address</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.fromAddress}</dd>
          <dt>
            <span id="toAddress">
              <Translate contentKey="activitiesApp.emailing.toAddress">To Address</Translate>
            </span>
          </dt>
          <dd>{emailingEntity.toAddress}</dd>
        </dl>
        <Button tag={Link} to="/emailing" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emailing/${emailingEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmailingDetail;
