import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Keyword from './keyword';
import KeywordDetail from './keyword-detail';
import KeywordUpdate from './keyword-update';
import KeywordDeleteDialog from './keyword-delete-dialog';

const KeywordRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Keyword />} />
    <Route path="new" element={<KeywordUpdate />} />
    <Route path=":id">
      <Route index element={<KeywordDetail />} />
      <Route path="edit" element={<KeywordUpdate />} />
      <Route path="delete" element={<KeywordDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default KeywordRoutes;
