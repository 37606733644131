import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IActivity } from 'app/shared/model/activity.model';
import { ICity } from 'app/shared/model/city.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { DealCategory } from 'app/shared/model/enumerations/deal-category.model';

export interface IDeal {
  id?: number;
  name?: string | null;
  startDateTime?: string | null;
  endDateTime?: string | null;
  activityType?: ActivityType | null;
  dealCategory?: DealCategory | null;
  dealImageOneContentType?: string | null;
  dealImageOne?: string | null;
  dealImageTwoContentType?: string | null;
  dealImageTwo?: string | null;
  dealImageThreeContentType?: string | null;
  dealImageThree?: string | null;
  dealImageOneUrl?: string | null;
  dealImageTwoUrl?: string | null;
  dealImageThreeUrl?: string | null;
  dealSummary?: string | null;
  dealDescription?: string | null;
  dealUrl?: string | null;
  user?: IUser | null;
  activity?: IActivity | null;
  city?: ICity | null;
}

export const defaultValue: Readonly<IDeal> = {};
