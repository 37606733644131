import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { ICity } from 'app/shared/model/city.model';
import { getEntities as getCities } from 'app/entities/city/city.reducer';
import { IDeal } from 'app/shared/model/deal.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { DealCategory } from 'app/shared/model/enumerations/deal-category.model';
import { getEntity, updateEntity, createEntity, reset } from './deal.reducer';

export const DealUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const activities = useAppSelector(state => state.activity.entities);
  const cities = useAppSelector(state => state.city.entities);
  const dealEntity = useAppSelector(state => state.deal.entity);
  const loading = useAppSelector(state => state.deal.loading);
  const updating = useAppSelector(state => state.deal.updating);
  const updateSuccess = useAppSelector(state => state.deal.updateSuccess);
  const activityTypeValues = Object.keys(ActivityType);
  const dealCategoryValues = Object.keys(DealCategory);

  const handleClose = () => {
    navigate('/deal' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getActivities({}));
    dispatch(getCities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.startDateTime = convertDateTimeToServer(values.startDateTime);
    values.endDateTime = convertDateTimeToServer(values.endDateTime);

    const entity = {
      ...dealEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user.toString()),
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
      city: cities.find(it => it.id.toString() === values.city.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          startDateTime: displayDefaultDateTime(),
          endDateTime: displayDefaultDateTime(),
        }
      : {
          activityType: 'ADVENTURE',
          dealCategory: 'DEAL_OF_THE_DAY',
          ...dealEntity,
          startDateTime: convertDateTimeFromServer(dealEntity.startDateTime),
          endDateTime: convertDateTimeFromServer(dealEntity.endDateTime),
          user: dealEntity?.user?.id,
          activity: dealEntity?.activity?.id,
          city: dealEntity?.city?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.deal.home.createOrEditLabel" data-cy="DealCreateUpdateHeading">
            <Translate contentKey="activitiesApp.deal.home.createOrEditLabel">Create or edit a Deal</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="deal-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('activitiesApp.deal.name')} id="deal-name" name="name" data-cy="name" type="text" />
              <ValidatedField
                label={translate('activitiesApp.deal.startDateTime')}
                id="deal-startDateTime"
                name="startDateTime"
                data-cy="startDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.endDateTime')}
                id="deal-endDateTime"
                name="endDateTime"
                data-cy="endDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.activityType')}
                id="deal-activityType"
                name="activityType"
                data-cy="activityType"
                type="select"
              >
                {activityTypeValues.map(activityType => (
                  <option value={activityType} key={activityType}>
                    {translate('activitiesApp.ActivityType.' + activityType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.deal.dealCategory')}
                id="deal-dealCategory"
                name="dealCategory"
                data-cy="dealCategory"
                type="select"
              >
                {dealCategoryValues.map(dealCategory => (
                  <option value={dealCategory} key={dealCategory}>
                    {translate('activitiesApp.DealCategory.' + dealCategory)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedBlobField
                label={translate('activitiesApp.deal.dealImageOne')}
                id="deal-dealImageOne"
                name="dealImageOne"
                data-cy="dealImageOne"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.deal.dealImageTwo')}
                id="deal-dealImageTwo"
                name="dealImageTwo"
                data-cy="dealImageTwo"
                isImage
                accept="image/*"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.deal.dealImageThree')}
                id="deal-dealImageThree"
                name="dealImageThree"
                data-cy="dealImageThree"
                isImage
                accept="image/*"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealImageOneUrl')}
                id="deal-dealImageOneUrl"
                name="dealImageOneUrl"
                data-cy="dealImageOneUrl"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealImageTwoUrl')}
                id="deal-dealImageTwoUrl"
                name="dealImageTwoUrl"
                data-cy="dealImageTwoUrl"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealImageThreeUrl')}
                id="deal-dealImageThreeUrl"
                name="dealImageThreeUrl"
                data-cy="dealImageThreeUrl"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealSummary')}
                id="deal-dealSummary"
                name="dealSummary"
                data-cy="dealSummary"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealDescription')}
                id="deal-dealDescription"
                name="dealDescription"
                data-cy="dealDescription"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.deal.dealUrl')}
                id="deal-dealUrl"
                name="dealUrl"
                data-cy="dealUrl"
                type="text"
              />
              <ValidatedField id="deal-user" name="user" data-cy="user" label={translate('activitiesApp.deal.user')} type="select">
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="deal-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.deal.activity')}
                type="select"
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="deal-city" name="city" data-cy="city" label={translate('activitiesApp.deal.city')} type="select">
                <option value="" key="0" />
                {cities
                  ? cities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.cityName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/deal" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DealUpdate;
