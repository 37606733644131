export enum Hour {
  ZERO = '00',
  ONE = '01',
  TWO = '02',
  THREE = '03',
  FOUR = '04',
  FIVE = '05',
  SIX = '06',
  SEVEN = '07',
  EIGHT = '08',
  NINE = '09',
  TEN = '10',
  ELEVEN = '11',
  TWELVE = '12',
}
