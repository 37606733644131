import { IActivity } from 'app/shared/model/activity.model';

export interface IItinerary {
  id?: number;
  itineraryName?: string | null;
  itineraryText?: string | null;
  active?: boolean | null;
  itineraryImageContentType?: string | null;
  itineraryImage?: string | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IItinerary> = {
  active: false,
};
