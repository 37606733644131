import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DiningDetails from './dining-details';
import DiningDetailsDetail from './dining-details-detail';
import DiningDetailsUpdate from './dining-details-update';
import DiningDetailsDeleteDialog from './dining-details-delete-dialog';

const DiningDetailsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DiningDetails />} />
    <Route path="new" element={<DiningDetailsUpdate />} />
    <Route path=":id">
      <Route index element={<DiningDetailsDetail />} />
      <Route path="edit" element={<DiningDetailsUpdate />} />
      <Route path="delete" element={<DiningDetailsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DiningDetailsRoutes;
