import React from 'react';
import './layout.css'; // Import your CSS file

const DivWithTitle = ({ title, children }) => {
  return (
    <div className="div-with-title">
      <h4 className="div-title">{title}</h4>
      <div className="div-with-title-content">{children}</div>
    </div>
  );
};

export default DivWithTitle;
