import { IMealType } from 'app/shared/model/meal-type.model';
import { ICusine } from 'app/shared/model/cusine.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface IDiningDetails {
  id?: number;
  name?: string | null;
  about?: string | null;
  priceRange?: string | null;
  features?: string | null;
  specialDiets?: string | null;
  notes?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  mealTypes?: IMealType[] | null;
  cusines?: ICusine[] | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IDiningDetails> = {};
