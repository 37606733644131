import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './itinerary.reducer';

export const ItineraryDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const itineraryEntity = useAppSelector(state => state.itinerary.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="itineraryDetailsHeading">
          <Translate contentKey="activitiesApp.itinerary.detail.title">Itinerary</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{itineraryEntity.id}</dd>
          <dt>
            <span id="itineraryName">
              <Translate contentKey="activitiesApp.itinerary.itineraryName">Itinerary Name</Translate>
            </span>
          </dt>
          <dd>{itineraryEntity.itineraryName}</dd>
          <dt>
            <span id="itineraryText">
              <Translate contentKey="activitiesApp.itinerary.itineraryText">Itinerary Text</Translate>
            </span>
          </dt>
          <dd>{itineraryEntity.itineraryText}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="activitiesApp.itinerary.active">Active</Translate>
            </span>
          </dt>
          <dd>{itineraryEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="itineraryImage">
              <Translate contentKey="activitiesApp.itinerary.itineraryImage">Itinerary Image</Translate>
            </span>
          </dt>
          <dd>
            {itineraryEntity.itineraryImage ? (
              <div>
                {itineraryEntity.itineraryImageContentType ? (
                  <a onClick={openFile(itineraryEntity.itineraryImageContentType, itineraryEntity.itineraryImage)}>
                    <img
                      src={`data:${itineraryEntity.itineraryImageContentType};base64,${itineraryEntity.itineraryImage}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {itineraryEntity.itineraryImageContentType}, {byteSize(itineraryEntity.itineraryImage)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.itinerary.activity">Activity</Translate>
          </dt>
          <dd>{itineraryEntity.activity ? itineraryEntity.activity.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/itinerary" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/itinerary/${itineraryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ItineraryDetail;
