import './landingindex.scss';

import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import Navbar from './navbar';
import HomeActivityLomBok from './homeactivitylombok';
import { useNavigate, useParams } from "react-router-dom";
import HomeActivityBali from './HomeActivityBali';

export const LandingIndex = () => {

  const navigate = useNavigate();
  const { cityParam = 'Bali' } = useParams();
  const HomeActivityComponent = cityParam === 'Lombok' ? HomeActivityLomBok : HomeActivityBali; // Choose the appropriate component based on cityParam

  return (
    <>
      <div>
        <Row>
        </Row>
        <Row>
          <Col>
            <HomeActivityComponent /> {/* Render the chosen component */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LandingIndex;
