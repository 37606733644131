import dayjs from 'dayjs';
import { IBookingUser } from 'app/shared/model/booking-user.model';
import { IBookingPament } from 'app/shared/model/booking-pament.model';
import { IBookingPax } from 'app/shared/model/booking-pax.model';
import { IActivity } from 'app/shared/model/activity.model';
import { IUser } from 'app/shared/model/user.model';
import { BookingStatus } from 'app/shared/model/enumerations/booking-status.model';
import { IWeekDayTime } from 'app/shared/model/week-day-time.model';

export interface IBooking {
  id?: number;
  adultCount?: number | null;
  childCount?: number | null;
  infantCount?: number | null;
  bookingDate?: string | null;
  totalPrice?: number | null;
  bookingStatus?: BookingStatus | null;
  bookingGUID?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  bookingUser?: IBookingUser | null;
  bookingPayments?: IBookingPament[] | null;
  bookingPaxes?: IBookingPax[] | null;
  activity?: IActivity | null;
  user?: IUser | null;
  weekDayTime?: IWeekDayTime | null;
  reviewRequestEmailCount?: number | null;
}

export const defaultValue: Readonly<IBooking> = {};
