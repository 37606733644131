import { Button } from 'app/shared/components/ui/button';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BookingModal from '../../bookingmodal';
import { setPaxData, updateSelectedDate } from 'app/modules/customer/customeractions';

const Booking = () => {
  const [showModal, setShowModal] = useState(false);
  const activitySelector = useSelector((state: any) => state.customerReducer.activity);
  const customerSelector = useSelector((state: any) => state.customerReducer);
  const dispatch = useDispatch();

  const dateAvailability = activitySelector?.activitiyDateAvailabilityDTOS;
  const activity = activitySelector?.activity;

  const toggleModal = () => setShowModal(!showModal);

  const handleOpen = elementIndex => {
    const activityDate = dateAvailability[elementIndex];
    if (customerSelector?.activityDate === activityDate?.activityDate) {
      //selecting the same date
      //no need to create pax array, we already have data in store, unless refreshed.
    } else {
      dispatch(updateSelectedDate(activityDate));
      const paxDataArray: any = [];
      activityDate?.activityPrices?.map((activityPrice, index) => {
        paxDataArray.push({
          activityDate: activityDate.activityDate,
          arrayIndex: index,
          paxCount: 0,
          perPaxPrice: activityPrice.adultPrice,
        });
      });
      dispatch(setPaxData(paxDataArray));
    }
    toggleModal();
  };

  return (
    <div>
      <div className="tw-flex tw-flex-col">
        <span className="tw-text-base tw-font-semibold tw-text-sky-500">
          {`Booking Availability ${dayjs(dateAvailability?.[0].activityDate).format('MMM D')} to ${dayjs(
            dateAvailability?.[6].activityDate
          ).format('MMM D')} 2023`}
        </span>
        <span className="tw-text-xs tw-text-gray-500">All prices shown in AUD</span>

        <div className="tw-mt-8 tw-overflow-x-auto">
          <div className="tw-w-[800px] md:tw-w-[100%] tw-mb-4">
            <div className="tw-grid tw-grid-cols-29 tw-gap-2 tw-w-full">
              <div className="tw-col-start-2 tw-col-end-30 tw-grid tw-grid-cols-7 tw-bg-sky-500 tw-text-white tw-py-3">
                {dateAvailability?.map((item, index) => (
                  <span key={index} className="tw-text-xs tw-text-center tw-text-white">
                    {item.formattedDate}
                  </span>
                ))}
              </div>
            </div>
          </div>
          {activity?.activityPrices?.map((activityPrice, index) => {
            const discount = Math.round(((activity?.maxPrice - activityPrice.adultPrice) / activity?.maxPrice) * 100);
            return (
              <div key={index} className="tw-flex tw-w-[800px] md:tw-w-[100%] tw-mt-2 tw-relative">
                <div className="tw-grid tw-grid-cols-29 tw-gap-2 tw-w-full">
                  <div className="tw-col-span-1 [writing-mode:vertical-lr] tw-justify-center tw-items-center">
                    {discount > 0 && (
                      <div className=" tw-bg-orange-400 tw-w-full tw-text-sm tw-text-center tw-py-1 tw-text-white">{discount}% off</div>
                    )}
                  </div>

                  {dateAvailability?.map((date, dateIndex) => (
                    <div
                      key={dateIndex}
                      className="tw-col-span-4 tw-text-xs tw-pt-2 tw-pb-3 tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-2 tw-border"
                    >
                      <div className="tw-w-full tw-mt-2 tw-space-y-1">
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <span className="tw-text-gray-500 tw-text-xs">Space left</span>
                          <span className="tw-text-sky-500 tw-font-bold tw-text-xs">{date.adultCount}</span>
                        </div>
                        <div className="tw-flex tw-items-center tw-justify-between">
                          <span className="tw-text-gray-500 tw-text-xs">Booked</span>
                          <span className="tw-text-sky-500 tw-font-bold tw-text-xs">{date.bookedAdultCount}</span>
                        </div>

                        <div className="tw-flex tw-items-center tw-justify-between">
                          <span className="tw-text-gray-500 tw-text-xs">Per person</span>
                          <span className="tw-text-sky-500 tw-font-bold tw-text-xs">${activityPrice.adultPrice}</span>
                        </div>

                        <div className="tw-flex tw-items-center tw-justify-center tw-pt-4">
                          <Button
                            size="sm"
                            className="tw-px-4 tw-py-1"
                            disabled={date.applicablePriceId != activityPrice.id || !date.adultCount}
                            onClick={() => handleOpen(dateIndex)}
                          >
                            Book
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
        <Button variant="outline">Book now</Button>
      </div>

      <BookingModal show={showModal} onClose={toggleModal} />
    </div>
  );
};

export default Booking;
