import React from 'react';
import ReviewItem from './review-item';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import useWindowSize from 'app/shared/hooks/use-window-size';

interface Props {
  reviews: any[];
}

const CustomerReviews = ({ reviews }: Props) => {
  const { width } = useWindowSize();

  return (
    <div className="tw-mt-12 md:tw-mt-16">
      <div className="tw-flex tw-items-center">
        <div className="tw-bg-gray tw-pb-3 tw-border-b-sky-500 tw-border-b-2">
          <h3 className="tw-text-2xl tw-font-semibold tw-text-sky-500">Customer Reviews</h3>
        </div>
      </div>

      <div className="tw-mt-12">
        <Swiper
          slidesPerView={width < 768 ? 1 : 2}
          spaceBetween={60}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="tw-custom-swiper"
        >
          {reviews?.map((review, index) => (
            <SwiperSlide key={index}>
              <ReviewItem rating={review.noOfStars} comment={review.commnet} user={review.reviewdUser} date={review.reviewDate} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="tw-flex tw-space-x-12 tw-mt-8 md:tw-mt-12"></div>
    </div>
  );
};

export default CustomerReviews;
