import React from 'react';
import Features from './features';

interface Props {
  summaryDescription: string;
  detailedDescription: string;
  highlights: Array<{ id: string; highlightNote: string }>;
  itineraries: Array<{ id: string; itineraryText: string }>;
}

const Description = ({ summaryDescription, detailedDescription, highlights, itineraries }: Props) => {
  return (
    <div className="">
      <p className="tw-text-xl">{summaryDescription}</p>

      <div className="md:tw-px-12 tw-py-4 md:tw-py-6 tw-grid tw-grid-cols-2 tw-gap-8 md:tw-gap-16">
        <div className="tw-flex tw-flex-col tw-items-start tw-col-span-2 md:tw-col-span-1">
          <div className="tw-mb-2 tw-border-sky-500 tw-border-b-2 tw-pr-8 tw-pb-2">
            <h6 className="tw-text-sky-500 tw-text-base tw-font-bold">HIGHLIGHTS</h6>
          </div>
          <ul className="tw-list-disc tw-text-sky-500 tw-pl-4 tw-text-base">
            {highlights?.map((item, index) => (
              <li key={index}>{item.highlightNote}</li>
            ))}
          </ul>
        </div>

        <div className="tw-flex tw-flex-col tw-items-start tw-col-span-2 md:tw-col-span-1">
          <div className="tw-mb-2 tw-border-sky-500 tw-border-b-2 tw-pr-8 tw-pb-2">
            <h6 className="tw-text-sky-500 tw-text-base tw-font-bold">ITINERARY</h6>
          </div>

          {itineraries?.map((item, index) => (
            <ul key={index} className="tw-list-disc tw-text-sky-500 tw-pl-4 tw-text-base">
              {item.itineraryText.split('\n').map(item => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          ))}
        </div>
      </div>

      <p className="tw-text-base tw-text-gray-600">{detailedDescription}</p>
    </div>
  );
};

export default Description;
