import './landing.scss';
import './activity.scss';
import './layout.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert, Modal, Form, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addDays, format, startOfWeek, eachDayOfInterval } from 'date-fns';

import Navbar from './navbar';

import { useAppSelector } from 'app/config/store';
import { set } from 'lodash';
import { height } from '@fortawesome/free-solid-svg-icons/faCogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import customerReducer from './customerreducer';
import {
  setPaxData,
  removePassenger,
  updatePaxDataElement,
  updateSelectedDate,
  updateSelectedTime,
  setActivityData,
  setActivityTimeData,
} from './customeractions';
import DivWithTitle from './divwithtitle';

export const Activity = props => {
  const activitySelector = useSelector((state: any) => state.customerReducer.activity);
  const paxDataSelector = useSelector((state: any) => state.customerReducer.paxData);
  const selectedDateSelector = useSelector((state: any) => state.customerReducer.selectedDate);
  const selectedTimeSelector = useSelector((state: any) => state.customerReducer.selectedTime);

  const [randomActivities, setRandomActivities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [adultPrice, setAdultPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [readIndex, setReadIndex] = useState(0);
  const [mainImage, setMainImage] = useState(null);
  const [averageReview, setAverageReview] = useState(null); /* change stars based on the averageReview value */
  const activityTypes = [
    'ADVENTURE',
    'NIGHTTIME',
    'WELLNESS',
    'KIDSPLAY',
    'TOURINGBALI',
    'GETWET',
    'ADRENALIN',
    'TRANSFERS',
    'CULTURALSITES',
  ];
  //const cities = ['Bali', 'Lombok','Nusa Lembongan'];
  const cities = ['Bali'];
  const dispatch = useDispatch();

  const { activityId } = useParams();

  useEffect(() => {
    fetchActivity();
    fetchAverageReview(); /* modified by buddi */
    updateModal();
  }, []);

  useEffect(() => {
    updateModal();
  }, [paxDataSelector]);

  useEffect(() => {
    fetchRandomActivity();
  }, []);

  const fetchActivity = async () => {
    if (activitySelector == null) {
      try {
        const response = await fetch(`/api/home/activity-availability?activityId=${activityId}`);
        const data = await response.json();
        dispatch(setActivityData(data));
        const paxDataArray = [];
        // data?.activity?.activityPrices?.map((activityPrice, index) => {
        //   paxDataArray.push({ arrayIndex: index, paxCount: 0, perPaxPrice: activityPrice.adultPrice });
        // });
        // dispatch(setPaxData(paxDataArray));
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };

  const fetchRandomActivity = async () => {
    if (activitySelector == null) {
      try {
        const response = await fetch(
          `/api/home/get-sortable-pagable-list?page=0&size=20&cityName=${cities[Math.floor(Math.random() * cities.length)]}&activityType=${
            activityTypes[Math.floor(Math.random() * activityTypes.length)]
          }&keyword=`
        );
        const data = await response.json();
        setRandomActivities(data);
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };

  const openModal = elementIndex => {
    const activityDateDTO = activitySelector.activitiyDateAvailabilityDTOS[elementIndex];
    if (selectedDateSelector?.activityDate === activityDateDTO?.activityDate) {
      //selecting the same date
      //no need to create pax array, we already have data in store, unless refreshed.
    } else {
      dispatch(updateSelectedDate(activityDateDTO));
      const paxDataArray = [];
      activityDateDTO?.activityPrices?.map((activityPrice, index) => {
        paxDataArray.push({
          activityDate: activityDateDTO.activityDate,
          arrayIndex: index,
          paxCount: 0,
          perPaxPrice: activityPrice.adultPrice,
        });
      });
      dispatch(setPaxData(paxDataArray));
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleTimeSelect = (event, availTime) => {
    event.preventDefault();
    dispatch(updateSelectedTime(availTime));
  };

  /* added by buddi */
  const fetchAverageReview = () => {
    // Simulating the API call with a delay
    setTimeout(() => {
      // Replace this with your actual data received from the API
      const data = { activityReviewDTO: { averageReview: 2 } };
      setAverageReview(data.activityReviewDTO.averageReview);
    }, 1000); // Delayed by 1 second to simulate an API call
  };

  const onAddButtonClick = (event, price, index) => {
    event.preventDefault();
    const spinnerValue = paxDataSelector[index].paxCount + 1; //parseInt(event.target.value);
    dispatch(updatePaxDataElement(index, spinnerValue));
  };

  const onRemoveButtonClick = (event, price, index) => {
    event.preventDefault();
    const spinnerValue = paxDataSelector[index]?.paxCount - 1; //parseInt(event.target.value);
    dispatch(updatePaxDataElement(index, spinnerValue));
  };

  const getTotalPaxCount = () => {
    let totalPaxCount = 0;
    paxDataSelector.map(p => {
      totalPaxCount = totalPaxCount + p.paxCount;
    });
    return totalPaxCount;
  };

  // Function to get the class name based on the averageReviewReview value
  const getClassNames = () => {
    if (!averageReview) return 'star-icon-0';
    let rating = '';
    switch (parseInt(averageReview, 10)) {
      case 0:
        rating = 'star-icon-0';
        break;
      case 1:
        rating = 'star-icon-1';
        break;
      case 2:
        rating = 'star-icon-2';
        break;
      case 3:
        rating = 'star-icon-3';
      case 4:
        rating = 'star-icon-4';
        break;
      case 5:
        rating = 'star-icon-5';
        break;
      default:
        // Set a default class in case the value doesn't match any of the cases above
        rating = 'star-icon-0';
        break;
    }
    return rating;
  };

  const handleInputChange = (index, adultPrice, event) => {
    const spinnerValue = paxDataSelector[index].paxCount + 1; //parseInt(event.target.value);
    dispatch(updatePaxDataElement(index, spinnerValue));
  };

  const updateModal = () => {
    let paxTotalPrice = 0;
    let totalPaxCount = 0;
    paxDataSelector.map(data => {
      paxTotalPrice = paxTotalPrice + data.paxCount * data.perPaxPrice;
      totalPaxCount = totalPaxCount + data.paxCount;
    });
    setCounter(totalPaxCount);
    setTotalPrice(paxTotalPrice);
  };

  const bookNow = (selectedDate, adultCount, childCount) => {
    console.log(selectedDate.adultCount + '  - ' + adultCount + '  ' + childCount);
  };

  const today = new Date();
  const start = startOfWeek(today);
  const days = eachDayOfInterval({ start, end: addDays(start, 6) });

  // Function to handle the click event on sub-images
  const handleSubImageClick = image => {
    const selectedImage = image !== null && image != undefined && image !== 'undefined' ? image : '../../content/images/logo.png';
    setMainImage(selectedImage);
  };

  const getFormattedISODate = isoDate => {
    const dateParts = isoDate.split('T')[0].split('-');

    // Extract date components
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    // Format the date in "yyyy-MM-dd" format
    return `${year}-${month}-${day}`;
  };

  const enUS = (locale): Locale => {
    return {
      code: 'en-US',
    };
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  return (
    <>
      <div className="">
        <Row>
          <Col md="4"></Col>
          <Col md="4" style={{ alignItems: 'left' }}>
            <a href={`experience/${activityId}`}> Switch to New View</a>
          </Col>
          <Col md="4"></Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="container p-5">
              <div className="row">
                <div className="col-md-9 boxShadow2">
                  <div className="container">
                    <div className="row">
                      <div className="col">
                        <div className="container m-0 p-0">
                          <div className="row">
                            <div className="col-md-9">
                              <h3 style={{ fontSize: '1.3rem', color: '#000' }}>{activitySelector?.activity?.name}</h3>
                              <span className="ps-3">
                                <span className={getClassNames()}></span>
                                {activitySelector?.activitiyReviewDTO?.averageReview}({activitySelector?.activitiyReviewDTO?.totalReviews})
                              </span>
                              <span className="ps-3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-geo-alt-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                </svg>
                                {activitySelector?.activity?.location?.streetAddress}, {activitySelector?.activity?.location?.cityName}{' '}
                              </span>
                            </div>
                            <div
                              className="col-md-3"
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <div>
                                <h2 className="rateCard" style={{ fontWeight: 'bold', fontSize: '1.5rem', color: '#000' }}>
                                  ${activitySelector?.activity?.activityPrices?.[0]?.adultPrice}
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-md-9">
                              <img
                                id="activityMainImage"
                                src={
                                  mainImage ||
                                  activitySelector?.activity?.activityImages?.[0]?.imageShortDescription ||
                                  '../../content/images/logo.png'
                                }
                                className="img-fluid roundedCorner"
                                alt="Activity Main"
                              />
                            </div>
                            <div className="col-md-3" style={{ textAlign: 'right' }}>
                              <div className="mb-2" style={{ textAlign: 'right' }}>
                                <img
                                  style={{ width: '170px' }}
                                  className="img-fluid roundedCorner sub-image"
                                  id="activitySubImage1"
                                  src={
                                    activitySelector?.activity?.activityImages?.[0]?.imageShortDescription ||
                                    '../../content/images/logo.png'
                                  }
                                  alt="Activity Sub 1"
                                  onClick={() =>
                                    handleSubImageClick(`${activitySelector?.activity?.activityImages?.[0]?.imageShortDescription}`)
                                  }
                                />
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  textAlign: 'right',
                                }}
                              >
                                <img
                                  style={{ width: '170px' }}
                                  className="img-fluid roundedCorner sub-image"
                                  id="activitySubImage2"
                                  src={
                                    activitySelector?.activity?.activityImages?.[1]?.imageShortDescription ||
                                    '../../content/images/logo.png'
                                  }
                                  alt="Activity Sub 2"
                                  onClick={() =>
                                    handleSubImageClick(`${activitySelector?.activity?.activityImages?.[1]?.imageShortDescription}`)
                                  }
                                />
                              </div>
                              <div className="mb-2" style={{ textAlign: 'right' }}>
                                <img
                                  style={{ width: '170px' }}
                                  className="img-fluid roundedCorner sub-image"
                                  id="activitySubImage3"
                                  src={
                                    activitySelector?.activity?.activityImages?.[2]?.imageShortDescription ||
                                    '../../content/images/logo.png'
                                  }
                                  alt="Activity Sub 3"
                                  onClick={() =>
                                    handleSubImageClick(`${activitySelector?.activity?.activityImages?.[2]?.imageShortDescription}`)
                                  }
                                />
                              </div>
                              <div
                                className="mb-2"
                                style={{
                                  textAlign: 'right',
                                }}
                              >
                                <img
                                  id="activitySubImage4"
                                  src={
                                    activitySelector?.activity?.activityImages?.[3]?.imageShortDescription ||
                                    '../../content/images/logo.png'
                                  }
                                  style={{ width: '170px' }}
                                  className="img-fluid roundedCorner"
                                  alt="Activity Sub 4"
                                  onClick={() =>
                                    handleSubImageClick(`${activitySelector?.activity?.activityImages?.[3]?.imageShortDescription}`)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-9">
                              <p className="lineSpace textSummary">{activitySelector?.activity?.summaryDescription}</p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 pt-3 pb-3">
                              <span style={{ fontSize: '18px' }}>
                                <i className="fa fa-calendar iconSize" aria-hidden="true"></i>Available Bookings for the next 7 days
                              </span>
                              <span>
                                <i className="fa fa-calendar iconSize" aria-hidden="true"></i>(All prices are shown in AUD($))
                              </span>
                            </div>
                          </div>

                          <div className="row">
                            {activitySelector?.activitiyDateAvailabilityDTOS?.map((activityDateDTO, index) => {
                              const adultCount = activityDateDTO?.adultCount;
                              return (
                                <>
                                  <div
                                    className="col-12 col-md-2"
                                    style={{
                                      border: '1px solid #ccc',
                                      borderRadius: '5px 5px 5px 0',
                                      padding: '0px',
                                      marginLeft: '5px',
                                      textAlign: 'center',
                                      marginBottom: '10px',
                                      minWidth: '150px',
                                    }}
                                  >
                                    <div style={{ backgroundColor: 'orange', textAlign: 'center' }}>{activityDateDTO?.formattedDate}</div>
                                    <div
                                      key={index}
                                      style={
                                        selectedDateSelector?.activityDate === activityDateDTO?.activityDate
                                          ? { backgroundColor: 'violet' }
                                          : { backgroundColor: '#f4f4f4' }
                                      }
                                    >
                                      <span>
                                        <p style={{ fontSize: '13px' }}>Spaces Left :{activityDateDTO?.adultCount}</p>
                                      </span>
                                      <div>
                                        <p style={{ fontSize: '13px' }}>Per person: ${activityDateDTO?.adultPrice}</p>
                                      </div>
                                      <div>
                                        {adultCount == undefined || parseInt(adultCount) == 0 ? (
                                          <button
                                            disabled={true}
                                            className="book"
                                            style={{ backgroundColor: 'grey', marginBottom: '10px' }}
                                            onClick={() => openModal(index)}
                                          >
                                            Book
                                          </button>
                                        ) : (
                                          adultCount != undefined &&
                                          parseInt(adultCount) >= 0 && (
                                            <button className="book" style={{ marginBottom: '10px' }} onClick={() => openModal(index)}>
                                              Book
                                            </button>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>

                          {/* <div className="row">
                            <div className="col-md-12">
                              {activitySelector && (
                                <>
                                  <div className='row'>
                                    <div className="days">
                                      {activitySelector?.activitiyDateAvailabilityDTOS?.map((activityDateDTO, index) => {
                                        const adultCount = activityDateDTO?.adultCount;
                                        const adultPrice = activityDateDTO?.adultPrice;
                                        return (
                                          <div
                                            className="aV"
                                            key={index}
                                            style={
                                              selectedDateSelector?.activityDate === activityDateDTO?.activityDate
                                                ? { backgroundColor: 'lightblue' }
                                                : { backgroundColor: '#f4f4f4' }
                                            }
                                          >
                                            <span>
                                              {activityDateDTO?.formattedDate }
                                            </span>
                                            <div key={index}>
                                              <p className="calSpaces">Spaces: {adultCount ? adultCount : 0}</p>
                                              <p className="calAdult">Price: {adultPrice ? adultPrice : 0}</p>
                                              {adultCount == undefined || parseInt(adultCount) == 0 ? (
                                                <button
                                                  disabled={true}
                                                  className="book"
                                                  style={{ backgroundColor: 'grey' }}
                                                  onClick={() => openModal(index)}
                                                >
                                                  Book
                                                </button>
                                              ) : (
                                                adultCount != undefined &&
                                                parseInt(adultCount) >= 0 && (
                                                  <button className="book" onClick={() => openModal(index)}>
                                                    Book
                                                  </button>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div> */}

                          <div className="row mt-4">
                            <div className="col-md-9">
                              <p className="lineSpace textSummary">{activitySelector?.activity?.detailedDescription}</p>
                            </div>
                          </div>

                          <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseOne"
                                  aria-expanded="true"
                                  aria-controls="panelsStayOpen-collapseOne"
                                >
                                  <i className="fa fa-list iconSize" aria-hidden="true"></i>Highlights
                                </button>
                              </h2>
                              <div
                                id="panelsStayOpen-collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="panelsStayOpen-headingOne"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    {activitySelector?.activity?.activityHighlights &&
                                    activitySelector.activity.activityHighlights.length > 0 ? (
                                      activitySelector.activity.activityHighlights.map((highlight, index) => (
                                        <li key={index}>{highlight.highlightNote}</li>
                                      ))
                                    ) : (
                                      <li>None</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseTwo"
                                >
                                  <i className="fa fa-list iconSize" aria-hidden="true"></i>Include Items
                                </button>
                              </h2>
                              <div
                                id="panelsStayOpen-collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingTwo"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    {activitySelector?.activity?.activityIncludedItems &&
                                    activitySelector.activity.activityIncludedItems.length > 0 ? (
                                      activitySelector.activity.activityIncludedItems.map((includeItems, index) => (
                                        <li key={index}>{includeItems.itemDetails}</li>
                                      ))
                                    ) : (
                                      <li>None</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#panelsStayOpen-collapseThree"
                                  aria-expanded="false"
                                  aria-controls="panelsStayOpen-collapseThree"
                                >
                                  <i className="fa fa-list iconSize" aria-hidden="true"></i>Reviews
                                </button>
                              </h2>
                              <div
                                id="panelsStayOpen-collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="panelsStayOpen-headingThree"
                              >
                                <div className="accordion-body">
                                  <ul>
                                    {activitySelector?.activitiyReviewDTO?.activityReviews?.length > 0 ? (
                                      activitySelector?.activitiyReviewDTO?.activityReviews.map((reviewItem, index) => (
                                        <li key={index}>{reviewItem.commnet}</li>
                                      ))
                                    ) : (
                                      <li>None</li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 ps-4">
                  {/** move this code to another module  */}
                  <div className="">
                    <div className="">
                      <div className="row">
                        {randomActivities?.length == 0 && (
                          <div className="col-md-12 p-0">
                            <h5 className="activityRightCol"></h5>
                            <Link to={``}>
                              <img className="img-fluid " src={'../../content/images/logo.png'} />
                            </Link>
                            <p className="rightColumnLink">Activity Description</p>
                          </div>
                        )}
                        {randomActivities?.map((activity, index) => (
                          <div key={index}>
                            <div className="col-md-12 p-0">
                              <h5 className="activityRightCol">{activity?.name}</h5>
                              <Link to={``}>
                                <img
                                  className="img-fluid "
                                  src={activity?.activityImages?.[0]?.imageShortDescription || '../../content/images/logo.png'}
                                />
                              </Link>
                              <p className="rightColumnLink">{activity.summaryDescription}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* end of bootstarp code */}
        {showModal && (
          <Modal isOpen={showModal} toggle={closeModal} backdrop="static" id="login-page" autoFocus={false}>
            <Form onSubmit={closeModal}>
              <ModalHeader
                id="login-title"
                data-cy="loginTitle"
                toggle={closeModal}
                style={{
                  backgroundColor: 'orange',
                  borderTopLeftRadius: '50px',
                  borderBottomLeftRadius: '50px',
                  borderTopRightRadius: '50px',
                  borderBottomRightRadius: '50px',
                  padding: '20px',
                  fontWeight: 'bolder',
                }}
              >
                Enter Pax Details - {getFormattedISODate(selectedDateSelector.activityDate)}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md="12">
                    {selectedDateSelector?.activityTimeAvailabilityDTOS && selectedDateSelector?.activityTimeAvailabilityDTOS.length > 0 && (
                      <DivWithTitle title={'Select A Time Slot'}>
                        {selectedDateSelector?.activityTimeAvailabilityDTOS?.map((availTime, index) => (
                          <div key={availTime.activityTimeAvailabilityId}>
                            <div className="button-container">
                              <button
                                key={availTime.activityTimeAvailabilityId} // Replace "id" with a unique identifier for each item
                                onClick={event => handleTimeSelect(event, availTime)}
                                style={{
                                  backgroundColor:
                                    selectedTimeSelector?.activityTimeAvailabilityId === availTime?.activityTimeAvailabilityId
                                      ? '#022b4b'
                                      : 'white',
                                  color:
                                    selectedTimeSelector?.activityTimeAvailabilityId === availTime?.activityTimeAvailabilityId
                                      ? 'white'
                                      : 'black',
                                }}
                                className="timeSlot"
                              >
                                {availTime
                                  ? `${new Date(availTime?.twentyFourHourTime).toTimeString().slice(0, 5)} Spaces left for this time : ${
                                      availTime?.adultCount
                                    }`
                                  : 'No time slot available'}
                              </button>
                            </div>
                          </div>
                        ))}
                      </DivWithTitle>
                    )}
                  </Col>
                </Row>
                {(selectedDateSelector?.activityTimeAvailabilityDTOS?.length == 0 || selectedTimeSelector != null) && (
                  <Row>
                    <Col>
                      <DivWithTitle title={'Select Pax Count'}>
                        {selectedDateSelector?.activityPrices?.map((activityPrice, index) => {
                          return (
                            <Row>
                              <Col md="3" className="centered-div">
                                <span style={{ fontSize: '18px' }}>
                                  @ ${activityPrice?.adultPrice}
                                  {/* - [{activityPrice.fromPax}-{activityPrice.toPax}] */}
                                </span>
                              </Col>
                              <Col md="3" className="centered-div">
                                <Button
                                  onClick={event => onRemoveButtonClick(event, activityPrice, index)}
                                  disabled={
                                    getTotalPaxCount() === 0 ||
                                    getTotalPaxCount() <= activityPrice.fromPax - 1 ||
                                    getTotalPaxCount() > activityPrice.toPax
                                  }
                                >
                                  -
                                </Button>
                              </Col>
                              <Col md="3" className="centered-div">
                                <span style={{ fontSize: '18px' }}>{paxDataSelector[index].paxCount}</span>
                              </Col>
                              <Col md="3" className="centered-div">
                                <Button
                                  onClick={event => onAddButtonClick(event, activityPrice, index)}
                                  disabled={
                                    getTotalPaxCount() === selectedTimeSelector?.adultCount ||
                                    getTotalPaxCount() === activityPrice.toPax ||
                                    getTotalPaxCount() < activityPrice.fromPax - 1 ||
                                    getTotalPaxCount() > activityPrice.toPax
                                  }
                                >
                                  +
                                </Button>
                              </Col>
                            </Row>
                          );
                        })}
                      </DivWithTitle>
                    </Col>
                  </Row>
                )}
                <Row
                  style={{
                    backgroundColor: '#1262c0',
                    borderTopLeftRadius: '50px',
                    borderBottomLeftRadius: '50px',
                    borderTopRightRadius: '50px',
                    borderBottomRightRadius: '50px',
                    padding: '5px',
                  }}
                >
                  <Col>
                    <span style={{ color: 'white', fontSize: '18px' }}>Pax Count: {getTotalPaxCount()}</span>
                  </Col>
                  <Col style={{ textAlign: 'right', fontSize: '18px' }}>
                    <span style={{ color: 'white', textAlign: 'right', fontSize: '18px' }}>Total :$ {totalPrice}</span>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Link onClick={closeModal} to="">
                  Cancel
                </Link>{' '}
                <Link
                  className="activityTypeSelected"
                  style={{
                    pointerEvents: counter == 0 ? 'none' : 'auto',
                    backgroundColor: counter == 0 ? 'grey' : '#022b4b',
                    color: counter == 0 ? '#ccc' : '#fff',
                  }}
                  to={{
                    pathname: `/paxdetails/${encodeURIComponent(JSON.stringify(selectedDateSelector))}`,
                    search: `?param1=${counter}&param2=${counter}
                                &param3=${activitySelector?.activity?.id}&param4=${totalPrice}
                                &param5=${selectedTimeSelector?.availabilityType}
                                &param6=${selectedTimeSelector?.activityAvailabilityId ? selectedTimeSelector?.activityAvailabilityId : -1}
                                &param7=${
                                  selectedTimeSelector?.activityTimeAvailabilityId ? selectedTimeSelector?.activityTimeAvailabilityId : -1
                                }
                                &param8=${selectedTimeSelector?.weekDayTimeId ? selectedTimeSelector?.weekDayTimeId : -1}
                                &param9=${selectedTimeSelector?.twentyFourHourTime}`,
                  }}
                >
                  Book Now
                </Link>
              </ModalFooter>
            </Form>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Activity;
