import React, { useEffect, useMemo, useState } from 'react';
import Rating from '../experience/components/rating';

const ResultItem = ({ data: actItem }) => {
  return (
    <div className="tw-flex tw-flex-col tw-items-stretch tw-w-3/12 tw-max-md:w-full tw-max-md:ml-0">
      <div className="tw-items-start tw-flex tw-grow tw-flex-col tw-max-md:mt-5">
        <a href={`experience/${actItem?.id}`} className="tw-relative tw-inline-block">
          {actItem?.discount > 0 && (
            <div
              id="discountDiv"
              className="tw-absolute tw-top-5 tw-left-5 tw-text-neutral-700 tw-text-sm tw-whitespace-nowrap tw-justify-center tw-items-stretch tw-bg-yellow-300 tw-px-6 tw-py-3 tw-rounded-3xl"
            >
              {Math.floor(actItem?.discount)} % OFF
            </div>
          )}
          <img
            loading="lazy"
            srcSet={actItem?.activityImages?.[0]?.imageShortDescription}
            className="tw-h-full tw-w-full tw-object-cover tw-aspect-[0.71] tw-object-contain tw-object-center tw-w-full tw-overflow-hidden tw-self-stretch"
          />
        </a>

        <div className="tw-justify-center tw-items-stretch tw-flex tw-w-[249px] tw-max-w-full tw-gap-3.5 tw-mt-4 tw-self-start tw-w-full">
          <div className="tw-items-center tw-flex tw-justify-between tw-gap-2">
            <Rating rating={Math.floor(actItem?.reviewRating)} className="tw-text-orange-400 tw-scroll-mr-5" />
          </div>

          <div className="tw-w-full">
            <span>
              {Math.floor(actItem?.reviewRating)} / {actItem?.reviewCount} reviews
            </span>
          </div>

          <div className="tw-content-end tw-text-green-600 tw-text-2xl tw-font-medium tw-self-stretch tw-whitespace-nowrap tw-font-extrabold">
            ${actItem?.minPrice}
          </div>
        </div>
        <div className="tw-text-zinc-900 tw-text-1xl tw-whitespace-nowrap tw-mt-1 tw-self-start">
          {actItem?.name?.slice(0, 40 - 3) + '...'}
        </div>
        <div className="tw-items-center tw-flex tw-gap-3 tw-mt-1 tw-self-start">
          {actItem?.discount > 0 && (
            <div className="activityDivHeight">
              <span className="activitySubLineOne">Deal Window {actItem?.bestBetween}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultItem;
