// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../content/images/bali_home_page.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 497px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top;\n  background-size: contain;\n}\n\n.table-container {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table-container table {\n  width: 100%;\n}\n\n.table-container th,\n.table-container td {\n  padding: 8px;\n  border: 1px solid #ccc;\n}\n\n.table-container th {\n  background-color: #f2f2f2;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/customer/landingindex.scss"],"names":[],"mappings":"AAAA;;4EAAA;AAGA;EACE,qBAAA;EACA,WAAA;EACA,aAAA;EACA,wEAAA;EACA,wBAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;;EAEE,YAAA;EACA,sBAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF","sourcesContent":["/* ==========================================================================\nMain page styles\n========================================================================== */\n.hipster {\n  display: inline-block;\n  width: 100%;\n  height: 497px;\n  background: url('../../../content/images/bali_home_page.jpg') no-repeat center top;\n  background-size: contain;\n}\n\n.table-container {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table-container table {\n  width: 100%;\n}\n\n.table-container th,\n.table-container td {\n  padding: 8px;\n  border: 1px solid #ccc;\n}\n\n.table-container th {\n  background-color: #f2f2f2;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
