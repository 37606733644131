import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityAvailability from './activity-availability';
import ActivityAvailabilityDetail from './activity-availability-detail';
import ActivityAvailabilityUpdate from './activity-availability-update';
import ActivityAvailabilityDeleteDialog from './activity-availability-delete-dialog';

const ActivityAvailabilityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityAvailability />} />
    <Route path="new" element={<ActivityAvailabilityUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityAvailabilityDetail />} />
      <Route path="edit" element={<ActivityAvailabilityUpdate />} />
      <Route path="delete" element={<ActivityAvailabilityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityAvailabilityRoutes;
