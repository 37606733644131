import { IDiningDetails } from 'app/shared/model/dining-details.model';

export interface IMealType {
  id?: number;
  active?: boolean | null;
  mealTypeCode?: string | null;
  mealTypeName?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  diningDetails?: IDiningDetails[] | null;
}

export const defaultValue: Readonly<IMealType> = {
  active: false,
};
