import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

const Footer = () => {
  const dealsData = useSelector((state: any) => state.homeActivityReducer.dealData);
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);

  return (
    <div className="tw-items-stretch tw-bg-stone-900 tw-self-stretch tw-flex tw-w-full tw-flex-col tw-mt-28 tw-px-20 tw-py-12 tw-max-md:max-w-full tw-max-md:mt-10 tw-max-md:px-5">
      <div className="tw-mt-3.5 tw-max-md:max-w-full">
        <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
          <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[24%] tw-max-md:w-full tw-max-md:ml-0">
            <div className="tw-flex tw-flex-col tw-items-start tw-max-md:mt-10">
              <div className="tw-text-white tw-text-4xl tw-font-bold tw-whitespace-nowrap">LOGO</div>
              <div className="tw-text-white tw-text-2xl tw-whitespace-nowrap">balitodo.com</div>
              <div className="tw-text-white tw-text-xl tw-font-medium tw-self-stretch tw-whitespace-nowrap tw-mt-12 tw-max-md:mt-10">
                Connect With Us
              </div>
              <div className="tw-justify-between tw-items-start tw-self-stretch tw-flex tw-gap-5 tw-mt-4 tw-px-px tw-max-md:justify-center">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d9469589dc592366a5bcb9d095eb93e07bff854789f3e424054d5ee373b71d5?apiKey=782db1e49b4d4af9acf49bc384033667&"
                  className="tw-aspect-square tw-object-contain tw-object-center tw-w-5 tw-overflow-hidden tw-self-stretch tw-shrink-0 tw-max-w-full"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ae104b042dd00355c13d852291a4b52eba5fb0446d3294af6a8e229e00a4237?apiKey=782db1e49b4d4af9acf49bc384033667&"
                  className="tw-aspect-square tw-object-contain tw-object-center tw-w-5 tw-overflow-hidden tw-self-stretch tw-shrink-0 tw-max-w-full"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac7a47c7386aff6a366060df134165ff531f863bcfe3256b06c8a1dbefdfea63?apiKey=782db1e49b4d4af9acf49bc384033667&"
                  className="tw-aspect-square tw-object-contain tw-object-center tw-w-5 tw-overflow-hidden tw-self-stretch tw-shrink-0 tw-max-w-full"
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ff662c633b73f38b266bd81c707b56f9d58bc14003db23e62f37a2d0eebce91?apiKey=782db1e49b4d4af9acf49bc384033667&"
                  className="tw-aspect-[1.14] tw-object-contain tw-object-center tw-w-4 tw-fill-white tw-overflow-hidden tw-self-center tw-shrink-0 tw-max-w-full tw-my-auto"
                />
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[76%] tw-ml-5 tw-max-md:w-full tw-max-md:ml-0">
            <div className="tw-items-stretch tw-flex tw-w-full tw-grow tw-justify-between tw-gap-5 tw-max-md:max-w-full tw-max-md:flex-wrap tw-max-md:mt-10">
              <div className="tw-items-stretch tw-flex tw-basis-[0%] tw-flex-col">
                <div className="tw-text-white tw-text-xl tw-font-medium tw-whitespace-nowrap">Categories</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-4">Adrenalin</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Adventure</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Aquatic</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Cultural</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Kids</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Night</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Touring</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Transfers</div>
                <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Wellness</div>
              </div>
              <div className="tw-self-start tw-max-md:max-w-full">
                <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
                  <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[35%] tw-max-md:w-full tw-max-md:ml-0">
                    <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-10">
                      <div className="tw-text-white tw-text-xl tw-font-medium tw-whitespace-nowrap">Locations</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-4">Bali</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Lombok</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Nusa Lembongan</div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[31%] tw-ml-5 tw-max-md:w-full tw-max-md:ml-0">
                    <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-10">
                      <div className="tw-text-white tw-text-xl tw-font-medium tw-whitespace-nowrap">Must View</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-4">Deal of the day</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Most popular</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Most liked</div>
                    </div>
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[34%] tw-ml-5 tw-max-md:w-full tw-max-md:ml-0">
                    <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-10">
                      <div className="tw-text-white tw-text-xl tw-font-medium tw-whitespace-nowrap">Bookings</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-4">Terms & Conditions</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Customer Support</div>
                      <div className="tw-text-white tw-text-base tw-whitespace-nowrap tw-mt-2">Help</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-items-stretch tw-flex tw-flex-col tw-justify-center tw-mt-8 tw-px-16 tw-py-6 tw-border-t-[0.4px] tw-border-t-neutral-400 tw-border-solid tw-max-md:max-w-full tw-max-md:px-5">
        <div className="tw-justify-between tw-items-stretch tw-flex tw-gap-5 tw-mx-5 tw-max-md:max-w-full tw-max-md:flex-wrap tw-max-md:mr-2.5">
          <div className="tw-items-stretch tw-flex tw-justify-between tw-gap-5 tw-max-md:justify-center">
            <div className="tw-text-white tw-text-base tw-whitespace-nowrap">Terms of Service</div>
            <div className="tw-text-white tw-text-base">Privacy Policy</div>
            <div className="tw-text-white tw-text-base tw-whitespace-nowrap">Contact Us</div>
          </div>
          <div className="tw-text-white tw-text-base tw-grow tw-whitespace-nowrap">copyright © 2023 balitodo </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
