import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { IActivityAvailability } from 'app/shared/model/activity-availability.model';
import { getEntity, updateEntity, createEntity, reset } from './activity-availability.reducer';
import { IActivitySchedule } from 'app/shared/model/activity-schedule.model';
import { getEntities as getActivitySchedules } from 'app/entities/activity-schedule/activity-schedule.reducer';
import ActivitySuggest, { fetchActivities, fetchActivityList } from 'app/shared/util/activitysuggest';

export const ActivityAvailabilityUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activitySchedules = useAppSelector(state => state.activitySchedule.entities);
  const activities = useAppSelector(state => state.activity.entities);
  const activityAvailabilityEntity = useAppSelector(state => state.activityAvailability.entity);
  const loading = useAppSelector(state => state.activityAvailability.loading);
  const updating = useAppSelector(state => state.activityAvailability.updating);
  const updateSuccess = useAppSelector(state => state.activityAvailability.updateSuccess);

  const handleClose = () => {
    navigate('/activity-availability' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
      dispatch(fetchActivityList({ id: id, objectTypeId: 2 }));
    }

    dispatch(getActivitySchedules({}));
    //dispatch(getActivities({}));
  }, []);

  const handleInputChange = (kw: any) => {
    const page = fetchActivities(kw);
    dispatch(page);
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...activityAvailabilityEntity,
      ...values,
      activitySchedule: activitySchedules.find(it => it.id.toString() === values.activitySchedule.toString()),
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...activityAvailabilityEntity,
          activitySchedule: activityAvailabilityEntity?.activitySchedule?.id,
          activity: activityAvailabilityEntity?.activity?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityAvailability.home.createOrEditLabel" data-cy="ActivityAvailabilityCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityAvailability.home.createOrEditLabel">
              Create or edit a ActivityAvailability
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-availability-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.fromDate')}
                id="activity-availability-fromDate"
                name="fromDate"
                data-cy="fromDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.toDate')}
                id="activity-availability-toDate"
                name="toDate"
                data-cy="toDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.adultCount')}
                id="activity-availability-adultCount"
                name="adultCount"
                data-cy="adultCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.childCount')}
                id="activity-availability-childCount"
                name="childCount"
                data-cy="childCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.infantCount')}
                id="activity-availability-infantCount"
                name="infantCount"
                data-cy="infantCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityAvailability.userName')}
                id="activity-availability-userName"
                name="userName"
                data-cy="userName"
                type="text"
              />
              <ValidatedField
                id="activity-availability-activitySchedule"
                name="activitySchedule"
                data-cy="activitySchedule"
                label={translate('activitiesApp.activityAvailability.activitySchedule')}
                type="select"
              >
                <option value="" key="0" />
                {activitySchedules
                  ? activitySchedules.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.scheduleName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ActivitySuggest id={1} paramOnChange={handleInputChange} name={'test'} childType={2}></ActivitySuggest>
              <ValidatedField
                id="activity-availability-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityAvailability.activity')}
                type="select"
                size={10}
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-availability" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityAvailabilityUpdate;
