import React from 'react';
import { PlusCircledIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import Rating from './rating';
import { Button } from 'app/shared/components/ui/button';

interface Props {
  title: string;
  totalReview: number;
  averageReview: number;
}

const Title = ({ title, totalReview, averageReview }: Props) => {
  return (
    <div>
      <h2 className="tw-text-2xl tw-font-medium md:tw-w-3/4">{title}</h2>

      <div className="tw-flex tw-space-y-4 md:tw-space-y-0 tw-flex-col md:tw-flex-row tw-justify-between tw-mt-8">
        <div className="tw-flex tw-items-center tw-space-x-2">
          <Rating rating={averageReview} className="tw-text-orange-400" />

          <span className="tw-text-gray-400 tw-text-sm">
            {averageReview?.toFixed(1)} / {totalReview} reviews
          </span>
        </div>
        <div className="">
          <Button variant="outline" className="tw-mr-2">
            <PlusCircledIcon className="tw-mr-1" />
            Add too list
          </Button>
          <Button variant="outline">
            <QuestionMarkCircledIcon className="tw-mr-1" />
            Ask a question
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Title;
