export interface ICriticalLogging {
  id?: number;
  createdDate?: string | null;
  logRef?: string | null;
  logLevel?: string | null;
  logMessage?: string | null;
  otherRef?: string | null;
  sesionId?: string | null;
  userId?: string | null;
  ipAddress?: string | null;
}

export const defaultValue: Readonly<ICriticalLogging> = {};
