import React, { useCallback, useEffect, useRef, useState } from 'react';

import './restulsbody';
import { GoogleMap, useLoadScript, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { GoogleMapsProvider } from '@ubilabs/google-maps-react-hooks';

export const ResultsMap = props => {
  const [mapContainer, setMapContainer] = useState(null);
  const onLoad = useCallback(map => addMarkers(map), []);

  const [labelMap, setLabelMap] = useState({});

  const addMarkers = map => {
    const infoWindow = new google.maps.InfoWindow();
    const markers = props?.data?.map(activity => {
      const marker = new google.maps.Marker({ position: { lat: activity?.location?.latitude, lng: activity?.location?.longitude } });
      marker.addListener('click', () => {
        infoWindow.setPosition({ lat: activity?.location?.latitude, lng: activity?.location?.longitude });
        infoWindow.setContent(`
          <div style="width:220px ;height:200px;">
          <h6>${activity?.name?.slice(0, 50 - 3) + '...'}</h6>
          <img src=${activity?.activityImages[0]?.imageShortDescription} style="width:200px;"/>
          <span class=star-icon-${Math.floor(activity?.reviewRating)}></span>
          <span><b>$${activity?.minPrice}</b></span>
          </div>
        `);
        infoWindow.open({ map });
      });
      return marker;
    });
    new MarkerClusterer({ markers, map, algorithm: new SuperClusterAlgorithm({ radius: 200 }) });
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBYqc0ez49eviBcBOkkoujmphLw7UiRXlo',
  });

  const defaultProps = {
    center: {
      lat: -8.409518,
      lng: 115.188919,
    },
    zoom: 10,
  };

  useEffect(() => {}, []);

  return isLoaded ? (
    <div className="container">
      <div className="row mb-12">
        <div style={{ height: '1000px', width: '100%', marginTop: '50px' }}>
          <GoogleMapsProvider
            googleMapsAPIKey="AIzaSyBYqc0ez49eviBcBOkkoujmphLw7UiRXlo"
            mapOptions={defaultProps}
            mapContainer={mapContainer}
            onLoadMap={onLoad}
          >
            <div ref={node => setMapContainer(node)} style={{ height: '1000px' }}></div>
          </GoogleMapsProvider>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ResultsMap;
