import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityHighlight from './activity-highlight';
import ActivityHighlightDetail from './activity-highlight-detail';
import ActivityHighlightUpdate from './activity-highlight-update';
import ActivityHighlightDeleteDialog from './activity-highlight-delete-dialog';

const ActivityHighlightRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityHighlight />} />
    <Route path="new" element={<ActivityHighlightUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityHighlightDetail />} />
      <Route path="edit" element={<ActivityHighlightUpdate />} />
      <Route path="delete" element={<ActivityHighlightDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityHighlightRoutes;
