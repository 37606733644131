const initialState = {
  activityType: '',
};

export const ACTION_TYPES = {
  SET_ACTIVITYTYPE: 'SEARCH/SET_ACTIVITYTYPE',
};

export const getActivityTypeAction = (activityType: string): any => ({
  type: ACTION_TYPES.SET_ACTIVITYTYPE,
  payload: activityType,
  error: null,
});

const ActivityTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_ACTIVITYTYPE:
      return {
        ...state,
        activityType: action.payload,
      };
    default:
      return state;
  }
};

export default ActivityTypeReducer;
