import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { IActivityPrice } from 'app/shared/model/activity-price.model';
import { getEntity, updateEntity, createEntity, reset } from './activity-price.reducer';
import ActivitySuggest, { fetchActivities, fetchActivityList } from 'app/shared/util/activitysuggest';
import { getEntities as getActivitiesFirstPage } from 'app/entities/activity/activity.reducer';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const ActivityPriceUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activities = useAppSelector(state => state.activity.entities);
  const activityPriceEntity = useAppSelector(state => state.activityPrice.entity);
  const loading = useAppSelector(state => state.activityPrice.loading);
  const updating = useAppSelector(state => state.activityPrice.updating);
  const updateSuccess = useAppSelector(state => state.activityPrice.updateSuccess);

  const handleClose = () => {
    navigate('/activity-price' + location.search);
  };

  const getActivityList = createAsyncThunk('activity/fetch_entity_list', async (id: number | string) => {
    const requestUrl = `/api/home/activity-by-price-id-as-array?activityPriceId=${id}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getActivitiesFirstPage({}));
    } else {
      const actImage = getEntity(id);
      dispatch(actImage);
      dispatch(getActivityList(id));
    }

    // if (isNew) {
    //   dispatch(reset());
    // } else {
    //   dispatch(getEntity(id));
    // }

    //dispatch(getActivities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...activityPriceEntity,
      ...values,
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleInputChange = (kw: any) => {
    const page = fetchActivities(kw);
    dispatch(page);
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...activityPriceEntity,
          activity: activityPriceEntity?.activity?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityPrice.home.createOrEditLabel" data-cy="ActivityPriceCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityPrice.home.createOrEditLabel">Create or edit a ActivityPrice</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-price-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityPrice.fromPax')}
                id="activity-price-fromPax"
                name="fromPax"
                data-cy="fromPax"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.toPax')}
                id="activity-price-toPax"
                name="toPax"
                data-cy="toPax"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.fromDate')}
                id="activity-price-fromDate"
                name="fromDate"
                data-cy="fromDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.toDate')}
                id="activity-price-toDate"
                name="toDate"
                data-cy="toDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.adultPrice')}
                id="activity-price-adultPrice"
                name="adultPrice"
                data-cy="adultPrice"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.childPrice')}
                id="activity-price-childPrice"
                name="childPrice"
                data-cy="childPrice"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.infantPrice')}
                id="activity-price-infantPrice"
                name="infantPrice"
                data-cy="infantPrice"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.note')}
                id="activity-price-note"
                name="note"
                data-cy="note"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityPrice.userName')}
                id="activity-price-userName"
                name="userName"
                data-cy="userName"
                type="text"
              />
              <ActivitySuggest id={1} paramOnChange={handleInputChange} name={'test'} childType={2}></ActivitySuggest>
              <ValidatedField
                id="activity-price-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityPrice.activity')}
                type="select"
                size={10}
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-price" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityPriceUpdate;
