export interface IEmailing {
  id?: number;
  dateCreated?: string | null;
  bookingId?: number | null;
  content?: string | null;
  activityId?: number | null;
  fromAddress?: string | null;
  toAddress?: string | null;
}

export const defaultValue: Readonly<IEmailing> = {};
