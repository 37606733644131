import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity.reducer';

export const ActivityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityEntity = useAppSelector(state => state.activity.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityDetailsHeading">
          <Translate contentKey="activitiesApp.activity.detail.title">Activity</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="activitiesApp.activity.name">Name</Translate>
            </span>
          </dt>
          <dd>{activityEntity.name}</dd>
          <dt>
            <span id="summaryDescription">
              <Translate contentKey="activitiesApp.activity.summaryDescription">Summary Description</Translate>
            </span>
          </dt>
          <dd>{activityEntity.summaryDescription}</dd>
          <dt>
            <span id="detailedDescription">
              <Translate contentKey="activitiesApp.activity.detailedDescription">Detailed Description</Translate>
            </span>
          </dt>
          <dd>{activityEntity.detailedDescription}</dd>
          <dt>
            <span id="quote">
              <Translate contentKey="activitiesApp.activity.quote">Quote</Translate>
            </span>
          </dt>
          <dd>{activityEntity.quote}</dd>
          <dt>
            <span id="durationNote">
              <Translate contentKey="activitiesApp.activity.durationNote">Duration Note</Translate>
            </span>
          </dt>
          <dd>{activityEntity.durationNote}</dd>
          <dt>
            <span id="startDate">
              <Translate contentKey="activitiesApp.activity.startDate">Start Date</Translate>
            </span>
          </dt>
          <dd>{activityEntity.startDate ? <TextFormat value={activityEntity.startDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="endDate">
              <Translate contentKey="activitiesApp.activity.endDate">End Date</Translate>
            </span>
          </dt>
          <dd>{activityEntity.endDate ? <TextFormat value={activityEntity.endDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="childFriendly">
              <Translate contentKey="activitiesApp.activity.childFriendly">Child Friendly</Translate>
            </span>
          </dt>
          <dd>{activityEntity.childFriendly ? 'true' : 'false'}</dd>
          <dt>
            <span id="childFriendlyNote">
              <Translate contentKey="activitiesApp.activity.childFriendlyNote">Child Friendly Note</Translate>
            </span>
          </dt>
          <dd>{activityEntity.childFriendlyNote}</dd>
          <dt>
            <span id="activityType">
              <Translate contentKey="activitiesApp.activity.activityType">Activity Type</Translate>
            </span>
          </dt>
          <dd>{activityEntity.activityType}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="activitiesApp.activity.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {activityEntity.createdDate ? <TextFormat value={activityEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="activitiesApp.activity.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {activityEntity.modifiedDate ? <TextFormat value={activityEntity.modifiedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="active">
              <Translate contentKey="activitiesApp.activity.active">Active</Translate>
            </span>
          </dt>
          <dd>{activityEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="activitiesApp.activity.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{activityEntity.userName}</dd>
          <dt>
            <span id="whatsIncluded">
              <Translate contentKey="activitiesApp.activity.whatsIncluded">Whats Included</Translate>
            </span>
          </dt>
          <dd>{activityEntity.whatsIncluded}</dd>
          <dt>
            <span id="whatToBring">
              <Translate contentKey="activitiesApp.activity.whatToBring">What To Bring</Translate>
            </span>
          </dt>
          <dd>{activityEntity.whatToBring}</dd>
          <dt>
            <span id="departs">
              <Translate contentKey="activitiesApp.activity.departs">Departs</Translate>
            </span>
          </dt>
          <dd>{activityEntity.departs}</dd>
          <dt>
            <span id="restrictions">
              <Translate contentKey="activitiesApp.activity.restrictions">Restrictions</Translate>
            </span>
          </dt>
          <dd>{activityEntity.restrictions}</dd>
          <dt>
            <span id="freeCancellation">
              <Translate contentKey="activitiesApp.activity.freeCancellation">Free Cancellation</Translate>
            </span>
          </dt>
          <dd>{activityEntity.freeCancellation ? 'true' : 'false'}</dd>
          <dt>
            <span id="cancellationNote">
              <Translate contentKey="activitiesApp.activity.cancellationNote">Cancellation Note</Translate>
            </span>
          </dt>
          <dd>{activityEntity.cancellationNote}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.diningDetails">Dining Details</Translate>
          </dt>
          <dd>{activityEntity.diningDetails ? activityEntity.diningDetails.name : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.location">Location</Translate>
          </dt>
          <dd>{activityEntity.location ? activityEntity.location.cityName : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.user">User</Translate>
          </dt>
          <dd>{activityEntity.user ? activityEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.keyword">Keyword</Translate>
          </dt>
          <dd>
            {activityEntity.keywords
              ? activityEntity.keywords.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {activityEntity.keywords && i === activityEntity.keywords.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.applicationUser">Application User</Translate>
          </dt>
          <dd>
            {activityEntity.applicationUsers
              ? activityEntity.applicationUsers.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {activityEntity.applicationUsers && i === activityEntity.applicationUsers.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.activity.bookingUser">Booking User</Translate>
          </dt>
          <dd>
            {activityEntity.bookingUsers
              ? activityEntity.bookingUsers.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {activityEntity.bookingUsers && i === activityEntity.bookingUsers.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/activity" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity/${activityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityDetail;
