import React from 'react';
import './data-loading.scss';
import { Row, Col, Alert } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../config/store';
import { hasAnyAuthority } from '../shared/auth/private-route';
import { AUTHORITIES } from '../config/constants';

export const DataLoading = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isUser = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.USER]));
  const isCustomer = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CUSTOMER]));

  return (
    <>
      <Row>
        <Col md="2"></Col>
        <Col md="8">
          <div>
            {isAuthenticated && isAdmin && (
              <table>
                <tr>
                  <td>
                    <a href="/activity"> Activity</a>
                  </td>
                  <td>
                    <a href="/activity-price"> Activity Price</a>
                  </td>
                  <td>
                    <a href="/activity-availability">Activity Availability</a>
                  </td>
                  <td>
                    <a href="/activity-time-availability">Activity Time Availability</a>
                  </td>
                  <td>
                    <a href="/activity-image">Activity Image</a>
                  </td>
                  <td>
                    <a href="/activity-highlight">Highlights</a>
                  </td>
                  <td>
                    <a href="/activity-included-item">Included Items</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <a href="/application-user"> Users</a>
                  </td>
                  <td>
                    <a href="/activity-note"> Notes</a>
                  </td>
                  <td>
                    <a href="/country">country</a>
                  </td>
                  <td>
                    <a href="/location">Location</a>
                  </td>
                  <td>
                    <a href="/city">City</a>
                  </td>
                  <td>
                    <a href="/booking">Bookings</a>
                  </td>
                  <td>
                    <a href="/activity-review">Reviews</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <a href="/booking-pament"> Payments</a>
                  </td>
                  <td>
                    <a href="/booking-user">Booking Users</a>
                  </td>
                  <td>
                    <a href="/booking-pax">Pax</a>
                  </td>
                  <td>
                    <a href="/week-day-time">Week Day Time</a>
                  </td>
                  <td>
                    <a href="/week-day-time-availability">Week Day Time Availability</a>
                  </td>
                  <td>
                    <a href="/activity-schedule">Activity Schedule</a>
                  </td>
                  <td>
                    <a href="/booking-time">Booking Times</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <a href="/deal"> Deals</a>
                  </td>
                  <td>
                    <a href="/itinerary">Itinerary</a>
                  </td>
                  <td>
                    <a href="/content-images">Content Images</a>
                  </td>
                  <td>
                    <a href="/critical-logging">Logging</a>
                  </td>
                  <td>
                    <a href="/site-settings">Site Settings</a>
                  </td>
                  <td>
                    <a href="/activity-faq">Activity Faqs</a>
                  </td>
                  <td>
                    <a href="/keyword">Keywords</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <a href="/dining-details"> Dining</a>
                  </td>
                  <td>
                    <a href="/meal-type">MealType</a>
                  </td>
                  <td>
                    <a href="/cusine">Cusine</a>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table>
            )}

            <div></div>
            <div></div>
          </div>
        </Col>
        <Col md="2"></Col>
      </Row>
      <Row>
        <Col md="2"></Col>
        <Col md="8">
          <div>
            {isAuthenticated && isCustomer && (
              <table>
                <tr>
                  <td>
                    <a href="/booking">Bookings</a>
                  </td>
                  <td>
                    <a href="/activity-review">Reviews</a>
                  </td>
                  <td>
                    <a href="/booking-pament"> Payments</a>
                  </td>
                </tr>
              </table>
            )}
            <div></div>
            <div></div>
          </div>
        </Col>
        <Col md="2"></Col>
      </Row>
    </>
  );
};

export default DataLoading;

/*

<DropdownMenu style={{ zIndex: '999' }}>
            {(isAdmin || isUser) && (
              <>


                {isAdmin && (




                    <DropdownItem
                      icon="asterisk"
                      onClick={() => {
                        navigate('/keyword');
                      }}
                    >
                      <Translate contentKey="global.menu.entities.keyword" />
                    </DropdownItem>
                  </>
*/
