import React from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Landing from 'app/modules/customer/landing';
import Activity from 'app/modules/customer/activity';
import PaxDetails from 'app/modules/customer/paxdetails';
import Booking from 'app/modules/customer/booking';
import LandingIndex from 'app/modules/customer/landingindex';

const loading = <div>loading ...</div>;
import HomeActivity from './modules/customer/homeactivity';
import { Results } from './modules/customer/results/restuls';
import Experience from './modules/customer/experience/experience';
import ExtendedResults from './modules/customer/results/extendedresults';
import CityResult from './modules/customer/v2/cityresults';
import MyComponent from './modules/customer/v2/city-dump';
import DataLoading from './customer/data-loading';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        {/* <Route index element={<Home />} /> */}
        {/* <Route index element={<HomeActivity />} /> */}
        <Route index element={<CityResult />} />
        {/* <Route index element={<MyComponent />} /> */}
        <Route path="landingindex/:cityParam" element={<LandingIndex />} />
        {/* <Route path="landingindex/:cityParam" element={<CityResult />} /> */}
        <Route path="customer/:cityParam/:clientTypeInt" element={<Landing />} />
        <Route path="customer/:clientTypeInt/:cityParam/:keywordParam" element={<Landing />} />
        <Route path="landingindex" element={<LandingIndex />} />
        <Route
          path="dataloading"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.CUSTOMER]}>
              <DataLoading />
            </PrivateRoute>
          }
        />
        <Route path="dataloading" element={<DataLoading />} />
        <Route path="selectedActivity/:activityId" element={<Activity />} />
        <Route path="booking/:bookingId" element={<Booking />} />
        <Route path="paxdetails" element={<PaxDetails />} />
        {/* <Route path="results/:cityParam" element={<Results />} /> */}
        <Route path="results/:cityParam" element={<CityResult />} />
        <Route path="results/:cityParam/:keywordParam" element={<ExtendedResults />} />
        <Route path="experience/:activityId" element={<Experience />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER, AUTHORITIES.CUSTOMER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER, AUTHORITIES.CUSTOMER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
