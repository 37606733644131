import React from 'react';
import { Row } from 'reactstrap';

export const CategoryHeader = () => {
  return (
    <>
      <Row>
        <div className="col-md-12 text-center promotionCage ">
          <p>SAVE ON BALI EXPERIENCES &gt; </p>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 text-center img-fluid p-0">
          <img src="../../content/images/image_mix.png" width="100%" alt="Logo" />
        </div>
        `
      </Row>
    </>
  );
};

export default CategoryHeader;
