/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import RatingChart from './rating-chart';

interface Props {
  totalReview: number;
  averageReview: number;
  images: Array<{ imageShortDescription: string }>;
  total?: number;
  reviewMap: Map<number, number>;
}

const Ratings = ({ totalReview, averageReview, images, total, reviewMap }: Props) => {
  return (
    <div className="tw-grid tw-grid-cols-2 tw-mt-12 md:tw-mt-24 tw-gap-12">
      <div className="tw-flex tw-col-span-2 md:tw-col-span-1 tw-flex-col tw-items-start tw-space-y-12">
        <div className="tw-bg-gray tw-pb-3 tw-border-b-sky-500 tw-border-b-2">
          <h3 className="tw-text-2xl tw-font-semibold tw-text-sky-500">Average Rating & Photos</h3>
        </div>

        <div className="tw-flex tw-items-center tw-w-full tw-justify-center">
          <div className="tw-bg-sky-500 tw-text-white tw-rounded-full tw-h-[250px] tw-w-[250px] tw-flex tw-items-center tw-justify-center tw-flex-col">
            <span className="tw-text-[120px] tw-text-white tw-leading-tight tw-font-bold">{averageReview?.toFixed(1)}</span>
            <span className="tw-text-xs tw-text-white">base on total {totalReview} review</span>
          </div>
        </div>

        <div className="tw-w-full">{reviewMap && <RatingChart total={total} reviewMap={reviewMap} />}</div>
      </div>

      <div className="tw-col-span-2 md:tw-col-span-1">
        <div className="tw-grid tw-grid-cols-2 tw-gap-3 md:tw-gap-6">
          {images?.slice(0, 4)?.map((image, index) => (
            <img key={index} className="tw-w-full tw-object-cover tw-h-[250px]" src={image.imageShortDescription} alt="image" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ratings;
