import React from 'react';
import Results from './restuls';

const ExtendedResults = () => {
  return (
    <>
      <Results></Results>
    </>
  );
};

export default ExtendedResults;
