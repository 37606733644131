import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IBookingUser } from 'app/shared/model/booking-user.model';
import { getEntities as getBookingUsers } from 'app/entities/booking-user/booking-user.reducer';
import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IBooking } from 'app/shared/model/booking.model';
import { BookingStatus } from 'app/shared/model/enumerations/booking-status.model';
import { getEntity, updateEntity, createEntity, reset } from './booking.reducer';
import { IWeekDayTime } from 'app/shared/model/week-day-time.model';
import { getEntities as getWeekDayTimes } from 'app/entities/week-day-time/week-day-time.reducer';

export const BookingUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const bookingUsers = useAppSelector(state => state.bookingUser.entities);
  const activities = useAppSelector(state => state.activity.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const bookingEntity = useAppSelector(state => state.booking.entity);
  const loading = useAppSelector(state => state.booking.loading);
  const updating = useAppSelector(state => state.booking.updating);
  const updateSuccess = useAppSelector(state => state.booking.updateSuccess);
  const bookingStatusValues = Object.keys(BookingStatus);
  const weekDayTimes = useAppSelector(state => state.weekDayTime.entities);

  const handleClose = () => {
    navigate('/booking' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getBookingUsers({}));
    dispatch(getActivities({}));
    dispatch(getUsers({}));
    dispatch(getWeekDayTimes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.modifiedDate = convertDateTimeToServer(values.modifiedDate);
    const entity = {
      ...bookingEntity,
      ...values,
      bookingUser: bookingUsers.find(it => it.id.toString() === values.bookingUser.toString()),
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
      user: users.find(it => it.id.toString() === values.user.toString()),
      weekDayTime: weekDayTimes.find(it => it.id.toString() === values.weekDayTime.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          modifiedDate: displayDefaultDateTime(),
        }
      : {
          bookingStatus: 'PAID',
          ...bookingEntity,
          createdDate: convertDateTimeFromServer(bookingEntity.createdDate),
          modifiedDate: convertDateTimeFromServer(bookingEntity.modifiedDate),
          bookingUser: bookingEntity?.bookingUser?.id,
          activity: bookingEntity?.activity?.id,
          user: bookingEntity?.user?.id,
          weekDayTime: bookingEntity?.weekDayTime?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.booking.home.createOrEditLabel" data-cy="BookingCreateUpdateHeading">
            <Translate contentKey="activitiesApp.booking.home.createOrEditLabel">Create or edit a Booking</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="booking-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.booking.adultCount')}
                id="booking-adultCount"
                name="adultCount"
                data-cy="adultCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.childCount')}
                id="booking-childCount"
                name="childCount"
                data-cy="childCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.infantCount')}
                id="booking-infantCount"
                name="infantCount"
                data-cy="infantCount"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.bookingDate')}
                id="booking-bookingDate"
                name="bookingDate"
                data-cy="bookingDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.totalPrice')}
                id="booking-totalPrice"
                name="totalPrice"
                data-cy="totalPrice"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.bookingStatus')}
                id="booking-bookingStatus"
                name="bookingStatus"
                data-cy="bookingStatus"
                type="select"
              >
                {bookingStatusValues.map(bookingStatus => (
                  <option value={bookingStatus} key={bookingStatus}>
                    {translate('activitiesApp.BookingStatus.' + bookingStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.booking.bookingGUID')}
                id="booking-bookingGUID"
                name="bookingGUID"
                data-cy="bookingGUID"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.createdDate')}
                id="booking-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.modifiedDate')}
                id="booking-modifiedDate"
                name="modifiedDate"
                data-cy="modifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.booking.reviewRequestEmailCount')}
                id="booking-reviewRequestEmailCount"
                name="reviewRequestEmailCount"
                data-cy="reviewRequestEmailCount"
                type="text"
              />
              <ValidatedField
                id="booking-bookingUser"
                name="bookingUser"
                data-cy="bookingUser"
                label={translate('activitiesApp.booking.bookingUser')}
                type="select"
              >
                <option value="" key="0" />
                {bookingUsers
                  ? bookingUsers.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.firsName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="booking-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.booking.activity')}
                type="select"
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="booking-user" name="user" data-cy="user" label={translate('activitiesApp.booking.user')} type="select">
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="booking-weekDayTime"
                name="weekDayTime"
                data-cy="weekDayTime"
                label={translate('activitiesApp.booking.weekDayTime')}
                type="select"
              >
                <option value="" key="0" />
                {weekDayTimes
                  ? weekDayTimes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/booking" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BookingUpdate;
