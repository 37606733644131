import React from 'react';
import { ClockIcon, MapPinIcon, NoSymbolIcon, ClipboardIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import isEmpty from 'lodash/isEmpty';

interface Props {
  duration: string;
  location: string;
  restriction: string;
  notes: Array<{ id: string; itemDetails: string }>;
  price: number;
}

const Features = ({ duration, location, restriction, notes, price }: Props) => {
  return (
    <div className="lg:tw-px-12 tw-mt-8">
      <div className="tw-grid tw-grid-cols-2 tw-mt-12 tw-space-x-4">
        <div className="tw-space-y-6">
          <div className="tw-flex tw-space-x-3">
            <ClockIcon className="tw-w-8 tw-h-8 tw-text-sky-500" />
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-sm tw-font-semibold tw-text-sky-500">DURATION</span>
              <span className="tw-text-xs tw-text-gray-500">{duration}</span>
            </div>
          </div>
          <div className="tw-flex tw-space-x-3">
            <MapPinIcon className="tw-w-8 tw-h-8 tw-text-sky-500" />
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-sm tw-font-semibold tw-text-sky-500">LOCATION</span>
              <span className="tw-text-xs tw-text-gray-500">{location}</span>
            </div>
          </div>

          <div className="tw-flex tw-space-x-3">
            <NoSymbolIcon className="tw-w-8 tw-h-8 tw-text-sky-500" />
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-sm tw-font-semibold tw-text-sky-500">RESTRICTION</span>
              <span className="tw-text-xs tw-text-gray-500">{restriction || '-'}</span>
            </div>
          </div>
        </div>

        <div className="tw-space-y-6">
          <div className="tw-flex tw-space-x-3">
            <ClipboardIcon className="tw-w-8 tw-h-8 tw-text-sky-500" />
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-sm tw-font-semibold tw-text-sky-500">NOTES</span>
              {!isEmpty(notes) ? (
                <>
                  {notes?.map(note => (
                    <span className="tw-text-xs tw-text-gray-500">{note.itemDetails || '-'}</span>
                  ))}
                </>
              ) : (
                '-'
              )}
            </div>
          </div>

          <div className="tw-flex tw-space-x-3">
            <CurrencyDollarIcon className="tw-w-8 tw-h-8 tw-text-sky-500" />
            <div className="tw-flex tw-flex-col">
              <span className="tw-text-sm tw-font-semibold tw-text-sky-500">PRICE</span>
              <span className="tw-text-xs tw-text-gray-500">{price}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
