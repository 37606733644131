import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityReview from './activity-review';
import ActivityReviewDetail from './activity-review-detail';
import ActivityReviewUpdate from './activity-review-update';
import ActivityReviewDeleteDialog from './activity-review-delete-dialog';

const ActivityReviewRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityReview />} />
    <Route path="new" element={<ActivityReviewUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityReviewDetail />} />
      <Route path="edit" element={<ActivityReviewUpdate />} />
      <Route path="delete" element={<ActivityReviewDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityReviewRoutes;
