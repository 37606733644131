import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './content-images.reducer';

export const ContentImagesDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const contentImagesEntity = useAppSelector(state => state.contentImages.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="contentImagesDetailsHeading">
          <Translate contentKey="activitiesApp.contentImages.detail.title">ContentImages</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{contentImagesEntity.id}</dd>
          <dt>
            <span id="contentImage">
              <Translate contentKey="activitiesApp.contentImages.contentImage">Content Image</Translate>
            </span>
          </dt>
          <dd>
            {contentImagesEntity.contentImage ? (
              <div>
                {contentImagesEntity.contentImageContentType ? (
                  <a onClick={openFile(contentImagesEntity.contentImageContentType, contentImagesEntity.contentImage)}>
                    <img
                      src={`data:${contentImagesEntity.contentImageContentType};base64,${contentImagesEntity.contentImage}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {contentImagesEntity.contentImageContentType}, {byteSize(contentImagesEntity.contentImage)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="name">
              <Translate contentKey="activitiesApp.contentImages.name">Name</Translate>
            </span>
          </dt>
          <dd>{contentImagesEntity.name}</dd>
          <dt>
            <span id="imageType">
              <Translate contentKey="activitiesApp.contentImages.imageType">Image Type</Translate>
            </span>
          </dt>
          <dd>{contentImagesEntity.imageType}</dd>
          <dt>
            <span id="activityType">
              <Translate contentKey="activitiesApp.contentImages.activityType">Activity Type</Translate>
            </span>
          </dt>
          <dd>{contentImagesEntity.activityType}</dd>
          <dt>
            <Translate contentKey="activitiesApp.contentImages.city">City</Translate>
          </dt>
          <dd>{contentImagesEntity.city ? contentImagesEntity.city.cityName : ''}</dd>
        </dl>
        <Button tag={Link} to="/content-images" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/content-images/${contentImagesEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ContentImagesDetail;
