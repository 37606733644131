export enum ContentImageType {
  DEALS = 'DEALS',

  CATEGORY = 'CATEGORY',

  SLIDER = 'SLIDER',

  OTHER = 'OTHER',

  LOGO = 'LOGO',
}
