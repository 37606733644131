import { ACTION_TYPES } from './customerreducer';

export const setPaxData = (paxData): any => ({
  type: ACTION_TYPES.ADD_PAX,
  payload: paxData,
  error: null,
});

export const updatePaxDataElement = (index, newValue): any => ({
  type: ACTION_TYPES.UPDATE_PAX,
  payload: { index, newValue },
  error: null,
});

export const updateSelectedDate = (selectedDate): any => ({
  type: ACTION_TYPES.SELECT_DATE,
  payload: selectedDate,
  error: null,
});

export const updateSelectedTime = (selectedTime): any => ({
  type: ACTION_TYPES.SELECT_TIME,
  payload: selectedTime,
  error: null,
});

export const removePassenger = () => ({
  type: ACTION_TYPES.REMOVE_PAX,
});

export const setActivityData = (activity): any => ({
  type: ACTION_TYPES.ACTIVITY_DATA,
  payload: activity,
  error: null,
});

export const setActivityTimeData = (activityTime): any => ({
  type: ACTION_TYPES.ACTIVITY_TIME,
  payload: activityTime,
  error: null,
});
