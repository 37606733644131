import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity-availability.reducer';

export const ActivityAvailabilityDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityAvailabilityEntity = useAppSelector(state => state.activityAvailability.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityAvailabilityDetailsHeading">
          <Translate contentKey="activitiesApp.activityAvailability.detail.title">ActivityAvailability</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityAvailabilityEntity.id}</dd>
          <dt>
            <span id="fromDate">
              <Translate contentKey="activitiesApp.activityAvailability.fromDate">From Date</Translate>
            </span>
          </dt>
          <dd>
            {activityAvailabilityEntity.fromDate ? (
              <TextFormat value={activityAvailabilityEntity.fromDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="toDate">
              <Translate contentKey="activitiesApp.activityAvailability.toDate">To Date</Translate>
            </span>
          </dt>
          <dd>
            {activityAvailabilityEntity.toDate ? (
              <TextFormat value={activityAvailabilityEntity.toDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="adultCount">
              <Translate contentKey="activitiesApp.activityAvailability.adultCount">Adult Count</Translate>
            </span>
          </dt>
          <dd>{activityAvailabilityEntity.adultCount}</dd>
          <dt>
            <span id="childCount">
              <Translate contentKey="activitiesApp.activityAvailability.childCount">Child Count</Translate>
            </span>
          </dt>
          <dd>{activityAvailabilityEntity.childCount}</dd>
          <dt>
            <span id="infantCount">
              <Translate contentKey="activitiesApp.activityAvailability.infantCount">Infant Count</Translate>
            </span>
          </dt>
          <dd>{activityAvailabilityEntity.infantCount}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="activitiesApp.activityAvailability.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{activityAvailabilityEntity.userName}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activityAvailability.activitySchedule">Activity Schedule</Translate>
          </dt>
          <dd>{activityAvailabilityEntity.activitySchedule ? activityAvailabilityEntity.activitySchedule.scheduleName : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activityAvailability.activity">Activity</Translate>
          </dt>
          <dd>{activityAvailabilityEntity.activity ? activityAvailabilityEntity.activity.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/activity-availability" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity-availability/${activityAvailabilityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityAvailabilityDetail;
