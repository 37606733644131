import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MealType from './meal-type';
import MealTypeDetail from './meal-type-detail';
import MealTypeUpdate from './meal-type-update';
import MealTypeDeleteDialog from './meal-type-delete-dialog';

const MealTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MealType />} />
    <Route path="new" element={<MealTypeUpdate />} />
    <Route path=":id">
      <Route index element={<MealTypeDetail />} />
      <Route path="edit" element={<MealTypeUpdate />} />
      <Route path="delete" element={<MealTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MealTypeRoutes;
