import dayjs from 'dayjs';
import { IActivityTimeAvailability } from 'app/shared/model/activity-time-availability.model';
import { IActivitySchedule } from 'app/shared/model/activity-schedule.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityAvailability {
  id?: number;
  fromDate?: string | null;
  toDate?: string | null;
  adultCount?: number | null;
  childCount?: number | null;
  infantCount?: number | null;
  userName?: string | null;
  activityTimeAvailabilities?: IActivityTimeAvailability[] | null;
  activitySchedule?: IActivitySchedule | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IActivityAvailability> = {};
