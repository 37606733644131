import { ACTION_TYPES } from './resultsreducer';

export const getShowMapData = (showMap: boolean, cityParam: string): any => ({
  type: ACTION_TYPES.SHOW_MAP,
  payload: showMap,
  city: cityParam,
  error: null,
});

export const getOrderByData = (orderBy: string, cityParam: string): any => ({
  type: ACTION_TYPES.SET_ORDER_BY,
  payload: orderBy,
  city: cityParam,
  error: null,
});

export const getFilterByData = (filterBy: string, cityParam: string): any => ({
  type: ACTION_TYPES.FILETER_BY,
  payload: filterBy,
  city: cityParam,
  error: null,
});

export const getRefreshDataState = (refreshState: number, cityParam: string): any => ({
  type: ACTION_TYPES.FILETER_BY,
  payload: refreshState,
  city: cityParam,
  error: null,
});

export const getResultsData = (results, cityParam): any => ({
  type: ACTION_TYPES.RESULTS,
  payload: results,
  city: cityParam,
  error: null,
});

export const getOrderedResultsData = (orderedResults, cityParam): any => ({
  type: ACTION_TYPES.ORDER_RESULTS,
  payload: orderedResults,
  city: cityParam,
  error: null,
});

export const getCurrentPageData = (pageNo: number, cityParam): any => ({
  type: ACTION_TYPES.CURRENT_PAGE,
  payload: pageNo,
  city: cityParam,
  error: null,
});

export const getDealsData = (deals, cityParam): any => ({
  type: ACTION_TYPES.SET_DEALS,
  payload: deals,
  city: cityParam,
  error: null,
});

export const getReplaceOrderedResultsData = (orderedResults, cityParam): any => ({
  type: ACTION_TYPES.REPLCAE_ORDER_RESULTS,
  payload: orderedResults,
  city: cityParam,
  error: null,
});

export const getResetData = (cityParam): any => ({
  type: ACTION_TYPES.RESET_RESULTS,
  payload: {},
  city: cityParam,
  error: null,
});
