import  {LANDING_ACTION_TYPES}  from './landingreducer';

export const setActivitiesData = (activities) :any => ({
  type: LANDING_ACTION_TYPES.SET_ACTIVITIES,
  payload: activities,
  error:null
});

export const setParametersData = (params) :any => ({
  type: LANDING_ACTION_TYPES.SET_PARAMETERS,
  payload: params,
  error:null
});