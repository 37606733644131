import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivityReview } from 'app/shared/model/activity-review.model';
import { getEntities } from './activity-review.reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams } from '../../shared/reducers/reducer.utils';
import axios from 'axios';

export const ActivityReview = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const activityReviewList = useAppSelector(state => state.activityReview.entities);
  const loading = useAppSelector(state => state.activityReview.loading);
  const totalItems = useAppSelector(state => state.activityReview.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const apiUrl = 'api/activity-reviews';
  const getEntitiesForActivity = createAsyncThunk('activityReview/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&activityId=${inputFilterValue}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IActivityReview[]>(requestUrl);
  });
  const loadFilteredObjects = () => {
    dispatch(
      getEntitiesForActivity({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };
  const [inputFilterValue, setInputFilterValue] = useState('');
  const handleInputChange = event => {
    const value = event.target.value;
    setInputFilterValue(value);
  };

  return (
    <div>
      <h2 id="activity-review-heading" data-cy="ActivityReviewHeading">
        <Translate contentKey="activitiesApp.activityReview.home.title">Activity Reviews</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="activitiesApp.activityReview.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/activity-review/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="activitiesApp.activityReview.home.createLabel">Create new Activity Review</Translate>
          </Link>
        </div>
      </h2>
      <div>
        <ValidatedField
          label="Filer for activity"
          id="activity-filter-text"
          name="toPax"
          data-cy="activityFilter"
          type="text"
          value={inputFilterValue}
          onChange={handleInputChange}
        />
        <Button className="me-2" color="info" onClick={loadFilteredObjects} disabled={loading}>
          Filter
        </Button>
      </div>
      <div className="table-responsive">
        {activityReviewList && activityReviewList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="activitiesApp.activityReview.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reviewDate')}>
                  <Translate contentKey="activitiesApp.activityReview.reviewDate">Review Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('noOfStars')}>
                  <Translate contentKey="activitiesApp.activityReview.noOfStars">No Of Stars</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reviewdUser')}>
                  <Translate contentKey="activitiesApp.activityReview.reviewdUser">Reviewd User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('commnet')}>
                  <Translate contentKey="activitiesApp.activityReview.commnet">Commnet</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('approved')}>
                  <Translate contentKey="activitiesApp.activityReview.approved">Approved</Translate>{' '}
                </th>
                <th className="hand" onClick={sort('bookingId')}>
                  <Translate contentKey="activitiesApp.activityReview.bookingId">Booking Id</Translate>{' '}
                </th>
                <th>
                  <Translate contentKey="activitiesApp.activityReview.activity">Activity</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('recommend')}>
                  <Translate contentKey="activitiesApp.activityReview.recommend">Recommend</Translate>{' '}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activityReviewList.map((activityReview, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/activity-review/${activityReview.id}`} color="link" size="sm">
                      {activityReview.id}
                    </Button>
                  </td>
                  <td>
                    {activityReview.reviewDate ? (
                      <TextFormat type="date" value={activityReview.reviewDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{activityReview.noOfStars}</td>
                  <td>{activityReview.reviewdUser}</td>
                  <td>{activityReview.commnet}</td>
                  <td>{activityReview.approved ? 'true' : 'false'}</td>
                  <td>{activityReview.bookingId}</td>
                  <td>
                    {activityReview.activity ? (
                      <Link to={`/activity/${activityReview.activity.id}`}>{activityReview.activity.name}</Link>
                    ) : (
                      activityReview.activity
                    )}
                  </td>
                  <td>{activityReview.recommend ? 'true' : 'false'}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/activity-review/${activityReview.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-review/${activityReview.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-review/${activityReview.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="activitiesApp.activityReview.home.notFound">No Activity Reviews found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={activityReviewList && activityReviewList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ActivityReview;
