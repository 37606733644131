import { IUser } from 'app/shared/model/user.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface IApplicationUser {
  id?: number;
  additionalField?: number | null;
  internalUser?: IUser | null;
  profileImageContentType?: string | null;
  profileImage?: string | null;
  activities?: IActivity[] | null;
}

export const defaultValue: Readonly<IApplicationUser> = {};
