import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCity } from '../headerreducer';

export const HeaderCity = () => {
  const { cityParam = null } = useParams();
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {}, []);

  const handleCityLinkClick = (city: string) => {
    event.preventDefault();
    dispatch(setSelectedCity(city));
    navigate(`/results/${city}`);
  };

  const handleOnSelect = () => {
    console.log('SEARCH CLICKED');
  };

  return (
    <div className="tw-relative tw-items-stretch tw-flex tw-w-full tw-justify-between tw-gap-5 tw-pr-1.5 tw-max-md:max-w-full tw-max-md:flex-wrap tw-pl-10 tw-pr-10">
      <div className="tw-text-white tw-text-4xl tw-font-bold tw-grow tw-whitespace-nowrap">
        <img src="content/images/logo-balito2.png" alt="Logo" style={{ width: '75px' }} />
      </div>
      <div className="tw-justify-between tw-items-stretch tw-self-center tw-flex tw-gap-5 tw-my-auto tw-max-md:max-w-full tw-max-md:flex-wrap tw-max-md:justify-center">
        <div className="tw-text-black tw-text-2xl tw-font-semibold tw-whitespace-nowrap">
          <a
            className={selectedCity === 'Bali' ? 'nav-link activeV2' : 'nav-link'}
            aria-current="page"
            href="/results/Bali"
            onClick={() => handleCityLinkClick('Bali')}
          >
            BALI
          </a>
        </div>
        <div className="tw-text-black tw-text-2xl tw-whitespace-nowrap">
          <a
            className={selectedCity === 'Lombok' ? 'nav-link activeV2' : 'nav-link'}
            href="/results/Lombok"
            onClick={() => handleCityLinkClick('Lombok')}
          >
            LOMBOK
          </a>
        </div>
        <div className="tw-text-black tw-text-2xl">
          <a
            className={selectedCity === 'Nusa Lembongan' ? 'nav-link activeV2' : 'nav-link'}
            href="/results/Nusa Lembongan"
            onClick={() => handleCityLinkClick('Nusa Lembongan')}
          >
            NUSA LEMBONGAN
          </a>
        </div>
      </div>
    </div>
  );
};
export default HeaderCity;
