import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { openFile, byteSize, Translate, getSortState, JhiPagination, JhiItemCount, ValidatedField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivityImage } from 'app/shared/model/activity-image.model';
import { getEntities } from './activity-image.reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IQueryParams } from '../../shared/reducers/reducer.utils';
import axios from 'axios';

export const ActivityImage = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const activityImageList = useAppSelector(state => state.activityImage.entities);
  const loading = useAppSelector(state => state.activityImage.loading);
  const totalItems = useAppSelector(state => state.activityImage.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const apiUrl = 'api/activity-images';
  const getEntitiesForActivity = createAsyncThunk('activityImage/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiUrl}${
      sort ? `?page=${page}&size=${size}&sort=${sort}&activityId=${inputFilterValue}&` : '?'
    }cacheBuster=${new Date().getTime()}`;
    return axios.get<IActivityImage[]>(requestUrl);
  });
  const [inputFilterValue, setInputFilterValue] = useState('');
  const loadFilteredObjects = () => {
    dispatch(
      getEntitiesForActivity({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };
  const handleInputChange = event => {
    const value = event.target.value;
    setInputFilterValue(value);
  };

  return (
    <div>
      <h2 id="activity-image-heading" data-cy="ActivityImageHeading">
        <Translate contentKey="activitiesApp.activityImage.home.title">Activity Images</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="activitiesApp.activityImage.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/activity-image/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="activitiesApp.activityImage.home.createLabel">Create new Activity Image</Translate>
          </Link>
        </div>
      </h2>
      <div>
        <ValidatedField
          label="Filer for activity"
          id="activity-filter-text"
          name="toPax"
          data-cy="activityFilter"
          type="text"
          value={inputFilterValue}
          onChange={handleInputChange}
        />
        <Button className="me-2" color="info" onClick={loadFilteredObjects} disabled={loading}>
          Filter
        </Button>
      </div>
      <div className="table-responsive">
        {activityImageList && activityImageList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="activitiesApp.activityImage.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('activityImage')}>
                  <Translate contentKey="activitiesApp.activityImage.activityImage">Activity Image</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="activitiesApp.activityImage.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('imageShortDescription')}>
                  <Translate contentKey="activitiesApp.activityImage.imageShortDescription">Image Short Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('imageDescription')}>
                  <Translate contentKey="activitiesApp.activityImage.imageDescription">Image Description</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="activitiesApp.activityImage.activity">Activity</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activityImageList.map((activityImage, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/activity-image/${activityImage.id}`} color="link" size="sm">
                      {activityImage.id}
                    </Button>
                  </td>
                  <td>
                    {activityImage.activityImage ? (
                      <div>
                        {activityImage.activityImageContentType ? (
                          <a onClick={openFile(activityImage.activityImageContentType, activityImage.activityImage)}>
                            <img
                              src={`data:${activityImage.activityImageContentType};base64,${activityImage.activityImage}`}
                              style={{ maxHeight: '30px' }}
                            />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {activityImage.activityImageContentType}, {byteSize(activityImage.activityImage)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>{activityImage.name}</td>
                  <td>{activityImage.imageShortDescription}</td>
                  <td>{activityImage.imageDescription}</td>
                  <td>
                    {activityImage.activity ? <Link to={`/activity/${activityImage.activity.id}`}>{activityImage.activity.name}</Link> : ''}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/activity-image/${activityImage.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-image/${activityImage.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/activity-image/${activityImage.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="activitiesApp.activityImage.home.notFound">No Activity Images found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={activityImageList && activityImageList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ActivityImage;
