import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './cusine.reducer';

export const CusineDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const cusineEntity = useAppSelector(state => state.cusine.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cusineDetailsHeading">
          <Translate contentKey="activitiesApp.cusine.detail.title">Cusine</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="activitiesApp.cusine.active">Active</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="cusineCode">
              <Translate contentKey="activitiesApp.cusine.cusineCode">Cusine Code</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.cusineCode}</dd>
          <dt>
            <span id="cusineName">
              <Translate contentKey="activitiesApp.cusine.cusineName">Cusine Name</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.cusineName}</dd>
          <dt>
            <span id="cusine">
              <Translate contentKey="activitiesApp.cusine.cusine">Cusine</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.cusine}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="activitiesApp.cusine.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{cusineEntity.createdDate ? <TextFormat value={cusineEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="activitiesApp.cusine.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {cusineEntity.modifiedDate ? <TextFormat value={cusineEntity.modifiedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
        </dl>
        <Button tag={Link} to="/cusine" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cusine/${cusineEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CusineDetail;
