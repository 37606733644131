import React, { useEffect, useMemo, useState } from 'react';
import CategoryHeader from './categoryheader';
import HighlightsHeader from './highlights';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import ResultsBody from './restulsbody';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './resultsbody.scss';
import ResultsMap from './resultsmap';
import { useSelector } from 'react-redux';
import {
  getFilterByData,
  getOrderByData,
  getOrderedResultsData,
  getResultsData,
  getShowMapData,
  getCurrentPageData,
  getDealsData,
  getReplaceOrderedResultsData,
  getResetData,
  getRefreshDataState,
} from './resultsactions';
import { setSelectedCity } from './../../../shared/layout/header/headerreducer';
import { getKeywordAction } from 'app/modules/customer/results/keywordreducer';
import { getActivityTypeAction } from 'app/modules/customer/results/activitytypereducer';
import ShowMoreResults from './showmoreresults';
import LoadingDialog from '../loadingdialog';

export const Results = () => {
  const activityTypes = [
    'ADVENTURE',
    'NIGHTTIME',
    'WELLNESS',
    'KIDSPLAY',
    'TOURINGBALI',
    'GETWET',
    'ADRENALIN',
    'TRANSFERS',
    'CULTURALSITES',
  ];

  const { cityParam = null } = useParams();
  const { clientTypeInt } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const resultsReducer = useSelector((state: any) => state.resultsReducer);
  const keywordReducer = useSelector((state: any) => state.keywordReducer.keyword);
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);
  const [loading, setLoading] = useState(false);
  const [showDeals, setShowDeals] = useState(true);
  const [refresh, setRefresh] = useState(true);

  const getCityIndex = (cityParam: string): number => {
    switch (cityParam) {
      case 'Bali':
        return 0;
      case 'Lombok':
        return 1;
      case 'Nusa Lembongan':
        return 2;
      default:
        return 0;
    }
  };

  const refreshToggle = () => {
    setRefresh(!refresh);
  };

  const getSelectedActivityType = () => {
    if (clientTypeInt && parseInt(clientTypeInt) < 0) return '';
    if (clientTypeInt) return activityTypes[parseInt(clientTypeInt) - 4];
    return '';
  };

  const apiUrl = `/api/home/get-sortable-pagable-results?cityName=${cityParam}&keyword=${keywordReducer}&page=${
    resultsReducer[getCityIndex(cityParam)]?.currentPage
  }&size=9&activityType=${getSelectedActivityType()}`;
  const dealsApiUrl = `/api/home/activity-deals?cityName=${cityParam}`;

  const fetchCityResults = async () => {
    try {
      setLoading(true);
      setShowDeals(true);
      const response = await fetch(apiUrl);
      const data = await response.json();
      dispatch(getResultsData({ ...data }, cityParam));
      dispatch(getOrderedResultsData({ ...data }, cityParam));
      dispatch(getKeywordAction(''));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error fetching activities:', error);
    }
  };

  const fetchCityResultsForKeyword = async () => {
    try {
      setLoading(true);
      setShowDeals(false);
      const apiUrlX = `/api/home/get-sortable-pagable-results?cityName=${cityParam}&keyword=${keywordReducer}&page=0&size=9&activityType=`;
      const response = await fetch(apiUrlX);
      const data = await response.json();
      dispatch(getResultsData({ ...data }, cityParam));
      dispatch(getOrderedResultsData({ ...data }, cityParam));
      dispatch(getKeywordAction(''));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error fetching activities:', error);
    }
  };

  const fetchCityDeals = async () => {
    try {
      setLoading(true);
      const response = await fetch(dealsApiUrl);
      const data = await response.json();
      dispatch(getDealsData(data, cityParam));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error fetching activities:', error);
    }
  };

  const fetchDeals = async () => {
    if (
      cityParam != null &&
      cityParam !== '' &&
      (!resultsReducer[getCityIndex(cityParam)] ||
        !resultsReducer[getCityIndex(cityParam)]?.deals ||
        !resultsReducer[getCityIndex(cityParam)]?.deals?.length ||
        resultsReducer[getCityIndex(cityParam)]?.deals?.length == 0)
    ) {
      try {
        const response = await fetch(dealsApiUrl);
        const data = await response.json();
        dispatch(getDealsData(data, cityParam));
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };

  const refreshAction = () => {
    dispatch(getResetData(cityParam));
    //dispatch(getOrderByData('Discount',cityParam));
    //dispatch(getFilterByData('',cityParam));
    navigate(`/results/${cityParam}`);
    dispatch(getRefreshDataState(resultsReducer[getCityIndex(cityParam)]?.refresh + 2, cityParam));
    refreshToggle();
  };

  useEffect(() => {
    console.log('cityParam, keywordReducer called 1');
    dispatch(setSelectedCity(cityParam)); //check wehter we need this
    fetchDeals();
    let fetchCityResultsCalled = false;

    if (keywordReducer && keywordReducer != '') {
      fetchCityResultsCalled = true;
      dispatch(getResetData(cityParam));
      fetchCityResultsForKeyword();
    }

    if (!resultsReducer[getCityIndex(cityParam)] || resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults == null) {
      if (!fetchCityResultsCalled) fetchCityResults();
    }
  }, [cityParam, keywordReducer, refresh]);

  useEffect(() => {
    if (resultsReducer[getCityIndex(cityParam)]?.currentPage > 0) {
      fetchCityResults();
    }
  }, [resultsReducer[0]?.currentPage, resultsReducer[1]?.currentPage, resultsReducer[2]?.currentPage]);

  useEffect(() => {
    if (resultsReducer[getCityIndex(cityParam)] && resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults) {
    } else {
      //dispatch(getActivityTypeAction(getSelectedActivityType()));
      dispatch(getFilterByData(getSelectedActivityType(), cityParam));
    }
  }, [clientTypeInt]);

  useEffect(() => {
    if (resultsReducer[getCityIndex(cityParam)]?.refresh > 0) {
      fetchCityResults();
    }
  }, [resultsReducer[getCityIndex(cityParam)]?.refresh]);

  return (
    <>
      <CategoryHeader key={'0'}></CategoryHeader>
      {showDeals && <HighlightsHeader key={'1'} deals={resultsReducer[getCityIndex(cityParam)]?.deals}></HighlightsHeader>}

      {resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.content && (
        <ResultsBody
          data={resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.content}
          key={'2'}
          loading={loading}
          refreAction={refreshAction}
        ></ResultsBody>
      )}

      <LoadingDialog isLoading={loading}></LoadingDialog>
    </>
  );
};
export default Results;
