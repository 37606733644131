import { IBooking } from 'app/shared/model/booking.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface IBookingUser {
  id?: number;
  title?: string | null;
  firsName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  sprcialRequirements?: string | null;
  state?: string | null;
  booking?: IBooking | null;
  activities?: IActivity[] | null;
}

export const defaultValue: Readonly<IBookingUser> = {};
