const initialState = {
  paxData: [],
  counter: 0,
  selectedTime: null,
  selectedDate: null,
  activity: null,
};

export const ACTION_TYPES = {
  ADD_PAX: 'customComponent/ADD_PAX',
  REMOVE_PAX: 'customComponent/REMOVE_PAX',
  ACTIVITY_DATA: 'customComponent/ACTIVITY_DATA',
  ACTIVITY_TIME: 'customComponent/ACTIVITY_TIME',
  UPDATE_PAX: 'customComponent/UPDATE_PAX',
  SELECT_DATE: 'customComponent/SELECT_DATE',
  SELECT_TIME: 'customComponent/SELECT_TIME',
  // Add more action types as needed
};

// Use the initialState as a default value
const CustomerReducer = (state = initialState, action) => {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case ACTION_TYPES.ADD_PAX:
      return {
        ...state,
        paxData: action.payload,
      };
    case ACTION_TYPES.UPDATE_PAX:
      const { index, newValue } = action.payload;
      const newArrayData = [...state.paxData];
      const newPaxDataObject = { ...newArrayData[index] };
      newPaxDataObject.paxCount = newValue;
      newArrayData[index] = newPaxDataObject;
      return {
        ...state,
        paxData: newArrayData,
      };
    case ACTION_TYPES.SELECT_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case ACTION_TYPES.SELECT_TIME:
      return {
        ...state,
        selectedTime: action.payload,
      };
    case ACTION_TYPES.REMOVE_PAX:
      return state;
    case ACTION_TYPES.ACTIVITY_DATA:
      return {
        ...state,
        activity: action.payload,
      };
    case ACTION_TYPES.ACTIVITY_TIME:
      return {
        ...state,
        selectedTime: action.payload,
      };
    // Do something here based on the different types of actions
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged
      return state;
  }
};

export default CustomerReducer;
