import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityImage {
  id?: number;
  activityImageContentType?: string | null;
  activityImage?: string | null;
  name?: string | null;
  imageShortDescription?: string | null;
  imageDescription?: string | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IActivityImage> = {};
