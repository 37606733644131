import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity-faq.reducer';

export const ActivityFaqDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityFaqEntity = useAppSelector(state => state.activityFaq.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityFaqDetailsHeading">
          <Translate contentKey="activitiesApp.activityFaq.detail.title">ActivityFaq</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityFaqEntity.id}</dd>
          <dt>
            <span id="question">
              <Translate contentKey="activitiesApp.activityFaq.question">Question</Translate>
            </span>
          </dt>
          <dd>{activityFaqEntity.question}</dd>
          <dt>
            <span id="asnwer">
              <Translate contentKey="activitiesApp.activityFaq.asnwer">Asnwer</Translate>
            </span>
          </dt>
          <dd>{activityFaqEntity.asnwer}</dd>
          <dt>
            <span id="faqNote">
              <Translate contentKey="activitiesApp.activityFaq.faqNote">Faq Note</Translate>
            </span>
          </dt>
          <dd>{activityFaqEntity.faqNote}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="activitiesApp.activityFaq.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {activityFaqEntity.createdDate ? (
              <TextFormat value={activityFaqEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="modifiedDate">
              <Translate contentKey="activitiesApp.activityFaq.modifiedDate">Modified Date</Translate>
            </span>
          </dt>
          <dd>
            {activityFaqEntity.modifiedDate ? (
              <TextFormat value={activityFaqEntity.modifiedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="faqImage">
              <Translate contentKey="activitiesApp.activityFaq.faqImage">Faq Image</Translate>
            </span>
          </dt>
          <dd>
            {activityFaqEntity.faqImage ? (
              <div>
                {activityFaqEntity.faqImageContentType ? (
                  <a onClick={openFile(activityFaqEntity.faqImageContentType, activityFaqEntity.faqImage)}>
                    <img
                      src={`data:${activityFaqEntity.faqImageContentType};base64,${activityFaqEntity.faqImage}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {activityFaqEntity.faqImageContentType}, {byteSize(activityFaqEntity.faqImage)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="activitiesApp.activityFaq.activity">Activity</Translate>
          </dt>
          <dd>{activityFaqEntity.activity ? activityFaqEntity.activity.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/activity-faq" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity-faq/${activityFaqEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityFaqDetail;
