import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './location.reducer';

export const LocationDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const locationEntity = useAppSelector(state => state.location.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="locationDetailsHeading">
          <Translate contentKey="activitiesApp.location.detail.title">Location</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{locationEntity.id}</dd>
          <dt>
            <span id="locationName">
              <Translate contentKey="activitiesApp.location.locationName">Location Name</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationName}</dd>
          <dt>
            <span id="streetAddress">
              <Translate contentKey="activitiesApp.location.streetAddress">Street Address</Translate>
            </span>
          </dt>
          <dd>{locationEntity.streetAddress}</dd>
          <dt>
            <span id="postalCode">
              <Translate contentKey="activitiesApp.location.postalCode">Postal Code</Translate>
            </span>
          </dt>
          <dd>{locationEntity.postalCode}</dd>
          <dt>
            <span id="cityName">
              <Translate contentKey="activitiesApp.location.cityName">City Name</Translate>
            </span>
          </dt>
          <dd>{locationEntity.cityName}</dd>
          <dt>
            <span id="stateProvince">
              <Translate contentKey="activitiesApp.location.stateProvince">State Province</Translate>
            </span>
          </dt>
          <dd>{locationEntity.stateProvince}</dd>
          <dt>
            <span id="locationNote">
              <Translate contentKey="activitiesApp.location.locationNote">Location Note</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationNote}</dd>
          <dt>
            <span id="locationPhone">
              <Translate contentKey="activitiesApp.location.locationPhone">Location Phone</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationPhone}</dd>
          <dt>
            <span id="locationMobile">
              <Translate contentKey="activitiesApp.location.locationMobile">Location Mobile</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationMobile}</dd>
          <dt>
            <span id="locationEmail">
              <Translate contentKey="activitiesApp.location.locationEmail">Location Email</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationEmail}</dd>
          <dt>
            <span id="locationContactPerson">
              <Translate contentKey="activitiesApp.location.locationContactPerson">Location Contact Person</Translate>
            </span>
          </dt>
          <dd>{locationEntity.locationContactPerson}</dd>
          <dt>
            <span id="longitude">
              <Translate contentKey="activitiesApp.location.longitude">Longitude</Translate>
            </span>
          </dt>
          <dd>{locationEntity.longitude}</dd>
          <dt>
            <span id="latitude">
              <Translate contentKey="activitiesApp.location.latitude">Latitude</Translate>
            </span>
          </dt>
          <dd>{locationEntity.latitude}</dd>
          <dt>
            <Translate contentKey="activitiesApp.location.city">City</Translate>
          </dt>
          <dd>{locationEntity.city ? locationEntity.city.cityName : ''}</dd>
        </dl>
        <Button tag={Link} to="/location" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/location/${locationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LocationDetail;
