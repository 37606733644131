/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel';

interface Props {
  images: Array<{ imageShortDescription: string }>;
}

const Carousel = ({ images }: Props) => {
  return (
    <div className="tw-mt-8">
      <ResponsiveCarousel showArrows={true} showThumbs={false} autoPlay={true} infiniteLoop={true}>
        {images?.map((image, index) => (
          <div key={index}>
            <img src={image?.imageShortDescription} />
          </div>
        ))}
      </ResponsiveCarousel>
    </div>
  );
};

export default Carousel;
