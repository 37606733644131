import { IWeekDayTime } from 'app/shared/model/week-day-time.model';

export interface IWeekDayTimeAvailability {
  id?: number;
  adultCount?: number | null;
  childCount?: number | null;
  infantCount?: number | null;
  weekDayTime?: IWeekDayTime | null;
}

export const defaultValue: Readonly<IWeekDayTimeAvailability> = {};
