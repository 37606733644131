
const initialState = {
    activities:[],
    parameters: {clientType:0, city:'', keyword:''}
  }

export const LANDING_ACTION_TYPES = {
    SET_ACTIVITIES: 'landing/SET_ACTIVITIES',
    SET_PARAMETERS: 'landing/SET_PARAMETERS',
    // Add more action types as needed
};
  

  
  // Use the initialState as a default value
 const LandingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANDING_ACTION_TYPES.SET_ACTIVITIES:
            return {
                ...state,
                activities:action.payload
            }
        case LANDING_ACTION_TYPES.SET_PARAMETERS:            
            return {
                ...state,
                parameters:action.payload
            }
        default:
            return state;
        
    }
 }

 export default LandingReducer;