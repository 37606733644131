import { ContentImageType } from 'app/shared/model/enumerations/content-image-type.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { ICity } from 'app/shared/model/city.model';

export interface IContentImages {
  id?: number;
  contentImageContentType?: string | null;
  contentImage?: string | null;
  name?: string | null;
  imageType?: keyof typeof ContentImageType | null;
  activityType?: keyof typeof ActivityType | null;
  city?: ICity | null;
}

export const defaultValue: Readonly<IContentImages> = {};
