import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityNote from './activity-note';
import ActivityNoteDetail from './activity-note-detail';
import ActivityNoteUpdate from './activity-note-update';
import ActivityNoteDeleteDialog from './activity-note-delete-dialog';

const ActivityNoteRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityNote />} />
    <Route path="new" element={<ActivityNoteUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityNoteDetail />} />
      <Route path="edit" element={<ActivityNoteUpdate />} />
      <Route path="delete" element={<ActivityNoteDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityNoteRoutes;
