import { IActivity } from 'app/shared/model/activity.model';

export interface IKeyword {
  id?: number;
  createdDate?: string | null;
  keyword?: string | null;
  valid?: boolean | null;
  activities?: IActivity[] | null;
}

export const defaultValue: Readonly<IKeyword> = {
  valid: false,
};
