import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './booking-user.reducer';

export const BookingUserDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const bookingUserEntity = useAppSelector(state => state.bookingUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bookingUserDetailsHeading">
          <Translate contentKey="activitiesApp.bookingUser.detail.title">BookingUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.id}</dd>
          <dt>
            <span id="title">
              <Translate contentKey="activitiesApp.bookingUser.title">Title</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.title}</dd>
          <dt>
            <span id="firsName">
              <Translate contentKey="activitiesApp.bookingUser.firsName">Firs Name</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.firsName}</dd>
          <dt>
            <span id="lastName">
              <Translate contentKey="activitiesApp.bookingUser.lastName">Last Name</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.lastName}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="activitiesApp.bookingUser.email">Email</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.email}</dd>
          <dt>
            <span id="mobileNumber">
              <Translate contentKey="activitiesApp.bookingUser.mobileNumber">Mobile Number</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.mobileNumber}</dd>
          <dt>
            <span id="sprcialRequirements">
              <Translate contentKey="activitiesApp.bookingUser.sprcialRequirements">Sprcial Requirements</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.sprcialRequirements}</dd>
          <dt>
            <span id="state">
              <Translate contentKey="activitiesApp.bookingUser.state">State</Translate>
            </span>
          </dt>
          <dd>{bookingUserEntity.state}</dd>
          <dt>
            <Translate contentKey="activitiesApp.bookingUser.activity">Activity</Translate>
          </dt>
          <dd>
            {bookingUserEntity.activities
              ? bookingUserEntity.activities.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.name}</a>
                    {bookingUserEntity.activities && i === bookingUserEntity.activities.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/booking-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/booking-user/${bookingUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BookingUserDetail;
