import { IBooking } from 'app/shared/model/booking.model';

export interface IBookingPax {
  id?: number;
  title?: string | null;
  firsName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobileNumber?: string | null;
  sprcialRequirements?: string | null;
  booking?: IBooking | null;
}

export const defaultValue: Readonly<IBookingPax> = {};
