import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityPrice from './activity-price';
import ActivityPriceDetail from './activity-price-detail';
import ActivityPriceUpdate from './activity-price-update';
import ActivityPriceDeleteDialog from './activity-price-delete-dialog';

const ActivityPriceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityPrice />} />
    <Route path="new" element={<ActivityPriceUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityPriceDetail />} />
      <Route path="edit" element={<ActivityPriceUpdate />} />
      <Route path="delete" element={<ActivityPriceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityPriceRoutes;
