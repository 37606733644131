import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity-price.reducer';

export const ActivityPriceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityPriceEntity = useAppSelector(state => state.activityPrice.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityPriceDetailsHeading">
          <Translate contentKey="activitiesApp.activityPrice.detail.title">ActivityPrice</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.id}</dd>
          <dt>
            <span id="fromPax">
              <Translate contentKey="activitiesApp.activityPrice.fromPax">From Pax</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.fromPax}</dd>
          <dt>
            <span id="toPax">
              <Translate contentKey="activitiesApp.activityPrice.toPax">To Pax</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.toPax}</dd>
          <dt>
            <span id="fromDate">
              <Translate contentKey="activitiesApp.activityPrice.fromDate">From Date</Translate>
            </span>
          </dt>
          <dd>
            {activityPriceEntity.fromDate ? (
              <TextFormat value={activityPriceEntity.fromDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="toDate">
              <Translate contentKey="activitiesApp.activityPrice.toDate">To Date</Translate>
            </span>
          </dt>
          <dd>
            {activityPriceEntity.toDate ? (
              <TextFormat value={activityPriceEntity.toDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="adultPrice">
              <Translate contentKey="activitiesApp.activityPrice.adultPrice">Adult Price</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.adultPrice}</dd>
          <dt>
            <span id="childPrice">
              <Translate contentKey="activitiesApp.activityPrice.childPrice">Child Price</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.childPrice}</dd>
          <dt>
            <span id="infantPrice">
              <Translate contentKey="activitiesApp.activityPrice.infantPrice">Infant Price</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.infantPrice}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="activitiesApp.activityPrice.note">Note</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.note}</dd>
          <dt>
            <span id="userName">
              <Translate contentKey="activitiesApp.activityPrice.userName">User Name</Translate>
            </span>
          </dt>
          <dd>{activityPriceEntity.userName}</dd>
          <dt>
            <Translate contentKey="activitiesApp.activityPrice.activity">Activity</Translate>
          </dt>
          <dd>{activityPriceEntity.activity ? activityPriceEntity.activity.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/activity-price" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/activity-price/${activityPriceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ActivityPriceDetail;
