import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityData } from '../customeractions';

import { useParams } from 'react-router-dom';

import Header from './components/header';
import Title from './components/title';
import Description from './components/description';
import Ratings from './components/ratings';
import Carousel from './components/carousel';
import CustomerReviews from './components/customer-reviews';
import Booking from './components/booking';
import Features from './components/features';

export const Experience = () => {
  const { activityId } = useParams();
  const activitySelector = useSelector((state: any) => state.customerReducer.activity);

  const dispatch = useDispatch();

  const activity = activitySelector?.activity;
  const review = activitySelector?.activitiyReviewDTO;

  const fetchActivity = async () => {
    if (activitySelector == null) {
      try {
        const response = await fetch(`/api/home/activity-availability?activityId=${activityId}`);
        const data = await response.json();
        dispatch(setActivityData(data));
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);

  return (
    <>
      <div className="tw-mt-4">
        <Header />
        <div className="tw-max-w-[1000px] tw-m-auto tw-pt-8 lg:tw-pt-20 tw-pb-10 sm:tw-px-10 tw-px-4">
          <Title title={activity?.name} totalReview={review?.totalReviews} averageReview={review?.averageReview} />
          <Carousel images={activity?.activityImages} />
          <br />
          <Description
            summaryDescription={activity?.summaryDescription}
            detailedDescription={activity?.detailedDescription}
            highlights={activity?.activityHighlights}
            itineraries={activity?.itineraries}
          />
          <Features
            duration={activity?.durationNote}
            location={`${activity?.location?.streetAddress} ${activity?.location?.cityName},
            ${activity?.location?.postalCode}`}
            restriction=""
            notes={activity?.activityNotes}
            price={activity?.minPrice}
          />
          <div className="tw-mt-24">
            <Booking />
          </div>
          <Ratings
            totalReview={review?.totalReviews}
            averageReview={review?.averageReview}
            images={activity?.activityImages}
            total={review?.totalReviews}
            reviewMap={review?.reviewMap}
          />
          <CustomerReviews reviews={review?.activityReviews} />
        </div>
      </div>
    </>
  );
};

export default Experience;
