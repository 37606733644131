import dayjs from 'dayjs';
import { IActivityAvailability } from 'app/shared/model/activity-availability.model';
import { Hour } from 'app/shared/model/enumerations/hour.model';
import { Minute } from 'app/shared/model/enumerations/minute.model';
import { AMPM } from 'app/shared/model/enumerations/ampm.model';

export interface IActivityTimeAvailability {
  id?: number;
  fromDate?: string | null;
  toDate?: string | null;
  hour?: keyof typeof Hour | null;
  minute?: keyof typeof Minute | null;
  ampm?: keyof typeof AMPM | null;
  adultCount?: number | null;
  childCount?: number | null;
  infantCount?: number | null;
  userName?: string | null;
  activityAvailability?: IActivityAvailability | null;
}

export const defaultValue: Readonly<IActivityTimeAvailability> = {};
