import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityFaq {
  id?: number;
  question?: string | null;
  asnwer?: string | null;
  faqNote?: string | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  faqImageContentType?: string | null;
  faqImage?: string | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IActivityFaq> = {};
