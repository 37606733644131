import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { ICity } from 'app/shared/model/city.model';
import { getEntities as getCities } from 'app/entities/city/city.reducer';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IContentImages } from 'app/shared/model/content-images.model';
import { ContentImageType } from 'app/shared/model/enumerations/content-image-type.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { getEntity, updateEntity, createEntity, reset } from './content-images.reducer';

export const ContentImagesUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const cities = useAppSelector(state => state.city.entities);
  const contentImagesEntity = useAppSelector(state => state.contentImages.entity);
  const loading = useAppSelector(state => state.contentImages.loading);
  const updating = useAppSelector(state => state.contentImages.updating);
  const updateSuccess = useAppSelector(state => state.contentImages.updateSuccess);
  const contentImageTypeValues = Object.keys(ContentImageType);
  const activityTypeValues = Object.keys(ActivityType);

  const handleClose = () => {
    navigate('/content-images' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getCities({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...contentImagesEntity,
      ...values,
      city: cities.find(it => it.id.toString() === values.city.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          imageType: 'DEALS',
          activityType: 'ADVENTURE',
          ...contentImagesEntity,
          city: contentImagesEntity?.city?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.contentImages.home.createOrEditLabel" data-cy="ContentImagesCreateUpdateHeading">
            <Translate contentKey="activitiesApp.contentImages.home.createOrEditLabel">Create or edit a ContentImages</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="content-images-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedBlobField
                label={translate('activitiesApp.contentImages.contentImage')}
                id="content-images-contentImage"
                name="contentImage"
                data-cy="contentImage"
                isImage
                accept="image/*"
              />
              <ValidatedField
                label={translate('activitiesApp.contentImages.name')}
                id="content-images-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.contentImages.imageType')}
                id="content-images-imageType"
                name="imageType"
                data-cy="imageType"
                type="select"
              >
                {contentImageTypeValues.map(contentImageType => (
                  <option value={contentImageType} key={contentImageType}>
                    {translate('activitiesApp.ContentImageType.' + contentImageType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.contentImages.activityType')}
                id="content-images-activityType"
                name="activityType"
                data-cy="activityType"
                type="select"
              >
                {activityTypeValues.map(activityType => (
                  <option value={activityType} key={activityType}>
                    {translate('activitiesApp.ActivityType.' + activityType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="content-images-city"
                name="city"
                data-cy="city"
                label={translate('activitiesApp.contentImages.city')}
                type="select"
              >
                <option value="" key="0" />
                {cities
                  ? cities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.cityName}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/content-images" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ContentImagesUpdate;
