const initialState = [
  {
    pageNo: 1,
    currentPage: 0,
    showMap: false,
    orderBy: 'Discount',
    filterBy: '',
    results: null,
    sortedOrFiltererdResults: null,
    deals: [],
    refresh: -1,
  },
  {
    pageNo: 1,
    currentPage: 0,
    showMap: false,
    orderBy: 'Discount',
    filterBy: '',
    results: null,
    sortedOrFiltererdResults: null,
    deals: [],
    refresh: -1,
  },
  {
    pageNo: 1,
    currentPage: 0,
    showMap: false,
    orderBy: 'Discount',
    filterBy: '',
    results: null,
    sortedOrFiltererdResults: null,
    deals: [],
    refresh: -1,
  },
];

export const ACTION_TYPES = {
  SHOW_MAP: 'RESULTS/SHOWMAP',
  SET_ORDER_BY: 'RESULTS/RESUTLS_ORDER_BY',
  FILETER_BY: 'RESULTS/FILTER_BY',
  RESULTS: 'RESULTS/RESULTS',
  ORDER_RESULTS: 'RESULTS/ORDER_RESULTS',
  CURRENT_PAGE: 'RESULTS/CURRENT_PAGE',
  SET_DEALS: 'RESULTS/SET_DEALS',
  REPLCAE_ORDER_RESULTS: 'RESULTS/REPLACE_ORDER_RESULTS',
  RESET_RESULTS: 'RESULTS/RESET',
  UPDATE_REFRESHDATA: 'RESULTS/REFRESHDATA',
};

const ResultsReducer = (state = initialState, action) => {
  const getCityDataObject = cityParam => {
    switch (cityParam) {
      case 'Bali':
        return state[0];
      case 'Lombok':
        return state[1];
      case 'Nusa Lembongan':
        return state[2];
      default:
        return state[0];
    }
  };

  const getCityIndex = (cityParam: string): number => {
    switch (cityParam) {
      case 'Bali':
        return 0;
      case 'Lombok':
        return 1;
      case 'Nusa Lembongan':
        return 2;
      default:
        return 0;
    }
  };

  switch (action.type) {
    //const getCityDataObject(cityParam);
    case ACTION_TYPES.SHOW_MAP:
      let updatesState = [...state];
      updatesState[getCityIndex(action.city)] = {
        ...updatesState[getCityIndex(action.city)],
        showMap: action.payload,
      };
      return updatesState;

    case ACTION_TYPES.SET_ORDER_BY:
      let updatesStateOrderBy = [...state];
      updatesStateOrderBy[getCityIndex(action.city)] = {
        ...updatesStateOrderBy[getCityIndex(action.city)],
        orderBy: action.payload,
      };
      return updatesStateOrderBy;

    case ACTION_TYPES.FILETER_BY:
      let updatesStateFilterBy = [...state];
      updatesStateFilterBy[getCityIndex(action.city)] = {
        ...updatesStateFilterBy[getCityIndex(action.city)],
        filterBy: action.payload,
      };
      return updatesStateFilterBy;

    case ACTION_TYPES.RESULTS:
      let updatesStateResults = [...state];
      let tempResultsContent = updatesStateResults[getCityIndex(action.city)]?.results?.content;
      if (tempResultsContent) {
        if (Array.isArray(tempResultsContent)) tempResultsContent = [...tempResultsContent, ...action.payload.content];
        else tempResultsContent = action.payload.content;
      } else {
        tempResultsContent = action.payload.content;
      }
      action.payload.content = tempResultsContent;
      updatesStateResults[getCityIndex(action.city)] = {
        ...updatesStateResults[getCityIndex(action.city)],
        results: action.payload,
      };
      return updatesStateResults;

    case ACTION_TYPES.ORDER_RESULTS:
      let updatesStateOrderResults = [...state];
      let tempOrderResultsContent = updatesStateOrderResults[getCityIndex(action.city)]?.sortedOrFiltererdResults?.content;
      if (tempOrderResultsContent) {
        if (Array.isArray(tempOrderResultsContent)) {
          tempOrderResultsContent = [...tempOrderResultsContent, ...action.payload.content];
        } else tempOrderResultsContent = action.payload.content;
      } else {
        tempOrderResultsContent = action.payload.content;
      }
      action.payload.content = tempOrderResultsContent;
      updatesStateOrderResults[getCityIndex(action.city)] = {
        ...updatesStateOrderResults[getCityIndex(action.city)],
        sortedOrFiltererdResults: action.payload,
      };
      return updatesStateOrderResults;

    case ACTION_TYPES.CURRENT_PAGE:
      let updatesStateCurrentPage = [...state];
      updatesStateCurrentPage[getCityIndex(action.city)] = {
        ...updatesStateCurrentPage[getCityIndex(action.city)],
        currentPage: action.payload,
      };
      return updatesStateCurrentPage;
    case ACTION_TYPES.SET_DEALS:
      let updatesStateSeals = [...state];
      updatesStateSeals[getCityIndex(action.city)] = {
        ...updatesStateSeals[getCityIndex(action.city)],
        deals: action.payload,
      };
      return updatesStateSeals;
    case ACTION_TYPES.REPLCAE_ORDER_RESULTS:
      let updatedOrderedState = [...state];
      updatedOrderedState[getCityIndex(action.city)] = {
        ...updatedOrderedState[getCityIndex(action.city)],
        sortedOrFiltererdResults: action.payload,
      };
      return updatedOrderedState;

    case ACTION_TYPES.RESET_RESULTS:
      let updatesStateToRest = [...state];
      updatesStateToRest[getCityIndex(action.city)] = { ...updatesStateToRest[getCityIndex(action.city)], results: null };
      updatesStateToRest[getCityIndex(action.city)] = { ...updatesStateToRest[getCityIndex(action.city)], sortedOrFiltererdResults: null };
      updatesStateToRest[getCityIndex(action.city)] = { ...updatesStateToRest[getCityIndex(action.city)], currentPage: 0 };
      return updatesStateToRest;
    case ACTION_TYPES.UPDATE_REFRESHDATA:
      let refreshStateToRest = [...state];
      refreshStateToRest[getCityIndex(action.city)] = { ...refreshStateToRest[getCityIndex(action.city)], refresh: action.payload };
      return refreshStateToRest;
    default:
      return state;
  }
};

export default ResultsReducer;
