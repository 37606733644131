import React, { useState, useEffect } from 'react';
import { format, differenceInMilliseconds } from 'date-fns';

const CountdownTimer = ({ futureDateTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(differenceInMilliseconds(futureDateTime, new Date()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeRemaining = differenceInMilliseconds(futureDateTime, new Date());
      setTimeRemaining(newTimeRemaining);

      if (newTimeRemaining <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [futureDateTime]);

  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

  return (
    <div>
      {!isNaN(hours) && !isNaN(minutes) && !isNaN(seconds) && hours >= 0 && (
        <div style={{ fontSize: 20 }}>
          <span style={{ fontSize: 20 }}>{String(hours).padStart(2, '0')}</span>:
          <span style={{ fontSize: 20 }}>{String(minutes).padStart(2, '0')}</span>:
          <span style={{ fontSize: 20 }}>{String(seconds).padStart(2, '0')}</span>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
