import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './deal.reducer';

export const DealDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const dealEntity = useAppSelector(state => state.deal.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="dealDetailsHeading">
          <Translate contentKey="activitiesApp.deal.detail.title">Deal</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{dealEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="activitiesApp.deal.name">Name</Translate>
            </span>
          </dt>
          <dd>{dealEntity.name}</dd>
          <dt>
            <span id="startDateTime">
              <Translate contentKey="activitiesApp.deal.startDateTime">Start Date Time</Translate>
            </span>
          </dt>
          <dd>{dealEntity.startDateTime ? <TextFormat value={dealEntity.startDateTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="endDateTime">
              <Translate contentKey="activitiesApp.deal.endDateTime">End Date Time</Translate>
            </span>
          </dt>
          <dd>{dealEntity.endDateTime ? <TextFormat value={dealEntity.endDateTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="activityType">
              <Translate contentKey="activitiesApp.deal.activityType">Activity Type</Translate>
            </span>
          </dt>
          <dd>{dealEntity.activityType}</dd>
          <dt>
            <span id="dealCategory">
              <Translate contentKey="activitiesApp.deal.dealCategory">Deal Category</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealCategory}</dd>
          <dt>
            <span id="dealImageOne">
              <Translate contentKey="activitiesApp.deal.dealImageOne">Deal Image One</Translate>
            </span>
          </dt>
          <dd>
            {dealEntity.dealImageOne ? (
              <div>
                {dealEntity.dealImageOneContentType ? (
                  <a onClick={openFile(dealEntity.dealImageOneContentType, dealEntity.dealImageOne)}>
                    <img
                      src={`data:${dealEntity.dealImageOneContentType};base64,${dealEntity.dealImageOne}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {dealEntity.dealImageOneContentType}, {byteSize(dealEntity.dealImageOne)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="dealImageTwo">
              <Translate contentKey="activitiesApp.deal.dealImageTwo">Deal Image Two</Translate>
            </span>
          </dt>
          <dd>
            {dealEntity.dealImageTwo ? (
              <div>
                {dealEntity.dealImageTwoContentType ? (
                  <a onClick={openFile(dealEntity.dealImageTwoContentType, dealEntity.dealImageTwo)}>
                    <img
                      src={`data:${dealEntity.dealImageTwoContentType};base64,${dealEntity.dealImageTwo}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {dealEntity.dealImageTwoContentType}, {byteSize(dealEntity.dealImageTwo)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="dealImageThree">
              <Translate contentKey="activitiesApp.deal.dealImageThree">Deal Image Three</Translate>
            </span>
          </dt>
          <dd>
            {dealEntity.dealImageThree ? (
              <div>
                {dealEntity.dealImageThreeContentType ? (
                  <a onClick={openFile(dealEntity.dealImageThreeContentType, dealEntity.dealImageThree)}>
                    <img
                      src={`data:${dealEntity.dealImageThreeContentType};base64,${dealEntity.dealImageThree}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {dealEntity.dealImageThreeContentType}, {byteSize(dealEntity.dealImageThree)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="dealImageOneUrl">
              <Translate contentKey="activitiesApp.deal.dealImageOneUrl">Deal Image One Url</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealImageOneUrl}</dd>
          <dt>
            <span id="dealImageTwoUrl">
              <Translate contentKey="activitiesApp.deal.dealImageTwoUrl">Deal Image Two Url</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealImageTwoUrl}</dd>
          <dt>
            <span id="dealImageThreeUrl">
              <Translate contentKey="activitiesApp.deal.dealImageThreeUrl">Deal Image Three Url</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealImageThreeUrl}</dd>
          <dt>
            <span id="dealSummary">
              <Translate contentKey="activitiesApp.deal.dealSummary">Deal Summary</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealSummary}</dd>
          <dt>
            <span id="dealDescription">
              <Translate contentKey="activitiesApp.deal.dealDescription">Deal Description</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealDescription}</dd>
          <dt>
            <span id="dealUrl">
              <Translate contentKey="activitiesApp.deal.dealUrl">Deal Url</Translate>
            </span>
          </dt>
          <dd>{dealEntity.dealUrl}</dd>
          <dt>
            <Translate contentKey="activitiesApp.deal.user">User</Translate>
          </dt>
          <dd>{dealEntity.user ? dealEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.deal.activity">Activity</Translate>
          </dt>
          <dd>{dealEntity.activity ? dealEntity.activity.name : ''}</dd>
          <dt>
            <Translate contentKey="activitiesApp.deal.city">City</Translate>
          </dt>
          <dd>{dealEntity.city ? dealEntity.city.cityName : ''}</dd>
        </dl>
        <Button tag={Link} to="/deal" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/deal/${dealEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DealDetail;
