import { useAppDispatch } from 'app/config/store';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getFilterByData,
  getOrderByData,
  getOrderedResultsData,
  getResultsData,
  getShowMapData,
  getCurrentPageData,
  getDealsData,
  getReplaceOrderedResultsData,
  getResetData,
  getRefreshDataState,
} from './../results/resultsactions';

const DealTop = () => {
  const dispatch = useAppDispatch();

  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);

  const dealsApiUrl = `/api/home/activity-deals?cityName=${selectedCity}`;

  const resultsReducer = useSelector((state: any) => state.resultsReducer);

  const getCityIndex = (cityParam: string): number => {
    switch (cityParam) {
      case 'Bali':
        return 0;
      case 'Lombok':
        return 1;
      case 'Nusa Lembongan':
        return 2;
      default:
        return 0;
    }
  };

  const fetchDeals = async () => {
    if (
      selectedCity != null &&
      selectedCity !== '' &&
      (!resultsReducer[getCityIndex(selectedCity)] ||
        !resultsReducer[getCityIndex(selectedCity)]?.deals ||
        !resultsReducer[getCityIndex(selectedCity)]?.deals?.length ||
        resultsReducer[getCityIndex(selectedCity)]?.deals?.length == 0)
    ) {
      try {
        const response = await fetch(dealsApiUrl);
        const data = await response.json();
        dispatch(getDealsData(data, selectedCity));
      } catch (error) {
        console.log('Error fetching activities:', error);
      }
    }
  };

  useEffect(() => {
    fetchDeals();
  }, [selectedCity]);

  return (
    <>
      <div className="tw-self-center tw-w-full tw-max-w-[1279px] tw-mt-14 tw-px-5 tw-max-md:max-w-full tw-max-md:mt-10">
        <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
          {resultsReducer[getCityIndex(selectedCity)]?.deals &&
            resultsReducer[getCityIndex(selectedCity)]?.deals.slice(0, 3).map((deal, index) => {
              return (
                <div key={index} className="tw-flex tw-flex-col tw-items-stretch tw-w-[33%] tw-max-md:w-full tw-max-md:ml-0">
                  <div className="tw-flex-col tw-overflow-hidden tw-relative tw-flex tw-aspect-[0.9196428571428571] tw-grow tw-pl-7 tw-pr-16 tw-pt-12 tw-pb-7 tw-items-start tw-max-md:mt-5 tw-max-md:px-5">
                    <img
                      loading="lazy"
                      srcSet={deal.dealImageOneUrl}
                      className="tw-absolute tw-h-full tw-w-full tw-object-cover tw-object-center tw-inset-0"
                    />
                    <div className="tw-relative tw-items-stretch tw-flex tw-gap-4 tw-mt-80 tw-max-md:mt-10">
                      <div className="tw-items-center tw-flex tw-aspect-square tw-flex-col tw-justify-center">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/313aa10a-1651-42ec-b1f0-c42964e0d22d?apiKey=782db1e49b4d4af9acf49bc384033667&"
                          className="tw-aspect-square tw-object-contain tw-object-center tw-w-16 tw-overflow-hidden tw-rounded-[50%]"
                        />
                      </div>
                      <div className="tw-text-white tw-text-2xl tw-font-semibold tw-self-center tw-grow tw-whitespace-nowrap tw-my-auto">
                        {deal.dealCategory}
                      </div>
                    </div>
                  </div>
                  {/* <div className="tw-text-zinc-900 tw-text-2xl tw-font-medium">{deal.dealSummary}</div>
                                <div className="tw-text-neutral-700 tw-text-xl tw-mt-1">{deal.dealDescription}</div> */}
                </div>
              );
            })}
          ;
        </div>
      </div>

      <div className="tw-self-center tw-w-full tw-max-w-[1279px] tw-mt-6 tw-px-5 tw-max-md:max-w-full">
        <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
          {resultsReducer[getCityIndex(selectedCity)]?.deals &&
            resultsReducer[getCityIndex(selectedCity)]?.deals.slice(0, 3).map((deal, index) => {
              return (
                <div key={index} className="tw-flex tw-flex-col tw-items-stretch tw-w-[33%] tw-max-md:w-full tw-max-md:ml-0">
                  <div className="tw-items-stretch tw-flex tw-flex-col tw-max-md:mt-5">
                    <div className="tw-text-zinc-900 tw-text-2xl tw-font-medium">{deal.dealSummary}</div>
                    <div className="tw-text-neutral-700 tw-text-xl tw-mt-1">{deal.dealDescription}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default DealTop;
