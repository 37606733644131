const initialState = {
  keyword: '',
};

export const ACTION_TYPES = {
  SET_KEYWORD: 'SEARCH/SET_KEYWORD',
};

export const getKeywordAction = (keyword: string): any => ({
  type: ACTION_TYPES.SET_KEYWORD,
  payload: keyword,
  error: null,
});

const KeywordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_KEYWORD:
      console.log('SETTING KW ' + action.payload);
      return {
        ...state,
        keyword: action.payload,
      };
    default:
      return state;
  }
};

export default KeywordReducer;
