import React from 'react';

interface Props {
  reviewMap: Map<number, number>;
  total?: number;
}

const RatingChart = ({ reviewMap, total }: Props) => {
  return (
    <div className="tw-w-full tw-space-y-1">
      <div className="tw-flex tw-w-full tw-items-center tw-space-x-2">
        <span className="tw-text-[12px] tw-text-gray-500 tw-inline">5&nbsp;starts</span>
        <div className="tw-bg-gray-200 tw-w-[100%] tw-h-2">
          <div className="tw-h-2 tw-bg-sky-500" style={{ width: `${(reviewMap[5] * 100) / total}%` }} />
        </div>
        <span className="tw-text-[12px] tw-text-gray-500 tw-w-12 tw-flex">{reviewMap[5]}</span>
      </div>

      <div className="tw-flex tw-items-center tw-space-x-2">
        <span className="tw-text-[12px] tw-text-gray-500 tw-inline">4&nbsp;starts</span>
        <div className="tw-bg-gray-200 tw-w-[100%] tw-h-2">
          <div className="tw-h-2 tw-bg-sky-500" style={{ width: `${(reviewMap[4] * 100) / total}%` }} />
        </div>
        <span className="tw-text-[12px] tw-text-gray-500 tw-w-12 tw-flex">{reviewMap[4]}</span>
      </div>

      <div className="tw-flex tw-items-center tw-space-x-2">
        <span className="tw-text-[12px] tw-text-gray-500 tw-inline">3&nbsp;starts</span>
        <div className="tw-bg-gray-200 tw-w-[100%] tw-h-2">
          <div className="tw-h-2 tw-bg-sky-500" style={{ width: `${(reviewMap[3] * 100) / total}%` }} />
        </div>
        <span className="tw-text-[12px] tw-text-gray-500 tw-w-12 tw-flex">{reviewMap[4]}</span>
      </div>

      <div className="tw-flex tw-items-center tw-space-x-2">
        <span className="tw-text-[12px] tw-text-gray-500 tw-inline">2&nbsp;starts</span>
        <div className="tw-bg-gray-200 tw-w-[100%] tw-h-2">
          <div className="tw-h-2 tw-bg-sky-500" style={{ width: `${(reviewMap[2] * 100) / total}%` }} />
        </div>
        <span className="tw-text-[12px] tw-text-gray-500 tw-w-12 tw-flex">{reviewMap[2]}</span>
      </div>

      <div className="tw-flex tw-items-center tw-space-x-2">
        <span className="tw-text-[12px] tw-text-gray-500 tw-inline">1&nbsp;starts</span>
        <div className="tw-bg-gray-200 tw-w-[100%] tw-h-2">
          <div className="tw-h-2 tw-bg-sky-500" style={{ width: `${(reviewMap[1] * 100) / total}%` }} />
        </div>
        <span className="tw-text-[12px] tw-text-gray-500 tw-w-12 tw-flex">{reviewMap[1]}</span>
      </div>
    </div>
  );
};

export default RatingChart;
