import { useState } from 'react';
import { Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DivWithTitle from '../divwithtitle';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Button } from 'app/shared/components/ui/button';
import {
  setPaxData,
  removePassenger,
  updatePaxDataElement,
  updateSelectedDate,
  updateSelectedTime,
  setActivityData,
  setActivityTimeData,
} from './../customeractions';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Props {
  onClose: () => void;
  show: boolean;
}

const BookingModal = ({ onClose, show }: Props) => {
  const activitySelector = useSelector((state: any) => state.customerReducer.activity);
  const paxDataSelector = useSelector((state: any) => state.customerReducer.paxData);
  const selectedDateSelector = useSelector((state: any) => state.customerReducer.selectedDate);
  const selectedTimeSelector = useSelector((state: any) => state.customerReducer.selectedTime);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [counter, setCounter] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const getFormattedISODate = isoDate => {
    const dateParts = isoDate.split('T')[0].split('-');

    // Extract date components
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    // Format the date in "yyyy-MM-dd" format
    return `${year}-${month}-${day}`;
  };

  const handleTimeSelect = (event, availTime) => {
    event.preventDefault();
    dispatch(updateSelectedTime(availTime));
  };

  const getTotalPaxCount = () => {
    let totalPaxCount = getPaxCountForThisBooking();
    let bookedSoFar = getBookedSoFar();
    return totalPaxCount + (bookedSoFar ? bookedSoFar : 0);
  };

  const getBookedSoFar = () => {
    let bookedSoFar = 0;
    if (selectedDateSelector) {
      bookedSoFar = selectedDateSelector?.bookedAdultCount;
    }
    if (selectedTimeSelector) {
      bookedSoFar = selectedTimeSelector?.bookedAdultCountForTime;
    }
    return bookedSoFar;
  };

  const getPaxCountForThisBooking = () => {
    let totalPaxCount = 0;
    paxDataSelector.map(p => {
      totalPaxCount = totalPaxCount + p.paxCount;
    });
    return totalPaxCount;
  };

  const onAddButtonClick = (event, price, index) => {
    event.preventDefault();
    const spinnerValue = paxDataSelector[index].paxCount + 1; //parseInt(event.target.value);
    dispatch(updatePaxDataElement(index, spinnerValue));
  };

  const onRemoveButtonClick = (event, price, index) => {
    event.preventDefault();
    const spinnerValue = paxDataSelector[index]?.paxCount - 1; //parseInt(event.target.value);
    dispatch(updatePaxDataElement(index, spinnerValue));
  };

  const updateModal = () => {
    let paxTotalPrice = 0;
    let totalPaxCount = 0;
    paxDataSelector.map(data => {
      paxTotalPrice = paxTotalPrice + data.paxCount * data.perPaxPrice;
      totalPaxCount = totalPaxCount + data.paxCount;
    });
    setCounter(totalPaxCount);
    setTotalPrice(paxTotalPrice);
  };

  const createBooking = async event => {
    event.preventDefault();
    try {
      var booking = {
        availabilityDateData: selectedDateSelector,
        activityId: activitySelector.activity.id,
        totalPrice: totalPrice,
        availabilityType: selectedTimeSelector?.availabilityType,
        activityAvailabilityId: selectedTimeSelector?.activityAvailabilityId ? selectedTimeSelector?.activityAvailabilityId : -1,
        activityTimeAvailabilityId: selectedTimeSelector?.activityTimeAvailabilityId
          ? selectedTimeSelector?.activityTimeAvailabilityId
          : -1,
        weekDayTimeId: selectedTimeSelector?.weekDayTimeId ? selectedTimeSelector?.weekDayTimeId : -1,
        twentyFourHourTime: selectedTimeSelector?.twentyFourHourTime,
        bookingPaxes: [],
      };

      for (let index = 0; index < getPaxCountForThisBooking() - 1; index++) {
        const passenger = {
          firstName: '',
          lastName: '',
        };
        booking.bookingPaxes.push(passenger);
      }

      await axios
        .post('/api/home/saveBooking', booking)
        .then(response => {
          const responseBody = response.data;
          navigate(`/booking/${responseBody.id}`);
          onClose();
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });

      // Handle the response or any additional logic
      console.log('Booking Saved');
    } catch (error) {
      console.log('Booking Saved error ' + error);
    }
  };

  useEffect(() => {
    updateModal();
  }, [paxDataSelector]);

  return (
    <Modal isOpen={show} toggle={onClose} backdrop="static" id="login-page" autoFocus={false}>
      <Form>
        <ModalHeader
          id="login-title"
          data-cy="loginTitle"
          toggle={onClose}
          //className="tw-grid tw-grid-cols-1 tw-bg-sky-500 tw-text-white tw-rounded-full"
          style={{
            backgroundColor: '#0ea5e9',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            padding: '20px',
            fontWeight: 'bolder',
          }}
        >
          <div className="bg-sky-500"> Enter Pax Details - {selectedDateSelector?.formattedDate} </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              {selectedDateSelector?.activityTimeAvailabilityDTOS && selectedDateSelector?.activityTimeAvailabilityDTOS.length > 0 && (
                <DivWithTitle title={'Select A Time Slot'}>
                  {selectedDateSelector?.activityTimeAvailabilityDTOS?.map((availTime, index) => (
                    <div key={availTime.activityTimeAvailabilityId}>
                      <div className="button-container">
                        <button
                          key={availTime.activityTimeAvailabilityId} // Replace "id" with a unique identifier for each item
                          onClick={event => handleTimeSelect(event, availTime)}
                          style={{
                            backgroundColor:
                              selectedTimeSelector?.activityTimeAvailabilityId === availTime?.activityTimeAvailabilityId
                                ? '#022b4b'
                                : 'white',
                            color:
                              selectedTimeSelector?.activityTimeAvailabilityId === availTime?.activityTimeAvailabilityId
                                ? 'white'
                                : 'black',
                          }}
                          className="timeSlot"
                        >
                          {availTime
                            ? `${new Date(availTime?.twentyFourHourTime).toTimeString().slice(0, 5)} Spaces left for this time : ${
                                availTime?.adultCount
                              }`
                            : 'No time slot available'}
                        </button>
                      </div>
                    </div>
                  ))}
                </DivWithTitle>
              )}
            </Col>
          </Row>
          {(selectedDateSelector?.activityTimeAvailabilityDTOS?.length == 0 || selectedTimeSelector != null) && (
            <Row>
              <Col>
                <DivWithTitle title={'Select Pax Count'}>
                  {selectedDateSelector?.activityPrices?.map((activityPrice, index) => {
                    return (
                      <Row key={index}>
                        {!(getBookedSoFar() >= activityPrice.toPax) && (
                          <>
                            <Col md="3" className="centered-div">
                              <span style={{ fontSize: '18px' }}>
                                @ ${activityPrice?.adultPrice}
                                {/* - [{activityPrice.fromPax}-{activityPrice.toPax}] */}
                              </span>
                            </Col>

                            <Col md="3" className="centered-div">
                              <Button
                                onClick={event => onRemoveButtonClick(event, activityPrice, index)}
                                disabled={
                                  paxDataSelector[index].paxCount == 0 ||
                                  getBookedSoFar() >= activityPrice.toPax ||
                                  getTotalPaxCount() === 0 ||
                                  getTotalPaxCount() <= activityPrice.fromPax - 1 ||
                                  getTotalPaxCount() > activityPrice.toPax
                                }
                              >
                                -
                              </Button>
                            </Col>
                            <Col md="3" className="centered-div">
                              <span style={{ fontSize: '18px' }}>{paxDataSelector[index].paxCount}</span>
                            </Col>
                            <Col md="3" className="centered-div">
                              <Button
                                onClick={event => onAddButtonClick(event, activityPrice, index)}
                                disabled={
                                  getBookedSoFar() >= activityPrice.toPax ||
                                  getTotalPaxCount() === activityPrice.toPax ||
                                  getTotalPaxCount() < activityPrice.fromPax - 1 ||
                                  getTotalPaxCount() > activityPrice.toPax
                                }
                              >
                                +
                              </Button>
                            </Col>
                          </>
                        )}
                      </Row>
                    );
                  })}
                </DivWithTitle>
              </Col>
            </Row>
          )}
          <Row
            style={{
              backgroundColor: '#1262c0',
              borderTopLeftRadius: '50px',
              borderBottomLeftRadius: '50px',
              borderTopRightRadius: '50px',
              borderBottomRightRadius: '50px',
              padding: '5px',
            }}
          >
            <Col>
              <span style={{ color: 'white', fontSize: '18px' }}>Pax Count: {getPaxCountForThisBooking()}</span>
            </Col>
            <Col style={{ textAlign: 'right', fontSize: '18px' }}>
              <span style={{ color: 'white', textAlign: 'right', fontSize: '18px' }}>Total :$ {totalPrice}</span>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="lg"
            className="tw-px-4 tw-py-1"
            onClick={event => {
              event.preventDefault();
              onClose();
            }}
          >
            Cancel
          </Button>
          {/* <Link className="tw-px-4 tw-py-1" onClick={onClose} to="">
              Cancel
            </Link>{' '}  */}
          <Button size="lg" className="tw-px-4 tw-py-1" disabled={counter == 0} onClick={createBooking}>
            Book Now
            {/* <Link
              className={counter == 0 ? 'text-gray-500 cursor-not-allowed' : 'tw-px-4 tw-py-1'}
              to={{
                pathname: `/paxdetails`
              }}
            >
              Book Now
            </Link> */}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default BookingModal;
