import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { IActivityReview } from 'app/shared/model/activity-review.model';
import { getEntity, updateEntity, createEntity, reset } from './activity-review.reducer';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';
import { getEntities as getActivitiesFirstPage } from 'app/entities/activity/activity.reducer';

export const ActivityReviewUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activities = useAppSelector(state => state.activity.entities);
  const activityReviewEntity = useAppSelector(state => state.activityReview.entity);
  const loading = useAppSelector(state => state.activityReview.loading);
  const updating = useAppSelector(state => state.activityReview.updating);
  const updateSuccess = useAppSelector(state => state.activityReview.updateSuccess);

  const [inputValue, setInputValue] = useState('');

  const handleClose = () => {
    navigate('/activity-review' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getActivitiesFirstPage({}));
    } else {
      const actImage = getEntity(id);
      dispatch(actImage);
      dispatch(getActivityList(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...activityReviewEntity,
      ...values,
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...activityReviewEntity,
          activity: activityReviewEntity?.activity?.id,
        };

  const getActivities = createAsyncThunk('activity/fetch_entity_list', async (kw: string) => {
    if (kw.length < 4) return [];
    const requestUrl = `/api/home/activity-byname?name=${kw}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const getActivityList = createAsyncThunk('activity/fetch_entity_list', async (id: number | string) => {
    const requestUrl = `/api/home/activity-by-review-id-as-array?activityReviewId=${id}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const handleInputChange = event => {
    setInputValue(event?.target?.value);
    if (event?.target?.value?.length < 4) return;
    dispatch(getActivities(event.target.value));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityReview.home.createOrEditLabel" data-cy="ActivityReviewCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityReview.home.createOrEditLabel">Create or edit a ActivityReview</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-review-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityReview.reviewDate')}
                id="activity-review-reviewDate"
                name="reviewDate"
                data-cy="reviewDate"
                type="date"
              />
              <ValidatedField
                label={translate('activitiesApp.activityReview.noOfStars')}
                id="activity-review-noOfStars"
                name="noOfStars"
                data-cy="noOfStars"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityReview.reviewdUser')}
                id="activity-review-reviewdUser"
                name="reviewdUser"
                data-cy="reviewdUser"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityReview.commnet')}
                id="activity-review-commnet"
                name="commnet"
                data-cy="commnet"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityReview.approved')}
                id="activity-review-approved"
                name="approved"
                data-cy="approved"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('activitiesApp.activityReview.bookingId')}
                id="activity-review-bookingId"
                name="bookingId"
                data-cy="bookingId"
                type="text"
              />
              <ValidatedField
                type="text"
                onChange={handleInputChange}
                placeholder="Type to search..."
                id="activity-filter"
                data-cy="activity"
                value={inputValue}
                name="activity-filter"
                label="Type minimum 4 letters to filter activities"
              ></ValidatedField>
              <ValidatedField
                id="activity-review-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityReview.activity')}
                type="select"
                size={10}
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('activitiesApp.activityReview.recommend')}
                id="activity-review-recommend"
                name="recommend"
                data-cy="recommend"
                check
                type="checkbox"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-review" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityReviewUpdate;
