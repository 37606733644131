import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';
import { IActivityHighlight } from 'app/shared/model/activity-highlight.model';
import { IActivityIncludedItem } from 'app/shared/model/activity-included-item.model';
import { IActivityNote } from 'app/shared/model/activity-note.model';
import { IActivityPrice } from 'app/shared/model/activity-price.model';
import { IActivityImage } from 'app/shared/model/activity-image.model';
import { IActivityAvailability } from 'app/shared/model/activity-availability.model';
import { IActivityReview } from 'app/shared/model/activity-review.model';
import { IUser } from 'app/shared/model/user.model';
import { IDeal } from 'app/shared/model/deal.model';
import { ActivityType } from 'app/shared/model/enumerations/activity-type.model';
import { IItinerary } from 'app/shared/model/itinerary.model';
import { IActivityFaq } from 'app/shared/model/activity-faq.model';
import { IKeyword } from 'app/shared/model/keyword.model';
import { IDiningDetails } from 'app/shared/model/dining-details.model';
import { IBookingUser } from 'app/shared/model/booking-user.model';
import { IApplicationUser } from 'app/shared/model/application-user.model';

export interface IActivity {
  id?: number;
  name?: string | null;
  summaryDescription?: string | null;
  detailedDescription?: string | null;
  quote?: string | null;
  durationNote?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  childFriendly?: boolean | null;
  childFriendlyNote?: string | null;
  activityType?: ActivityType | null;
  createdDate?: string | null;
  modifiedDate?: string | null;
  active?: boolean | null;
  whatsIncluded?: string | null;
  whatToBring?: string | null;
  departs?: string | null;
  restrictions?: string | null;
  freeCancellation?: boolean | null;
  cancellationNote?: string | null;
  userName?: string | null;
  location?: ILocation | null;
  diningDetails?: IDiningDetails | null;
  activityHighlights?: IActivityHighlight[] | null;
  activityIncludedItems?: IActivityIncludedItem[] | null;
  activityNotes?: IActivityNote[] | null;
  activityPrices?: IActivityPrice[] | null;
  activityImages?: IActivityImage[] | null;
  activityAvailabilities?: IActivityAvailability[] | null;
  activityReviews?: IActivityReview[] | null;
  user?: IUser | null;
  deals?: IDeal[] | null;
  itineraries?: IItinerary[] | null;
  activityFaqs?: IActivityFaq[] | null;
  keywords?: IKeyword[] | null;
  bookingUsers?: IBookingUser[] | null;
  applicationUsers?: IApplicationUser[] | null;
}

export const defaultValue: Readonly<IActivity> = {
  childFriendly: false,
  active: false,
  freeCancellation: false,
};
