import React from 'react';
import { StarFilledIcon, StarIcon } from '@radix-ui/react-icons';
import { cn } from 'app/shared/util/tailwind';

interface Props {
  rating: number;
  className?: string;
}

const Rating = ({ rating, className }: Props) => {
  return (
    <div className="tw-flex tw-items-center">
      {[0, 1, 2, 3, 4].map(index => (
        <React.Fragment key={index}>
          {rating > index ? (
            <StarFilledIcon key={index} className={cn('tw-h-5 tw-w-5 tw-flex-shrink-0', className)} />
          ) : (
            <StarIcon key={index} className={cn('tw-h-5 tw-w-5 tw-flex-shrink-0', className)} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Rating;
