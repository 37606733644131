import { ICity } from 'app/shared/model/city.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface ILocation {
  id?: number;
  locationName?: string | null;
  streetAddress?: string | null;
  postalCode?: string | null;
  cityName?: string | null;
  stateProvince?: string | null;
  locationNote?: string | null;
  locationPhone?: string | null;
  locationMobile?: string | null;
  locationEmail?: string | null;
  locationContactPerson?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  city?: ICity | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<ILocation> = {};
