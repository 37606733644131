const initialState = {
  selectedCity: 'Bali',
};

export const ACTION_TYPES = {
  SET_CITY: 'header/SET_CITY',
};

export const setSelectedCity = (city): any => ({
  type: ACTION_TYPES.SET_CITY,
  payload: city,
  error: null,
});

const HeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_CITY:
      return {
        ...state,
        selectedCity: action.payload,
      };
    default:
      return state;
  }
};

export default HeaderReducer;
