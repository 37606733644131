/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Rating from './rating';
import dayjs from 'dayjs';

interface Props {
  rating: number;
  comment: string;
  date: string;
  user: string;
}

const ReviewItem = ({ rating, comment, date, user }: Props) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-space-x-4 tw-items-center">
          <img
            src="https://www.mecgale.com/wp-content/uploads/2017/08/dummy-profile.png"
            alt="profile"
            className="tw-h-14 tw-object-cover tw-w-16"
          />

          <div className="tw-space-y-0.5">
            <span className="tw-text-sm tw-font-semibold">{user || 'Unknown User'}</span>
            <div className="tw-flex tw-space-x-2">
              <Rating rating={rating} className="tw-w-4 tw-h-4 tw-text-sky-500" />
              <span className="tw-text-xs tw-text-gray-500">{rating}/5 starts</span>
            </div>
          </div>
        </div>

        <div className="tw-text-xs tw-text-gray-500 tw-flex tw-flex-col">
          <span>{dayjs(date).format('MMMM D, YYYY')}</span>
        </div>
      </div>

      <div className="tw-mt-4">
        <span className="tw-text-gray-600">{comment}</span>
        {/* <p className="tw-mt-1 tw-text-sm tw-text-gray-500">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s,
        </p> */}
      </div>
    </div>
  );
};

export default ReviewItem;
