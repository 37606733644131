import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BookingPax from './booking-pax';
import BookingPaxDetail from './booking-pax-detail';
import BookingPaxUpdate from './booking-pax-update';
import BookingPaxDeleteDialog from './booking-pax-delete-dialog';

const BookingPaxRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BookingPax />} />
    <Route path="new" element={<BookingPaxUpdate />} />
    <Route path=":id">
      <Route index element={<BookingPaxDetail />} />
      <Route path="edit" element={<BookingPaxUpdate />} />
      <Route path="delete" element={<BookingPaxDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BookingPaxRoutes;
