import dayjs from 'dayjs';
import { IActivityTimeAvailability } from 'app/shared/model/activity-time-availability.model';
import { IBooking } from 'app/shared/model/booking.model';

export interface IBookingTime {
  id?: number;
  bookedTime?: string | null;
  activityTimeAvailability?: IActivityTimeAvailability | null;
  booking?: IBooking | null;
}

export const defaultValue: Readonly<IBookingTime> = {};
