import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivity } from 'app/shared/model/activity.model';
//import { getEntities as getActivities } from 'app/entities/activity/activity.reducer';
import { IItinerary } from 'app/shared/model/itinerary.model';
import { getEntity, updateEntity, createEntity, reset } from './itinerary.reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getEntities as getActivitiesFirstPage } from 'app/entities/activity/activity.reducer';

export const ItineraryUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activities = useAppSelector(state => state.activity.entities);
  const itineraryEntity = useAppSelector(state => state.itinerary.entity);
  const loading = useAppSelector(state => state.itinerary.loading);
  const updating = useAppSelector(state => state.itinerary.updating);
  const updateSuccess = useAppSelector(state => state.itinerary.updateSuccess);

  const [inputValue, setInputValue] = useState('');
  const getActivities = createAsyncThunk('activity/fetch_entity_list', async (kw: string) => {
    if (kw.length < 4) return [];
    const requestUrl = `/api/home/activity-byname?name=${kw}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const getActivityList = createAsyncThunk('activity/fetch_entity_list', async (id: number | string) => {
    const requestUrl = `/api/home/activity-by-itinerary-id-as-array?itineraryId=${id}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const handleInputChange = event => {
    setInputValue(event?.target?.value);
    if (event?.target?.value?.length < 4) return;
    dispatch(getActivities(event.target.value));
  };

  const handleClose = () => {
    navigate('/itinerary' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getActivitiesFirstPage({}));
    } else {
      dispatch(getEntity(id));
      dispatch(getActivityList(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...itineraryEntity,
      ...values,
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...itineraryEntity,
          activity: itineraryEntity?.activity?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.itinerary.home.createOrEditLabel" data-cy="ItineraryCreateUpdateHeading">
            <Translate contentKey="activitiesApp.itinerary.home.createOrEditLabel">Create or edit a Itinerary</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="itinerary-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.itinerary.itineraryName')}
                id="itinerary-itineraryName"
                name="itineraryName"
                data-cy="itineraryName"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.itinerary.itineraryText')}
                id="itinerary-itineraryText"
                name="itineraryText"
                data-cy="itineraryText"
                type="textarea"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
                size={10}
              />
              <ValidatedField
                label={translate('activitiesApp.itinerary.active')}
                id="itinerary-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.itinerary.itineraryImage')}
                id="itinerary-itineraryImage"
                name="itineraryImage"
                data-cy="itineraryImage"
                isImage
                accept="image/*"
              />
              <ValidatedField
                type="text"
                onChange={handleInputChange}
                placeholder="Type to search..."
                id="activity-filter"
                data-cy="activity"
                value={inputValue}
                name="activity-filter"
                label="Type minimum 4 letters to filter activities"
                //label={ "Type to filter activities. "+ ( "Current attached : " +activityImageEntity?.activity ?  activityImageEntity?.activity?.name : "None" )}
              ></ValidatedField>
              <ValidatedField
                id="itinerary-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.itinerary.activity')}
                type="select"
                size={10}
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/itinerary" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ItineraryUpdate;
