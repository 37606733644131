export enum DealCategory {
  DEAL_OF_THE_DAY = 'DEAL_OF_THE_DAY',

  MOST_POPULAR = 'MOST_POPULAR',

  MOST_LIKED = 'MOST_LIKED',

  HOT_DEAL = 'HOT_DEAL',

  SAVE_HEAPS = 'SAVE_HEAPS',

  OUR_FAVOURITE = 'OUR_FAVOURITE',

  HIGHLY_RECOMMEND = 'HIGHLY_RECOMMEND',
}
