import { IWeekDayTime } from 'app/shared/model/week-day-time.model';
import { IActivityAvailability } from 'app/shared/model/activity-availability.model';

export interface IActivitySchedule {
  id?: number;
  scheduleName?: string | null;
  weekDayTimes?: IWeekDayTime[] | null;
  activityAvailabilities?: IActivityAvailability[] | null;
}

export const defaultValue: Readonly<IActivitySchedule> = {};
