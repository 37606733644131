import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BookingPament from './booking-pament';
import BookingPamentDetail from './booking-pament-detail';
import BookingPamentUpdate from './booking-pament-update';
import BookingPamentDeleteDialog from './booking-pament-delete-dialog';

const BookingPamentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BookingPament />} />
    <Route path="new" element={<BookingPamentUpdate />} />
    <Route path=":id">
      <Route index element={<BookingPamentDetail />} />
      <Route path="edit" element={<BookingPamentUpdate />} />
      <Route path="delete" element={<BookingPamentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BookingPamentRoutes;
