import { ICountry } from 'app/shared/model/country.model';
import { ILocation } from 'app/shared/model/location.model';
import { IDeal } from 'app/shared/model/deal.model';
import { IContentImages } from 'app/shared/model/content-images.model';

export interface ICity {
  id?: number;
  cityName?: string | null;
  country?: ICountry | null;
  locations?: ILocation[] | null;
  deals?: IDeal[] | null;
  contentImages?: IContentImages[] | null;
}

export const defaultValue: Readonly<ICity> = {};
