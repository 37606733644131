export enum ActivityType {
  ADVENTURE = 'ADVENTURE',

  NIGHTTIME = 'NIGHTTIME',

  WELLNESS = 'WELLNESS',

  KIDSPLAY = 'KIDSPLAY',

  TOURINGBALI = 'TOURINGBALI',

  GETWET = 'GETWET',

  ADRENALIN = 'ADRENALIN',

  TRANSFERS = 'TRANSFERS',

  CULTURALSITES = 'CULTURALSITES',

  DINING = 'DINING',

  AQUATIC = 'AQUATIC',
}
