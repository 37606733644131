import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityFaq from './activity-faq';
import ActivityFaqDetail from './activity-faq-detail';
import ActivityFaqUpdate from './activity-faq-update';
import ActivityFaqDeleteDialog from './activity-faq-delete-dialog';

const ActivityFaqRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityFaq />} />
    <Route path="new" element={<ActivityFaqUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityFaqDetail />} />
      <Route path="edit" element={<ActivityFaqUpdate />} />
      <Route path="delete" element={<ActivityFaqDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityFaqRoutes;
