import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import './suggestdropdown.scss';
import { ValidatedField } from 'react-jhipster';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IActivity } from 'app/shared/model/activity.model';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';

export const fetchActivities = createAsyncThunk('activity/fetch_entity_list', async (kw:string) => {
    if( kw.length < 4 ) return [];
    const requestUrl = `/api/home/activity-summary-by-kw?keyword=${kw}`;
    return axios.get<IActivity[]>(requestUrl);
  });

 export  const fetchActivityList = createAsyncThunk('activity/fetch_entity_list', async ({id, objectTypeId}:any) => {
    const requestUrl = `/api/home/activity-summary-by-object-id-as-array?childType=${objectTypeId}&activityChildId=${id}`;
    return axios.get<IActivity[]>(requestUrl);
  });

const ActivitySuggest = ({id, name, paramOnChange, childType}) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange =(event) =>{
        setInputValue(event?.target?.value);
        if( event?.target?.value?.length < 4 ) return;
        paramOnChange(event?.target?.value);
    }

    return (
            <ValidatedField
            type="text"
            onChange={handleInputChange}
            placeholder="Type to search..."
            id="activity-filter"
            data-cy="activity"
            value={inputValue}
            name="activity-filter"
            label="Type minimum 4 letters to filter activities">            
            </ValidatedField>
        )
}

export default ActivitySuggest;