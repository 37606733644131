import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import BookingTime from './booking-time';
import BookingTimeDetail from './booking-time-detail';
import BookingTimeUpdate from './booking-time-update';
import BookingTimeDeleteDialog from './booking-time-delete-dialog';

const BookingTimeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<BookingTime />} />
    <Route path="new" element={<BookingTimeUpdate />} />
    <Route path=":id">
      <Route index element={<BookingTimeDetail />} />
      <Route path="edit" element={<BookingTimeUpdate />} />
      <Route path="delete" element={<BookingTimeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default BookingTimeRoutes;
