import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './booking-pament.reducer';

export const BookingPamentDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const bookingPamentEntity = useAppSelector(state => state.bookingPament.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="bookingPamentDetailsHeading">
          <Translate contentKey="activitiesApp.bookingPament.detail.title">BookingPament</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{bookingPamentEntity.id}</dd>
          <dt>
            <span id="totalPaid">
              <Translate contentKey="activitiesApp.bookingPament.totalPaid">Total Paid</Translate>
            </span>
          </dt>
          <dd>{bookingPamentEntity.totalPaid}</dd>
          <dt>
            <span id="totalDiscount">
              <Translate contentKey="activitiesApp.bookingPament.totalDiscount">Total Discount</Translate>
            </span>
          </dt>
          <dd>{bookingPamentEntity.totalDiscount}</dd>
          <dt>
            <span id="paymentDate">
              <Translate contentKey="activitiesApp.bookingPament.paymentDate">Payment Date</Translate>
            </span>
          </dt>
          <dd>
            {bookingPamentEntity.paymentDate ? (
              <TextFormat value={bookingPamentEntity.paymentDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="paymentMethod">
              <Translate contentKey="activitiesApp.bookingPament.paymentMethod">Payment Method</Translate>
            </span>
          </dt>
          <dd>{bookingPamentEntity.paymentMethod}</dd>
          <dt>
            <span id="userEmail">
              <Translate contentKey="activitiesApp.bookingPament.userEmail">User Email</Translate>
            </span>
          </dt>
          <dd>{bookingPamentEntity.userEmail}</dd>
          <dt>
            <Translate contentKey="activitiesApp.bookingPament.booking">Booking</Translate>
          </dt>
          <dd>{bookingPamentEntity.booking ? bookingPamentEntity.booking.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/booking-pament" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/booking-pament/${bookingPamentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BookingPamentDetail;
