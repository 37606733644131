import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Itinerary from './itinerary';
import ItineraryDetail from './itinerary-detail';
import ItineraryUpdate from './itinerary-update';
import ItineraryDeleteDialog from './itinerary-delete-dialog';

const ItineraryRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Itinerary />} />
    <Route path="new" element={<ItineraryUpdate />} />
    <Route path=":id">
      <Route index element={<ItineraryDetail />} />
      <Route path="edit" element={<ItineraryUpdate />} />
      <Route path="delete" element={<ItineraryDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ItineraryRoutes;
