import './landing.scss';
import { Button } from 'app/shared/components/ui/button';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Row, Col, Alert } from 'reactstrap';

import { useAppSelector } from 'app/config/store';
import Navbar from './navbar';
import SearchPane from './searchpane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DealMap from './dealmap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { constant } from 'lodash';
import City from 'app/entities/city/city';
import { setActivitiesData, setParametersData } from './landingactions';
import { useDispatch, useSelector } from 'react-redux';
import ResultsMap from './results/resultsmap';
import ResultsBody from './results/restulsbody';
import { setSelectedCity } from '../../shared/layout/header/headerreducer';
import { getOrderedResultsData, getResultsData } from './results/resultsactions';
import Results from './results/restuls';

export const Landing = () => {
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);
  const resultsReducer = useSelector((state: any) => state.resultsReducer);
  const { clientTypeInt = '0' } = useParams();
  const { keywordParam } = useParams();
  const { cityParam } = useParams();
  const dispatch = useDispatch();

  const account = useAppSelector(state => state.authentication.account);
  //const [activities, setActivities] = useState([]);
  const activities = useSelector((state: any) => state.landingReducer.activities);
  const parameters = useSelector((state: any) => state.landingReducer.parameters);

  const [clickType, setClickType] = useState(0);
  const [city, setCity] = useState(cityParam);
  const [keyword, setKeyword] = useState(keywordParam);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const activityTypes = [
    'ADVENTURE',
    'NIGHTTIME',
    'WELLNESS',
    'KIDSPLAY',
    'TOURINGBALI',
    'GETWET',
    'ADRENALIN',
    'TRANSFERS',
    'CULTURALSITES',
  ];

  // useEffect(() => {
  //   if (parameters.clientType !== parseInt(clientTypeInt) || parameters.city !== cityParam || parameters.keyword !== keywordParam)
  //     fetchActivities();
  //   dispatch(setParametersData({ clientType: parseInt(clientTypeInt), city: cityParam, keyword: keywordParam }));
  // }, [clientTypeInt, cityParam, keywordParam]);

  // useEffect(() => {
  //   fetchActivities();
  // }, [ cityParam, keywordParam]);

  // const getCityIndex = (cityParam: string): number => {
  //   switch (cityParam) {
  //     case 'Bali':
  //       return 0;
  //     case 'Lombok':
  //       return 1;
  //     case 'Nusa Lembongan':
  //       return 2;
  //     default:
  //       return 0;
  //   }
  // };

  //useEffect(() => {}, []);

  const fetchData = async (clickType, city, keyword) => {
    const response = await fetch(`/api/home/activity-by-city-kw?cityName=${city}&keyword=${keyword}`);
    let data = [];
    data = await response.json();
    //setActivities(data);
    dispatch(setActivitiesData(data));
  };

  const timeout = (delay: number) => {
    return new Promise(res => setTimeout(res, delay));
  };

  // const fetchActivities = async () => {
  //   const paramInt = parseInt(clientTypeInt);
  //   try {
  //     let data = [];
  //     setLoading(true);
  //     if (paramInt >= 4 && paramInt <= 12) {
  //       dispatch(setSelectedCity('Bali'));
  //       const response = await fetch(
  //         `/api/home/get-sortable-pagable-list?page=0&size=20&cityName=Bali&activityType=${activityTypes[paramInt - 4]}&keyword=`
  //       );
  //       data = await response.json();
  //     }

  //     if (paramInt >= 13 && paramInt <= 21) {
  //       const response = await fetch(
  //         `/api/home/get-sortable-pagable-list?page=0&size=20&cityName=Lombok&activityType=${activityTypes[paramInt - 13]}&keyword=`
  //       );
  //       data = await response.json();
  //     }

  //     if (paramInt == 23) {
  //       if (cityParam != null || keywordParam != null) {
  //         if (keywordParam === 'EMPTY') {
  //           const response = await fetch(`/api/home/get-sortable-pagable-list?page=0&size=20&cityName=${cityParam}&activityType=&keyword=`);
  //           data = await response.json();
  //         } else {
  //           const response = await fetch(
  //             `/api/home/get-sortable-pagable-list?page=0&size=20&cityName=${cityParam}&activityType=&keyword=${keywordParam}`
  //           );
  //           data = await response.json();
  //         }
  //       }
  //     }

  //     dispatch(getResultsData({ ...data }, cityParam));
  //     dispatch(getOrderedResultsData({ ...data }, cityParam));
  //     //setActivities(data);
  //     //dispatch(setActivitiesData(data));
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //     console.log('Error fetching activities:', error);
  //   }
  // };

  // const onPopularityClick = () => {
  //   const sortedData = [...activities].sort((a, b) => b.bookingCount - a.bookingCount);
  //   //setActivities(sortedData);
  //   dispatch(setActivitiesData(sortedData));
  //   setClickType(1);
  // };

  // const onDiscountClick = () => {
  //   const sortedData = [...activities].sort((a, b) => b.discount - a.discount);
  //   //setActivities(sortedData);
  //   dispatch(setActivitiesData(sortedData));
  //   setClickType(2);
  // };

  // const onDealMapClick = () => {
  //   setClickType(3);
  //   //fetchActivities();
  // };

  // const onNavbarClick = activityType => {
  //   switch (activityType) {
  //     case 'ADVENTURE':
  //       setClickType(4);
  //       break;
  //     case 'NIGHTTIME':
  //       setClickType(5);
  //       break;
  //     case 'ADRENALIN':
  //       setClickType(6);
  //       break;
  //     case 'TRANSFERS':
  //       setClickType(7);
  //       break;
  //     case 'GETWET':
  //       setClickType(8);
  //       break;
  //   }
  //   fetchActivities();
  //   console.log(activityType);
  // };

  const getStarClassName = rating => {
    // change className of the span tag based on the rating value
    const roundedRating = Math.round(rating); // Round the rating to the nearest integer
    return `star-icon-${roundedRating}`;
  };

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
      return description;
    }
    return description.substring(0, maxLength) + '...';
  };

  return (
    <>
      <div className="">
        <br />
        <br />
        <br />
        {clickType != 3 && (
          <>
            {/* <ResultsBody 
            data={resultsReducer[getCityIndex(selectedCity)]?.sortedOrFiltererdResults?.content}
            key={'2'}
            loading={loading}
            ></ResultsBody> */}
            <Results></Results>
          </>
        )}
        {clickType == 3 && (
          <Row>
            <Col md="2"></Col>
            <Col md="8">
              <ResultsMap data={activities}></ResultsMap>
            </Col>
            <Col md="2"></Col>
          </Row>
        )}
        {loading && (
          <Row>
            <Col md="4"></Col>
            <Col md="4">
              <div className="modal-dialog-activities ">
                <div className="modal-content-dialog-activities boxShadow2">
                  <div className="spinner-border text-success" role="status">
                    <span className="sr-only text-center">
                      <span className="dark"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="4"></Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default Landing;
