import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm, ValidatedBlobField } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IActivity } from 'app/shared/model/activity.model';
import { getEntities as getActivitiesFirstPage } from 'app/entities/activity/activity.reducer';
import { IActivityFaq } from 'app/shared/model/activity-faq.model';
import { getEntity, updateEntity, createEntity, reset } from './activity-faq.reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const ActivityFaqUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const activities = useAppSelector(state => state.activity.entities);
  const activityFaqEntity = useAppSelector(state => state.activityFaq.entity);
  const loading = useAppSelector(state => state.activityFaq.loading);
  const updating = useAppSelector(state => state.activityFaq.updating);
  const updateSuccess = useAppSelector(state => state.activityFaq.updateSuccess);

  const handleClose = () => {
    navigate('/activity-faq' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getActivitiesFirstPage({}));
    } else {
      dispatch(getEntity(id));
      dispatch(getActivityList(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.modifiedDate = convertDateTimeToServer(values.modifiedDate);

    const entity = {
      ...activityFaqEntity,
      ...values,
      activity: activities.find(it => it.id.toString() === values.activity.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          modifiedDate: displayDefaultDateTime(),
        }
      : {
          ...activityFaqEntity,
          createdDate: convertDateTimeFromServer(activityFaqEntity.createdDate),
          modifiedDate: convertDateTimeFromServer(activityFaqEntity.modifiedDate),
          activity: activityFaqEntity?.activity?.id,
        };

  const [inputValue, setInputValue] = useState('');

  const getActivities = createAsyncThunk('activity/fetch_entity_list', async (kw: string) => {
    if (kw.length < 4) return [];
    const requestUrl = `/api/home/activity-byname?name=${kw}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const getActivityList = createAsyncThunk('activity/fetch_entity_list', async (id: number | string) => {
    const requestUrl = `/api/home/activity-by-faq-id-as-array?activityFaqId=${id}`;
    return axios.get<IActivity[]>(requestUrl);
  });

  const handleInputChange = event => {
    setInputValue(event?.target?.value);
    if (event?.target?.value?.length < 4) return;
    dispatch(getActivities(event.target.value));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="activitiesApp.activityFaq.home.createOrEditLabel" data-cy="ActivityFaqCreateUpdateHeading">
            <Translate contentKey="activitiesApp.activityFaq.home.createOrEditLabel">Create or edit a ActivityFaq</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="activity-faq-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('activitiesApp.activityFaq.question')}
                id="activity-faq-question"
                name="question"
                data-cy="question"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityFaq.asnwer')}
                id="activity-faq-asnwer"
                name="asnwer"
                data-cy="asnwer"
                type="textarea"
                rows="5"
              />
              <ValidatedField
                label={translate('activitiesApp.activityFaq.faqNote')}
                id="activity-faq-faqNote"
                name="faqNote"
                data-cy="faqNote"
                type="text"
              />
              <ValidatedField
                label={translate('activitiesApp.activityFaq.createdDate')}
                id="activity-faq-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('activitiesApp.activityFaq.modifiedDate')}
                id="activity-faq-modifiedDate"
                name="modifiedDate"
                data-cy="modifiedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedBlobField
                label={translate('activitiesApp.activityFaq.faqImage')}
                id="activity-faq-faqImage"
                name="faqImage"
                data-cy="faqImage"
                isImage
                accept="image/*"
              />
              <ValidatedField
                type="text"
                onChange={handleInputChange}
                placeholder="Type to search..."
                id="activity-filter"
                data-cy="activity"
                value={inputValue}
                name="activity-filter"
                label="Type minimum 4 letters to filter activities"
                //label={ "Type to filter activities. "+ ( "Current attached : " +activityImageEntity?.activity ?  activityImageEntity?.activity?.name : "None" )}
              ></ValidatedField>
              <ValidatedField
                id="activity-faq-activity"
                name="activity"
                data-cy="activity"
                label={translate('activitiesApp.activityFaq.activity')}
                type="select"
                size={10}
              >
                <option value="" key="0" />
                {activities
                  ? activities.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/activity-faq" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActivityFaqUpdate;
