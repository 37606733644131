import React from 'react';
import { useSelector } from 'react-redux';
//import { Button } from 'reactstrap';
import { Button } from 'app/shared/components/ui/button';

export const ShowMoreResults = props => {
  const resultsReducer = useSelector((state: any) => state.resultsReducer);
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-right more-results-button">
          <Button disabled={props.isDisabled} onClick={props.nextPageAction}>
            Load More Results for {selectedCity}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShowMoreResults;
