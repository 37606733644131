import React, { useEffect, useMemo, useState } from 'react';
import DealTop from './dealtop';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/config/store';
import { useParams } from 'react-router-dom';
import {
  getFilterByData,
  getOrderByData,
  getOrderedResultsData,
  getResultsData,
  getShowMapData,
  getCurrentPageData,
  getDealsData,
  getReplaceOrderedResultsData,
  getResetData,
  getRefreshDataState,
} from './../results/resultsactions';
import ResultItem from './resultitem';

const CityResult = props => {
  const activityTypes = [
    'ADVENTURE',
    'NIGHTTIME',
    'WELLNESS',
    'KIDSPLAY',
    'TOURINGBALI',
    'GETWET',
    'ADRENALIN',
    'TRANSFERS',
    'CULTURALSITES',
  ];

  const { clientTypeInt } = useParams();

  const resultsReducer = useSelector((state: any) => state.resultsReducer);
  const keywordReducer = useSelector((state: any) => state.keywordReducer.keyword);
  const getCityIndex = (cityParam: string): number => {
    switch (cityParam) {
      case 'Bali':
        return 0;
      case 'Lombok':
        return 1;
      case 'Nusa Lembongan':
        return 2;
      default:
        return 0;
    }
  };

  const getSelectedActivityType = () => {
    if (clientTypeInt && parseInt(clientTypeInt) < 0) return '';
    if (clientTypeInt) return activityTypes[parseInt(clientTypeInt) - 4];
    return '';
  };

  const dispatch = useAppDispatch();

  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);

  const apiUrl = `/api/home/get-sortable-pagable-results?cityName=${selectedCity}&keyword=${keywordReducer}&page=${
    resultsReducer[getCityIndex(selectedCity)]?.currentPage
  }&size=12&activityType=${getSelectedActivityType()}`;

  const fetchCityResults = async () => {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
      dispatch(getResultsData({ ...data }, selectedCity));
      dispatch(getOrderedResultsData({ ...data }, selectedCity));
      console.log('City results loaded ' + apiUrl);
    } catch (error) {
      console.log('Error fetching activities:', error);
    }
  };
  function splitArrayIntoChunks(array, chunkSize) {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunks.push(chunk);
    }
    return chunks;
  }

  useEffect(() => {
    fetchCityResults();
  }, [selectedCity]);

  useEffect(() => {}, [resultsReducer[getCityIndex(selectedCity)]?.sortedOrFiltererdResults]);

  const getImageForCity = (city: string): number => {
    switch (city) {
      case 'Bali':
        return 190;
      case 'Lombok':
        return 189;
      case 'Nusa Lembongan':
        return 191;
    }
    return -1;
  };

  return (
    <div className="tw-bg-slate-50 tw-flex tw-flex-col tw-mt-[-50px] tw-z-0">
      <div className="tw-flex-col tw-overflow-hidden tw-self-stretch tw-relative tw-flex tw-min-h-[500px] tw-w-full tw-items-stretch tw-pt-5 tw-pb-12 tw-px-20 tw-max-md:max-w-full tw-max-md:px-5">
        <img
          loading="lazy"
          srcSet={`/api/home/image/${getImageForCity(selectedCity)}`}
          className="tw-absolute tw-h-full tw-w-full tw-object-cover tw-object-center tw-inset-0 tw-h-5/5"
        />
        <div className="tw-ml-[13%] tw-relative tw-text-white tw-text-5xl tw-whitespace-nowrap tw-ml-4 tw-mt-10 tw-self-start tw-max-md:ml-2.5 tw-max-md:mt-10">
          Explore
        </div>
        <div className="tw-ml-[13%] tw-relative tw-text-white tw-text-7xl tw-font-bold tw-max-w-[523px] tw-mt-3 tw-max-md:max-w-full tw-max-md:text-4xl">
          {selectedCity}.
        </div>
        <div className="tw-ml-[13%] tw-relative tw-text-white tw-text-xl tw-w-[441px] tw-mt-8 tw-max-md:max-w-full">
          Your Ultimate Guide to the Paradise Island
        </div>
        <div className="tw-ml-[13%] tw-relative tw-justify-center tw-items-stretch tw-flex tw-w-[521px] tw-max-w-full tw-flex-col tw-mt-10 tw-px-2.5 tw-py-2 tw-rounded-lg tw-border-[1.5px] tw-border-solid tw-border-zinc-100 tw-self-start">
          <div className="tw-justify-between tw-items-center tw-flex tw-gap-5 tw-max-md:max-w-full tw-max-md:flex-wrap">
            <div className="tw-text-neutral-300 tw-text-xl tw-font-light tw-grow tw-whitespace-nowrap tw-my-auto">
              What are you looking for {selectedCity}
            </div>
            <div className="tw-text-zinc-50 tw-text-base tw-font-medium tw-whitespace-nowrap tw-justify-center tw-items-stretch tw-self-stretch tw-bg-lime-500 tw-px-6 tw-py-4 tw-rounded-lg tw-max-md:px-5">
              SEARCH
            </div>
          </div>
        </div>
      </div>

      <DealTop></DealTop>

      <div className="tw-self-center tw-w-full tw-max-w-[1279px] tw-mt-10 tw-px-5 tw-max-md:max-w-full tw-max-md:mt-10">
        {/* <div className="tw-text-zinc-900 tw-text-6xl tw-font-bold tw-whitespace-nowrap tw-ml-10 tw-self-start tw-max-md:text-4xl tw-max-md:ml-2.5 tw-max-md:mt-10">
          Experiences
        </div>{' '} */}
        {/* <div className="tw-text-zinc-900 tw-text-xl tw-w-[630px] tw-max-w-full tw-ml-20 tw-mt-1 tw-self-start">
          Immerse yourself in the enchanting essence of Bali with our experts'
          curated experiences.
        </div>         */}
      </div>
      <div className="tw-self-center tw-w-full tw-max-w-[1279px] tw-mt-10 tw-px-5 tw-max-md:max-w-full tw-max-md:mt-10">
        <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
          <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[33%] tw-max-md:w-full tw-max-md:ml-0">
            <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-9">
              <div className="tw-text-zinc-900 tw-text-xl tw-whitespace-nowrap">Order By</div>
              <div className="tw-justify-center tw-items-stretch tw-border tw-flex tw-w-full tw-flex-col tw-mt-5 tw-px-8 tw-py-3 tw-rounded-[50px] tw-border-solid tw-border-zinc-500 tw-max-md:px-5">
                <div className="tw-items-stretch tw-flex tw-justify-between tw-gap-5">
                  <div className="tw-text-zinc-900 tw-text-xl tw-grow tw-whitespace-nowrap">Discount</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7cefc153dab1216f44c2cda7b0f9bc146e85070b4959f3b98c47c29ec7b8fa3b?apiKey=782db1e49b4d4af9acf49bc384033667&"
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-6 tw-justify-center tw-items-center tw-overflow-hidden tw-self-center tw-shrink-0 tw-max-w-full tw-my-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[33%] tw-ml-5 tw-max-md:w-full tw-max-md:ml-0">
            <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-9">
              <div className="tw-text-zinc-900 tw-text-xl tw-whitespace-nowrap">Activity Type</div>
              <div className="tw-justify-center tw-items-stretch tw-border tw-flex tw-w-full tw-flex-col tw-mt-5 tw-px-8 tw-py-3 tw-rounded-[50px] tw-border-solid tw-border-zinc-500 tw-max-md:px-5">
                <div className="tw-justify-between tw-items-stretch tw-flex tw-gap-5">
                  <div className="tw-text-zinc-900 tw-text-xl tw-grow tw-whitespace-nowrap">All</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/bcf4bcfe402f0d89ae8ea8d725c4c2ac5e93c9e4525a9f578d2763239608065e?apiKey=782db1e49b4d4af9acf49bc384033667&"
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-6 tw-justify-center tw-items-center tw-overflow-hidden tw-self-center tw-shrink-0 tw-max-w-full tw-my-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-items-stretch tw-w-[33%] tw-ml-5 tw-max-md:w-full tw-max-md:ml-0">
            <div className="tw-items-stretch tw-flex tw-grow tw-flex-col tw-max-md:mt-9">
              <div className="tw-text-zinc-900 tw-text-xl tw-whitespace-nowrap">Venue</div>
              <div className="tw-justify-center tw-items-stretch tw-border tw-flex tw-w-full tw-flex-col tw-mt-5 tw-px-8 tw-py-3 tw-rounded-[50px] tw-border-solid tw-border-zinc-500 tw-max-md:px-5">
                <div className="tw-justify-between tw-items-stretch tw-flex tw-gap-5">
                  <div className="tw-text-zinc-900 tw-text-xl tw-grow tw-whitespace-nowrap">Bali</div>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/272091af21214f8652423d2bbe3ec13314a2f5b8312bd9f52f0ba641a6ad45c5?apiKey=782db1e49b4d4af9acf49bc384033667&"
                    className="tw-aspect-square tw-object-contain tw-object-center tw-w-6 tw-justify-center tw-items-center tw-overflow-hidden tw-self-center tw-shrink-0 tw-max-w-full tw-my-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {resultsReducer[getCityIndex(selectedCity)]?.sortedOrFiltererdResults?.content &&
        splitArrayIntoChunks(resultsReducer[getCityIndex(selectedCity)]?.sortedOrFiltererdResults?.content, 4)?.map((subArray, index) => {
          return (
            <div key={index} className="tw-self-center tw-w-full tw-max-w-[1279px] tw-mt-10 tw-px-5 tw-max-md:max-w-full tw-max-md:mt-10">
              <div className="tw-gap-5 tw-flex tw-max-md:flex-col tw-max-md:items-stretch tw-max-md:gap-0">
                {subArray.map((actItem, index) => {
                  return <ResultItem key={index} data={actItem}></ResultItem>;
                })}
              </div>
            </div>
          );
        })}

      <div className="tw-text-zinc-50 tw-text-base tw-font-medium tw-whitespace-nowrap tw-justify-center tw-items-stretch tw-bg-lime-500 tw-self-center tw-mt-11 tw-px-6 tw-py-4 tw-rounded-lg tw-max-md:mt-10 tw-max-md:px-5">
        LOAD MORE RESULTS
      </div>
    </div>
  );
};

export default CityResult;
