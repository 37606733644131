import dayjs from 'dayjs';
import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityPrice {
  id?: number;
  fromPax?: number | null;
  toPax?: number | null;
  fromDate?: string | null;
  toDate?: string | null;
  adultPrice?: number | null;
  childPrice?: number | null;
  infantPrice?: number | null;
  note?: string | null;
  userName?: string | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IActivityPrice> = {};
