import './landing.scss';
import './layout.css';

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'app/shared/components/ui/button';
import BookingTimer from './experience/bookingtimer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import LoginBooking from './loginbooking';
import LoadingDialog from './loadingdialog';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './stripe/stripepayment';

export const Booking = () => {
  const { bookingId } = useParams();
  const [editLeadPax, setEditLeadPax] = useState(false);
  const [editOtherPax, setEditOtherPax] = useState(false);
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  //const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const [showModal, setShowModal] = useState(false);
  //const account = useAppSelector(state => state.authentication);
  const [userDetails, setUserDetails] = useState({});

  const initailBooking = {
    id: -1,
    bookingUser: { firsName: '', lastName: '', email: '' },
    bookingPaxes: [],
    activity: { id: -1, name: '' },
    bookingStatus: '',
    adultCount: 0,
    bookingDate: '',
    bookingPayments: [],
    totalPrice: 0,
    createdDate: '',
    bookingTimes: [],
  };

  const [booking, setBooking] = useState(null);
  const [activity, setActivity] = useState(null);

  const handleInputChange = (index: number, event) => {
    const { name, value } = event.target;
    const updatedUsers = [...booking.bookingPaxes];
    updatedUsers[index] = { ...updatedUsers[index], [name]: value };

    setBooking({
      ...booking,
      bookingPaxes: updatedUsers,
    });
  };

  const getStatusMapping = (bookingStatus: string): number => {
    switch (bookingStatus) {
      case 'UNPAID':
        return 0;
      case 'LEAD_PAX_UPDATED':
        return 1;
      case 'OTHER_PAX_UPDATED':
        return 2;
      case 'PAID':
        return 3;
      default:
        return -1;
    }
  };

  const handleMainUserChange = event => {
    const { name, value } = event.target;
    setBooking({
      ...booking,
      bookingUser: {
        ...booking.bookingUser,
        [name]: value,
      },
    });
  };

  const handlePaymentData = event => {
    const { name, value } = event.target;
    const updatedBookingPayments = [...booking.bookingPayments];
    updatedBookingPayments[0] = { ...updatedBookingPayments[0], [name]: value };
    setBooking({
      ...booking,
      bookingPayments: updatedBookingPayments,
    });
  };

  const updateBooking = async (bookingStatus: string) => {
    const updatesBooking = { ...booking, bookingStatus: bookingStatus };
    const response = await axios
      .post('/api/home/updateBooking', updatesBooking)
      .then(response => {
        const responseBody = response.data;
        navigate(`/booking/${responseBody.id}`);
        window.location.reload();
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
        setSaving(false);
      });
  };

  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    //fetch("http://localhost:4242/config").then(async (r) => {
    fetch('/api/home/config').then(async r => {
      const { publishableKey } = await r.json();
      console.log('------------------------------------------ ' + publishableKey);
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  const handleRatingChange = event => {
    setRating(Number(event.target.value));
  };

  const handleCommentChange = event => {
    setComment(event.target.value);
  };

  const submitReview = async event => {
    event.preventDefault();
    // You can perform any necessary submission logic here
    try {
      const reviewData = {
        noOfStars: rating,
        commnet: comment,
        bookingId: bookingId,
        activityId: booking?.activity?.id,
      };

      // Make the API call to submit the data
      const response = await axios
        .post('/api/home/save-review', reviewData)
        .then(response => {
          const responseBody = response.data;
          navigate(`/booking/${responseBody.id}`);
          window.location.reload();
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });

      // Handle the response or any additional logic
      console.log('Booking Saved');
    } catch (error) {
      // Handle the error
    }
  };

  const formData = {
    bookingId: -1,
    passengerDTOList: [], // Array to store passenger data
    cardNo: (document.getElementById('cardNo') as HTMLInputElement)?.value,
    expiryDate: (document.getElementById('expiryDate') as HTMLInputElement)?.value,
    cv2: (document.getElementById('cv2') as HTMLInputElement)?.value,
    nameOnCard: (document.getElementById('nameOnCard') as HTMLInputElement)?.value,
  };

  useEffect(() => {
    fetchBooking();
  }, []);

  useEffect(() => {
    if (bookingId && parseInt(bookingId) > 0 && booking?.activity?.id && booking?.activity?.id > 0) fetchActivity();
  }, [booking?.id]);

  useEffect(() => {
    fetchUserDetails();
  }, [isAuthenticated]);

  const fetchBooking = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/home/get-booking?bookingId=${bookingId}`);
      const data = await response.json();
      setBooking(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Error fetching activities:', error);
    }
  };

  const fetchActivity = async () => {
    try {
      const response = await fetch(`/api/home/activity-by-id-as-array?activityId=${booking?.activity?.id}`);
      const data = await response.json();
      setActivity(data);
    } catch (error) {
      console.log('Error fetching activities:', error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('/api/account');
      setUserDetails(response.data);
      const updatedBookingUser = {
        ...booking.bookingUser,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
      };
      const updatedBooking = { ...booking, bookingUser: updatedBookingUser };
      setBooking(updatedBooking);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error fetching user details:', error);
    }
  };

  const isMandatoryFieldsFilled = () => {
    return validateMailUser() && validatePax();
  };

  const validatePax = () => {
    for (let i = 0; i < booking.bookingPaxes.length; i++) {
      var pax = booking.bookingPaxes[i];
      if (pax.firsName == null || pax.firsName === '' || pax.lastName == null || pax.lastName === '') return false;
    }
    return true; // Return true if all objects pass validation
  };

  const validateMailUser = (): boolean => {
    const validated =
      booking?.bookingUser?.firsName != null &&
      booking?.bookingUser?.firsName !== '' &&
      booking?.bookingUser?.lastName != null &&
      booking?.bookingUser?.lastName !== '' &&
      booking?.bookingUser?.email != null &&
      booking?.bookingUser?.email !== '';
    return validated;
  };

  const saveBooking = async () => {
    setSaving(true);
    try {
      const formData = {
        bookingId: booking?.id,
        totalPrice: booking?.totalPrice,
        cardNo: (document.getElementById('cardNo') as HTMLInputElement)?.value,
        expiryDate: (document.getElementById('expiryDate') as HTMLInputElement)?.value,
        cv2: (document.getElementById('cv2') as HTMLInputElement)?.value,
        nameOnCard: (document.getElementById('nameOnCard') as HTMLInputElement)?.value,
      };

      // Make the API call to submit the data
      const response = await axios
        .post('/api/home/confirm-booking', formData)
        .then(response => {
          const responseBody = response.data;
          navigate(`/booking/${responseBody.id}`);
          window.location.reload();
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
          setSaving(false);
        });
      setSaving(false);
      // Handle the response or any additional logic
      console.log('Booking Saved');
    } catch (error) {
      console.error(error);
      setSaving(false);
      // Handle the error
    }
  };

  const parentClose = () => {
    setShowModal(false);
  };

  const isPastBookingDate = (): boolean => {
    const currentDate = new Date();
    const someDate = new Date(booking?.bookingDate);
    const daysDifference = 1;
    const dateToCompare = new Date(currentDate);
    dateToCompare.setDate(currentDate.getDate() - daysDifference);
    console.log('someDate ' + JSON.stringify(someDate));
    console.log('dateToCompare ' + JSON.stringify(dateToCompare));
    if (someDate <= dateToCompare) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div>
        {booking && (
          <Row>
            <Col md="2"></Col>
            <Col md="6">
              <>
                <Row>
                  <Col md="2">
                    <br />
                    <br />
                  </Col>
                  <Col md="8"> </Col>
                  <Col md="2"></Col>
                </Row>

                {booking.bookingStatus == 'PAID' && isPastBookingDate() && (
                  <>
                    <div className="container btdBackGroundColor mt-5">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <h1>We are pleased to inform you that your booking has been successfully paid in full</h1>
                          <p>We wish you a delightful and memorable trip.</p>
                        </div>
                      </div>

                      <div className="container mt-5 btdBackGroundColor">
                        <form onSubmit={submitReview}>
                          <div className="row">
                            <div className="col-md-6 text-end">
                              <label htmlFor="rating">Rating:</label>
                            </div>
                            <div className="col-md-6">
                              <select id="rating" value={rating} onChange={handleRatingChange}>
                                <option value={0}>Select a rating</option>
                                <option value={1}>1 star</option>
                                <option value={2}>2 stars</option>
                                <option value={3}>3 stars</option>
                                <option value={4}>4 stars</option>
                                <option value={5}>5 stars</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 text-end">
                              <label htmlFor="comment">Comment:</label>
                            </div>
                            <div className="col-md-6">
                              <textarea id="comment" value={comment} onChange={handleCommentChange} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              {' '}
                              <Button type="submit">Submit Review</Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}

                <div className="container boxShadow2">
                  <div className="row text-center">
                    <div className="col-md-12 border-bottom">
                      <h1>Booking Confirmation </h1>
                      <p className="paxCountLable">Please review the booking details and enter credit card details to make the payment</p>
                      <BookingTimer createdDate={booking?.createdDate}></BookingTimer>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 text-end">
                      <p>Reference :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Pax Count :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.adultCount}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Booking Date :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.bookingDate}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Booked User :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.bookingUser?.firsName}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Activity :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.activity?.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Total Price :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.totalPrice}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Eway Reference :</p>
                    </div>
                    <div className="col-md-6">
                      <p>{booking?.bookingPayments?.[0]?.paymentReference}</p>
                    </div>
                  </div>

                  {!isAuthenticated && (
                    <div className="row">
                      <div className="col-md-6 text-end">
                        <Button onClick={() => setShowModal(true)}>Login with BaliToDo</Button>
                      </div>
                      <div className="col-md-6 text-left">
                        <Button>Login with Google</Button>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md-6 text-end">
                      <p>Lead Passenger</p>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="firsName"
                        id="mainPaxFirstName"
                        value={booking?.bookingUser?.firsName || ''}
                        placeholder="First name"
                        onChange={handleMainUserChange}
                        disabled={!(editLeadPax || !validateMailUser() || booking.bookingStatus === 'UNPAID')}
                      />
                      <br />
                      <input
                        type="text"
                        name="lastName"
                        id="mainPaxLarstName"
                        value={booking?.bookingUser?.lastName || ''}
                        placeholder="Last name"
                        onChange={handleMainUserChange}
                        disabled={!(editLeadPax || !validateMailUser() || booking.bookingStatus === 'UNPAID')}
                      />
                      <br />
                      <input
                        type="text"
                        name="email"
                        id="mainPaxEmail"
                        value={booking?.bookingUser?.email || ''}
                        placeholder="Email"
                        onChange={handleMainUserChange}
                        disabled={!(editLeadPax || !validateMailUser() || booking.bookingStatus === 'UNPAID')}
                      />
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <p> </p>
                    </div>
                    <div className="col-md-6">
                      {booking.bookingStatus !== 'PAID' &&
                        (booking.bookingStatus === 'UNPAID' ||
                          (booking.bookingStatus === 'LEAD_PAX_UPDATED' && editLeadPax) ||
                          (booking.bookingStatus === 'OTHER_PAX_UPDATED' && editLeadPax)) && (
                          <>
                            <Button disabled={validateMailUser() === false} onClick={() => updateBooking('LEAD_PAX_UPDATED')}>
                              Update Lead Pax
                            </Button>{' '}
                            {editLeadPax && <Button onClick={() => setEditLeadPax(false)}>Cancel</Button>}
                          </>
                        )}
                      {booking.bookingStatus !== 'PAID' &&
                        (booking.bookingStatus === 'LEAD_PAX_UPDATED' || booking.bookingStatus === 'OTHER_PAX_UPDATED') &&
                        !editLeadPax && (
                          <p>
                            <Button onClick={() => setEditLeadPax(true)}>Edit Lead Pax</Button>{' '}
                          </p>
                        )}
                    </div>
                  </div>

                  {getStatusMapping(booking?.bookingStatus) >= 1 && (
                    <>
                      <div className="row">
                        {booking?.bookingPaxes?.map((bookingPax, index) => (
                          <div key={index}>
                            <div className="col-md-6 text-end">
                              <p>Passengers {index + 1}</p>
                            </div>
                            <div className="col-md-6"></div>
                            <input
                              type="text"
                              name="firsName"
                              id={`otherPaxFirstName-${index}`}
                              value={booking?.bookingPaxes[index]?.firsName}
                              placeholder="First name"
                              onChange={event => handleInputChange(index, event)}
                              disabled={!(editOtherPax || booking.bookingStatus === 'LEAD_PAX_UPDATED')}
                            />
                            <input
                              type="text"
                              name="lastName"
                              id={`otherPaxLastName-${index}`}
                              value={booking?.bookingPaxes[index]?.lastName}
                              placeholder="Last name"
                              onChange={event => handleInputChange(index, event)}
                              disabled={!(editOtherPax || booking.bookingStatus === 'LEAD_PAX_UPDATED')}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <p> </p>
                        </div>
                        <div className="col-md-6">
                          {booking.bookingStatus !== 'PAID' &&
                            (booking.bookingStatus === 'LEAD_PAX_UPDATED' ||
                              (booking.bookingStatus === 'OTHER_PAX_UPDATED' && editOtherPax)) && (
                              <>
                                <Button disabled={validatePax() == false} onClick={() => updateBooking('OTHER_PAX_UPDATED')}>
                                  Update Pax
                                </Button>{' '}
                                {editOtherPax && <Button onClick={() => setEditOtherPax(false)}>Cancel</Button>}
                              </>
                            )}
                          {booking.bookingStatus !== 'PAID' && booking.bookingStatus === 'OTHER_PAX_UPDATED' && !editOtherPax && (
                            <p>
                              <Button onClick={() => setEditOtherPax(true)}>Edit Other Pax</Button>{' '}
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  {getStatusMapping(booking?.bookingStatus) >= 2 && booking.bookingStatus != 'PAID' && (
                    <Payment stripePromise={stripePromise} bookingId={bookingId} />
                  )}
                </div>

                {/* <div>
                  {getStatusMapping(booking?.bookingStatus) >= 2 && booking.bookingStatus != 'PAID' && (
                    <>
                      <div className="container mt-4">
                        <div className="row text-center">
                          <div className="col-md-12 border-bottom">
                            <h1>Credit Card Deatils </h1>
                            <p className="paxCountLable">Enter credit card details to make the payment</p>
                          </div>
                        </div>
                        <div className="btdBackGroundColor mb-5">
                          <div className="row mt-3">
                            <div className="col-md-6 text-end">
                              <p>
                                <label htmlFor="cardNo">Card No:</label>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {' '}
                                <input type="text" id="cardNo" value="12121212" onChange={handlePaymentData} />
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 text-end">
                              <p>
                                {' '}
                                <label htmlFor="expiryDate">Expiry Date:</label>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {' '}
                                <input type="text" id="expiryDate" value="12/25" onChange={handlePaymentData} />
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 text-end">
                              <p>
                                {' '}
                                <label htmlFor="cv2">CV2:</label>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {' '}
                                <input type="text" id="cv2" value="123" onChange={handlePaymentData} />
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 text-end">
                              <p>
                                {' '}
                                <label htmlFor="nameOnCard">Name On Card:</label>
                              </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {' '}
                                <input type="text" id="nameOnCard" value="John Smith" onChange={handlePaymentData} />
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <p> </p>
                            </div>
                            <div className="col-md-6">
                              <p>
                                {' '}
                                <Button onClick={() => saveBooking()}>Confirm Booking</Button>{' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {booking.bookingStatus == 'PAID' && <></>}
                </div> */}
              </>
            </Col>
            <Col md="2">
              <br />
              <div>
                <div>
                  {activity &&
                    activity[0]?.activityImages
                      ?.slice(0, 2)
                      ?.map((image, index) => (
                        <img
                          key={index}
                          className="tw-w-full tw-object-cover tw-h-[250px] tw-my-10"
                          src={image.imageShortDescription}
                          alt="image"
                        />
                      ))}
                </div>
              </div>
            </Col>
            <Col md="2"></Col>
          </Row>
        )}
      </div>
      {showModal && !isAuthenticated && <LoginBooking parentClose={parentClose} bookingId={bookingId} fetchUserData={fetchUserDetails} />}
      <LoadingDialog isLoading={loading}></LoadingDialog>
    </>
  );
};

export default Booking;
