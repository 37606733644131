import React from 'react';
import { Col, Row } from 'reactstrap';

export const LoadingDialog = props => {
  return (
    <>
      {props.isLoading && (
        <Row>
          <Col md="4"></Col>
          <Col md="4">
            <div className="modal-dialog-activities ">
              <div className="modal-content-dialog-activities boxShadow2">
                <div className="spinner-border text-success spinner-position" role="status">
                  <span className="sr-only text-center">
                    <span className="dark"></span>
                  </span>
                </div>
              </div>
            </div>
          </Col>
          <Col md="4"></Col>
        </Row>
      )}
    </>
  );
};

export default LoadingDialog;
