import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, City } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Link, useNavigate } from 'react-router-dom';
import HeaderAccount from './headeraccount';
import HeaderEntities from './headerentities';
import HeaderCity from './v2/headercity';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isCustomer: boolean;
  isUser: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <div className="tw-bg-transparent tw-bg-opacity-25">
      <Row>
        <Col>
          <div
            className="tw-flow-root"
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              zIndex: '1030',
              height: '25px',
              width: '100%',
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <div className="tw-flex tw-float-left tw-m-1">
              <a href="https://www.instagram.com/bali2do" target="_blank">
                <img className="socila-media-icon" src="../../content/images/instagram.png" alt="" />
              </a>
              <a href="https://twitter.com/bali2do" target="_blank">
                <img className="socila-media-icon" src="../../content/images/twitter.png" alt="" />
              </a>
              <a href="https://www.facebook.com/bali2do" target="_blank">
                <img className="socila-media-icon" src="../../content/images/faceBook.png" alt="" />
              </a>
            </div>
            {props.isAuthenticated && (
              <div className="tw-flex tw-float-right tw-m-1 tw-mr-10 tw-text-white">
                <a href="/dataloading" className="tw-text-white">
                  Entities
                </a>
              </div>
            )}

            {/* <AdminMenu showOpenAPI={true}></AdminMenu> */}
            <HeaderAccount isAuthenticated={props.isAuthenticated}></HeaderAccount>

            {/* <HeaderEntities isAdmin={props.isAdmin} isCustomer={props.isCustomer} isUser={props.isUser}></HeaderEntities> */}
          </div>
        </Col>
      </Row>
      <Row>
        <HeaderCity></HeaderCity>
      </Row>
    </div>
  );
};

export default Header;
