import React, { useEffect, useState } from 'react';
import CategoryHeader from './categoryheader';
import HighlightsHeader from './highlights';
import { Col, Row } from 'reactstrap';
import { Button } from 'app/shared/components/ui/button';
import './resultsbody.scss';
import ShowMoreResults from './showmoreresults';
import LoadingDialog from '../loadingdialog';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useSelector } from 'react-redux';
import {
  getFilterByData,
  getOrderByData,
  getOrderedResultsData,
  getResultsData,
  getShowMapData,
  getCurrentPageData,
  getDealsData,
  getReplaceOrderedResultsData,
  getResetData,
} from './resultsactions';
import { useParams } from 'react-router-dom';
import Rating from '../experience/components/rating';
import ResultsMap from './resultsmap';
import { setSelectedCity } from '../../../shared/layout/header/headerreducer';

export const ResultsBody = props => {
  const activityTypes = [
    'ADVENTURE',
    'NIGHTTIME',
    'WELLNESS',
    'KIDSPLAY',
    'TOURINGBALI',
    'GETWET',
    'ADRENALIN',
    'TRANSFERS',
    'CULTURALSITES',
  ];

  const { clientTypeInt } = useParams();
  const dispatch = useAppDispatch();
  const resultsReducer = useSelector((state: any) => state.resultsReducer);
  const selectedCity = useSelector((state: any) => state.headerReducer.selectedCity);
  //const activityTypeReducer = useSelector((state: any) => state.activityTypeReducer.activityType);
  const [loading, setLoading] = useState(false);

  const { cityParam = null } = useParams();

  useEffect(() => {}, [props]);

  const getCityIndex = (cityParam: string): number => {
    switch (cityParam) {
      case 'Bali':
        return 0;
      case 'Lombok':
        return 1;
      case 'Nusa Lembongan':
        return 2;
      default:
        return 0;
    }
  };

  //useEffect(() => {}, [resultsReducer[getCityIndex(cityParam)]?.showMap]);

  const handleSort = event => {
    dispatch(getOrderByData(event.target.value, cityParam));
    if (event.target.value === 'Discount') {
      const sortedActivities = [...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.content].sort(
        (a, b) => b.discount - a.discount
      );
      const newData = { ...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults, content: sortedActivities };

      dispatch(getReplaceOrderedResultsData(newData, cityParam));
    }
    if (event.target.value === 'Popularity') {
      const sortedActivities = [...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.content].sort(
        (a, b) => a.bookingCount - b.bookingCount
      );
      const newData = { ...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults, content: sortedActivities };
      dispatch(getReplaceOrderedResultsData(newData, cityParam));
    }
  };

  const handleFilterByActivity = event => {
    const selectedFilter = event.target.value;
    if (selectedFilter === 'All') {
      const filteredData = resultsReducer[getCityIndex(cityParam)]?.results?.content?.filter(item => item.id > 0);
      const newData = { ...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults, content: filteredData };
      dispatch(getReplaceOrderedResultsData(newData, cityParam));
    } else {
      const filteredData = resultsReducer[getCityIndex(cityParam)]?.results?.content?.filter(
        item => item.activityType === selectedFilter.toUpperCase()
      );
      const newData = { ...resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults, content: filteredData };
      dispatch(getReplaceOrderedResultsData(newData, cityParam));
    }
  };

  const showHideMap = (showHide: boolean) => {
    dispatch(getShowMapData(showHide, cityParam));
  };

  const setCurrentPage = pageNo => {
    dispatch(getCurrentPageData(pageNo, cityParam));
  };

  const setNextPage = () => {
    const nextPageNumber = resultsReducer[getCityIndex(cityParam)]?.currentPage + 1;
    dispatch(getCurrentPageData(nextPageNumber, cityParam));
  };

  const isLastPage = (): boolean => {
    const lastPage =
      resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.totalPages - 1 ==
      resultsReducer[getCityIndex(cityParam)]?.currentPage;
    return loading || lastPage;
  };

  const refreshResults = () => {
    dispatch(getResetData(cityParam));
    dispatch(setSelectedCity(cityParam));
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-7 pageNaviBar">
            <div className="row">
              <div className="tw-flex col-md-2 ">
                <img className="socila-media-icon2 filter-icon" src="../../content/images/filter.png" alt="" />
                <span className="tw-py-1 tw-px-2  textType1">Order By</span>
              </div>
              <div className="col-md-2">
                <select className="form-select textType1" onChange={handleSort} value={resultsReducer[getCityIndex(cityParam)]?.orderBy}>
                  <option className="textType1Height">Discount</option>
                  <option className="textType1Height">Popularity</option>
                </select>
              </div>
              <div className="tw-flex col-md-3">
                <span>
                  <img className="socila-media-icon2 filter-icon" src="../../content/images/filter.png" alt="" />
                </span>
                <label className="tw-py-1 tw-px-2 textType1">Activity Type</label>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select textType1"
                  onChange={handleFilterByActivity}
                  value={resultsReducer[getCityIndex(cityParam)]?.filterBy}
                >
                  <option value={''}>All</option>
                  <option value={'ADVENTURE'}>Adventure</option>
                  <option value={'NIGHTTIME'}>NightTime</option>
                  <option value={'WELLNESS'}>Wellness</option>
                  <option value={'KIDSPLAY'}>Kids</option>
                  <option value={'ADRENALIN'}>Adrenalin</option>
                  <option value={'GETWET'}>Aquatic</option>
                  <option value={'CULTURALSITES'}>Culture</option>
                  <option value={'TOURINGBALI'}>TouringBali</option>
                  <option value={'TRANSFERS'}>Transfers</option>
                </select>
              </div>
              <div className="col-md-1">
                &nbsp;
                {!resultsReducer[getCityIndex(cityParam)]?.showMap && (
                  <Button className="showMap tw-bg-white" onClick={() => showHideMap(true)}></Button>
                )}
                {resultsReducer[getCityIndex(cityParam)]?.showMap && (
                  <Button className="showResult tw-bg-white" onClick={() => showHideMap(false)}></Button>
                )}
              </div>
              <div className="col-md-1">
                <Button onClick={props.refreAction}>Refresh</Button>
              </div>
            </div>
          </div>
          <div className="col-md-5 pageNaviBar">
            <ShowMoreResults isDisabled={isLastPage()} nextPageAction={setNextPage}></ShowMoreResults>
          </div>
        </div>
      </div>

      {resultsReducer[getCityIndex(cityParam)]?.showMap && (
        <ResultsMap data={resultsReducer[getCityIndex(cityParam)]?.sortedOrFiltererdResults?.content}></ResultsMap>
      )}

      {!resultsReducer[getCityIndex(cityParam)]?.showMap && (
        <div>
          {props?.data?.map((value, index) => {
            if (index % 3 === 0) {
              // Start a new row for every third item
              return (
                <React.Fragment key={index}>
                  <div className="container">
                    <div className="row mb-5">
                      {[0, 1, 2].map(innerItem => {
                        const actItem = props?.data?.[index + innerItem];
                        return (
                          <div className="col-md-4 " key={index + innerItem}>
                            {actItem && (
                              <>
                                <div>
                                  <a href={`experience/${actItem?.id}`}>
                                    <img className="squarePic" src={actItem?.activityImages?.[0]?.imageShortDescription} alt="" />
                                  </a>
                                </div>
                                <div className="row">
                                  <div className="activityDivHeight mt-1">
                                    <a href={`experience/${actItem?.id}`}>
                                      <p className="activityTitle p-0 m-0 ">{actItem?.name?.slice(0, 50 - 3) + '...'}</p>
                                    </a>
                                  </div>

                                  <div className="col-md-8 activityDetails">
                                    <div className="activityDivHeight tw-flex">
                                      <Rating rating={Math.floor(actItem?.reviewRating)} className="tw-text-orange-400 tw-scroll-mr-5" />
                                      <span className="tw-scroll-pl-5 tw-text-gray-400 tw-text-sm">
                                        &nbsp;&nbsp;{actItem?.reviewRating?.toFixed(1)} / {actItem?.reviewCount} reviews
                                      </span>
                                    </div>
                                    {actItem?.discount > 0 && (
                                      <div className="activityDivHeight">
                                        <span className="activitySubLineOne">Deal Window {actItem?.bestBetween}</span>
                                      </div>
                                    )}
                                    {actItem?.discount > 0 && (
                                      <div className="activityDivHeight mt-2 ">
                                        <span className="activityDiscount ">{Math.floor(actItem?.discount)} % Off</span>
                                        <span className="activityPax ms-2">{actItem?.availabilityCount} Pax</span>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-md-4 text-end">
                                    <div className="d-flex justify-content-end">
                                      <span className="activityPriceFrom">from</span>
                                      <h3 className="activityPrice">${actItem?.minPrice}</h3>
                                    </div>
                                    {actItem?.discount > 0 && (
                                      <span className="activitySubLineOne d-block">
                                        (Save up to ${actItem?.maxPrice - actItem?.minPrice})
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div>
      )}
      <ShowMoreResults isDisabled={isLastPage()} nextPageAction={setNextPage}></ShowMoreResults>
      <br />
      <LoadingDialog isLoading={props?.loading}></LoadingDialog>
    </>
  );
};
export default ResultsBody;
