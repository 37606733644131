import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import Login from '../../../modules/login/login';

const HeaderAccount = ({ isAuthenticated = false }) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const dispatch = useAppDispatch();

  return (
    <div className="tw-flex tw-float-right tw-text-white tw-align-top">
      {!isAuthenticated && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ borderRadius: '0px' }}>
          <DropdownToggle
            caret
            style={{
              fontSize: '14px',
              padding: '0px',
              marginTop: '-5px',
              background: 'orange',
              height: '23px',
              color: 'black',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '0px',
            }}
          >
            My Account
          </DropdownToggle>
          <DropdownMenu style={{ zIndex: '999' }}>
            <DropdownItem
              // onClick={() => {
              //   navigate('/login');
              // }}
              onClick={() => setShowLoginModal(true)}
            >
              <Translate contentKey="global.menu.account.login">Sign in</Translate>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate('/account/register');
              }}
            >
              <Translate contentKey="global.menu.account.register">Register</Translate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}

      {isAuthenticated && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ borderRadius: '0px' }}>
          <DropdownToggle
            caret
            style={{
              fontSize: '14px',
              padding: '0px',
              marginTop: '-5px',
              background: 'orange',
              height: '23px',
              color: 'black',
              paddingLeft: '5px',
              paddingRight: '5px',
              borderRadius: '0px',
            }}
          >
            My Account
          </DropdownToggle>
          <DropdownMenu style={{ zIndex: '999' }}>
            <DropdownItem
              onClick={() => {
                navigate('/account/settings');
              }}
            >
              <Translate contentKey="global.menu.account.settings">Settings</Translate>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate('/account/password');
              }}
            >
              <Translate contentKey="global.menu.account.password">Password</Translate>
            </DropdownItem>
            <DropdownItem
              // onClick={() => {
              //   navigate('/logout');
              // }}
              onClick={() => {
                setShowLoginModal(false);
                dispatch(logout());
              }}
            >
              <Translate contentKey="global.menu.account.logout">Sign out</Translate>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}

      {showLoginModal && !isAuthenticated && <Login></Login>}
    </div>
  );
};
export default HeaderAccount;
