import dayjs from 'dayjs';
import { IBooking } from 'app/shared/model/booking.model';

export interface IBookingPament {
  id?: number;
  totalPaid?: number | null;
  totalDiscount?: number | null;
  paymentDate?: string | null;
  paymentMethod?: string | null;
  userEmail?: string | null;
  booking?: IBooking | null;
}

export const defaultValue: Readonly<IBookingPament> = {};
