import React from 'react';
import { Link } from 'react-router-dom';
import './layout.css';
import './homeactivitylombok.scss'

const  HomeActivityLomBok = () => {

  return (
    <>
    <div className='commonBG'>
      <div className="container p-5" style={{ marginTop: '10px' }}>
        <div className="row">
          <div className="col-md-4 pt-3">
            <Link to="/customer/13">
              <img src="../../content/images/lombok-1.jpg" className="img-fluid sliderPhoto" alt="Activity 1" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/14">
              <img src="../../content/images/lombok-5.jpg" className="img-fluid sliderPhoto" alt="Activity 2" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/15">
              <img src="../../content/images/lombok-3.jpg" className="img-fluid sliderPhoto" alt="Activity 3" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pt-3">
            <Link to="/customer/16">
              <img src="../../content/images/lombok-7.jpg" className="img-fluid sliderPhoto" alt="Activity 4" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/17">
              <img src="../../content/images/lombok-8.jpg" className="img-fluid sliderPhoto" alt="Activity 5" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/18">
              <img src="../../content/images/lombok-6.jpg" className="img-fluid sliderPhoto" alt="Activity 6" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 pt-3">
            <Link to="/customer/19">
              <img src="../../content/images/lombok-4.jpg" className="img-fluid sliderPhoto" alt="Activity 7" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/20">
              <img src="../../content/images/lombok-9.jpg" className="img-fluid sliderPhoto" alt="Activity 8" />
            </Link>
          </div>
          <div className="col-md-4 pt-3">
            <Link to="/customer/21">
              <img src="../../content/images/lombok-2.jpg" className="img-fluid sliderPhoto" alt="Activity 9" />
            </Link>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default HomeActivityLomBok;
