import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityImage from './activity-image';
import ActivityImageDetail from './activity-image-detail';
import ActivityImageUpdate from './activity-image-update';
import ActivityImageDeleteDialog from './activity-image-delete-dialog';

const ActivityImageRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityImage />} />
    <Route path="new" element={<ActivityImageUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityImageDetail />} />
      <Route path="edit" element={<ActivityImageUpdate />} />
      <Route path="delete" element={<ActivityImageDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityImageRoutes;
