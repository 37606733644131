import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SiteSettings from './site-settings';
import SiteSettingsDetail from './site-settings-detail';
import SiteSettingsUpdate from './site-settings-update';
import SiteSettingsDeleteDialog from './site-settings-delete-dialog';

const SiteSettingsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SiteSettings />} />
    <Route path="new" element={<SiteSettingsUpdate />} />
    <Route path=":id">
      <Route index element={<SiteSettingsDetail />} />
      <Route path="edit" element={<SiteSettingsUpdate />} />
      <Route path="delete" element={<SiteSettingsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SiteSettingsRoutes;
