import React from 'react';
import { Link } from 'react-router-dom';
import './layout.css';
import './homeactivity.scss';

const  HomeActivityBali = () => {

  return (
    <>
    <div className='commonBG'>
      <div className="container p-5 ">
        <div className="row p-5 pb-0 pt-0">
          <div className="col-md-4 pt-3 relateDiv">
            <h3>Adventure</h3>
            <p>Bali is packed full of fantastic<br/> adventure activities.</p>
            <Link to="/customer/4">
              <img src="../../content/images/adventure_1_1.png" className="img-fluid sliderPhoto" alt="Activity 1" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Night</h3>
            <p>Night time is party time on the <br/>Island of the Gods.</p>
            <Link to="/customer/5">
              <img src="../../content/images/night_1_1.png" className="img-fluid sliderPhoto" alt="Activity 2" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Wellness</h3>
            <p>Into wellness and physical health.<br/>  Look no further.</p>
            <Link to="/customer/6">
              <img src="../../content/images/wellness_1_1.png" className="img-fluid sliderPhoto" alt="Activity 3" />
            </Link>
          </div>
        </div>
        <div className="row  p-5 pb-0 pt-0">
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Kids</h3>
            <p>Looking for something that will entertain <br/> the kids for hours.</p>
            <Link to="/customer/7">
              <img src="../../content/images/kids_1_1.png" className="img-fluid sliderPhoto" alt="Activity 4" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Adrenalin</h3>
            <p>Get the heart started.<br/> Check out some of these great deals.</p>
            <Link to="/customer/8">
              <img src="../../content/images/adrenalin_1_1.png" className="img-fluid sliderPhoto" alt="Activity 5" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Aquatic</h3>
            <p>Bali is jam packed full of <br/>water orientated activities.</p>
            <Link to="/customer/9">
              <img src="../../content/images/aquatic_1_1.png" className="img-fluid sliderPhoto" alt="Activity 6" />
            </Link>
          </div>
        </div>
        <div className="row  p-5 pb-0 pt-0">
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Culture</h3>
            <p>Bali abounds with cultural <br/>and historical sites.</p>
            <Link to="/customer/10">
              <img src="../../content/images/cultural_1_1.png" className="img-fluid sliderPhoto" alt="Activity 7" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Touring</h3>
            <p>Want to get off the beaten track <br/> and truly explore Bali.</p>
            <Link to="/customer/11">
              <img src="../../content/images/touring_1_1.png" className="img-fluid sliderPhoto" alt="Activity 8" />
            </Link>
          </div>
          <div className="col-md-4 pt-3 relateDiv">
          <h3>Transfers</h3>
            <p>From Nusa Lembongan to the <br/>Gillis and further afield.</p>
            <Link to="/customer/12">
              <img src="../../content/images/water_1_1.png" className="img-fluid sliderPhoto" alt="Activity 9" />
            </Link>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default HomeActivityBali;
