import './landing.scss';
import './activity.scss';
import './layout.css';

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
//import chunk from 'lodash.chunk';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Button } from 'app/shared/components/ui/button';
import { useSelector } from 'react-redux';
import BookingTimer from './experience/bookingtimer';
//import {BookingTimer} from '../../modules/customer/experience/bookingtimer';

export const PaxDetails = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userEmail: '',
    cardNo: '',
    expiryDate: '',
    cv2: '',
    nameOnCard: '',
  });

  const activitySelector = useSelector((state: any) => state.customerReducer.activity);
  const paxDataSelector = useSelector((state: any) => state.customerReducer.paxData);
  const selectedDateSelector = useSelector((state: any) => state.customerReducer.selectedDate);
  const selectedTimeSelector = useSelector((state: any) => state.customerReducer.selectedTime);

  const isFormFilled = formData.firstName !== '' && formData.lastName !== '' && formData.userEmail !== '';

  const getTotalPrice = () => {
    let paxTotalPrice = 0;
    paxDataSelector.map(data => {
      paxTotalPrice = paxTotalPrice + data.paxCount * data.perPaxPrice;
    });
    return paxTotalPrice;
  };

  const getTotalPax = () => {
    let totalPax = 0;
    paxDataSelector.map(data => {
      totalPax = totalPax + data.paxCount;
    });
    return totalPax;
  };

  const saveBooking = async () => {
    try {
      const formDataToSubmit = {
        availabilityDateData: selectedDateSelector,
        firstName: formData.firstName,
        lastName: formData.lastName,
        userEmail: formData.userEmail,
        cardNo: formData.cardNo,
        expiryDate: formData.expiryDate,
        cv2: formData.cv2,
        nameOnCard: formData.nameOnCard,
        activityId: activitySelector.activity.id,
        totalPrice: getTotalPrice(),
        availabilityType: selectedTimeSelector?.availabilityType,
        activityAvailabilityId: selectedTimeSelector?.activityAvailabilityId ? selectedTimeSelector?.activityAvailabilityId : -1,
        activityTimeAvailabilityId: selectedTimeSelector?.activityTimeAvailabilityId
          ? selectedTimeSelector?.activityTimeAvailabilityId
          : -1,
        weekDayTimeId: selectedTimeSelector?.weekDayTimeId ? selectedTimeSelector?.weekDayTimeId : -1,
        twentyFourHourTime: selectedTimeSelector?.twentyFourHourTime,
        passengerDTOList: [],
      };

      for (let index = 0; index < getTotalPax() - 1; index++) {
        const passenger = {
          firstName: (document.getElementById(`firstName${index}`) as HTMLInputElement)?.value,
          lastName: (document.getElementById(`lastName${index}`) as HTMLInputElement)?.value,
        };
        formDataToSubmit.passengerDTOList.push(passenger);
      }

      // Make the API call to submit the data
      const response = await axios
        .post('/api/home/saveBooking', formDataToSubmit)
        .then(response => {
          const responseBody = response.data;
          navigate(`/booking/${responseBody.id}`);
          console.log(JSON.stringify(responseBody));
        })
        .catch(error => {
          // Handle error
          console.error('Error:', error);
        });

      // Handle the response or any additional logic
      console.log('Booking Saved');
    } catch (error) {
      // Handle the error
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="">
      {selectedDateSelector && (
        <>
          <div className="container bookingHeader mt-5">
            <div className="row text-center ">
              <div className="col-md-12  ">
                <h1>Booking Summary </h1>
                <p className="paxCountLable">Please review the booking details and proceed by clicking on the proceed button</p>
              </div>
              {/* <div>
                <BookingTimer></BookingTimer> <p>remains to retain this booking</p>
              </div> */}
            </div>
          </div>

          <div className="btdBackGroundColor container mb-5">
            <div className="row mt-3">
              <div className="col-md-6 text-end">
                <p>Total available adult count :</p>
              </div>
              <div className="col-md-6">{selectedDateSelector.adultCount}</div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">Adult count for this booking</div>
              <div className="col-md-6">{getTotalPax()}</div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">Activity Date :</div>
              <div className="col-md-6">{selectedDateSelector.formattedDate}</div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">Activity ID :</div>
              <div className="col-md-6">{activitySelector.activity.id}</div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">
                {' '}
                <p>Total Price : </p>
              </div>
              <div className="col-md-6">
                {' '}
                <p> {getTotalPrice()}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">
                <label htmlFor="mailPaxFirstName">First Name: </label>
              </div>
              <div className="col-md-6 text-start">
                {' '}
                <p>
                  {' '}
                  <input
                    type="text"
                    name="firstName"
                    id="mailPaxFirstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First name"
                  />
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">
                {' '}
                <p>
                  <label htmlFor="mailPaxLastName">Last Name:</label>{' '}
                </p>
              </div>
              <div className="col-md-6 text-start">
                {' '}
                <p>
                  <input
                    type="text"
                    name="lastName"
                    id="mailPaxLastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last name"
                  />{' '}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-end">
                {' '}
                <p>
                  {' '}
                  <label htmlFor="mainPaxEmail">Email:</label>
                </p>
              </div>
              <div className="col-md-6 text-start">
                {' '}
                <p>
                  {' '}
                  <input
                    type="text"
                    name="userEmail"
                    id="mainPaxEmail"
                    value={formData.userEmail}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </p>
              </div>
            </div>
            {Array.from({ length: getTotalPax() - 1 }, (_, index) => (
              <React.Fragment key={index}>
                <div className="row">
                  <div className="col-md-6 text-end">
                    <label htmlFor={`firstName${index}`}>First Name:</label>
                  </div>
                  <div className="col-md-6 text-start">
                    <input type="text" id={`firstName${index}`} />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6 text-end">
                    <label htmlFor={`lastName${index}`}>Last Name:</label>
                  </div>
                  <div className="col-md-6 text-start">
                    <input type="text" id={`lastName${index}`} />
                  </div>
                </div>
              </React.Fragment>
            ))}
            <br />
            <br />
            <div className="row">
              <div className="col-md-6 text-end">
                {' '}
                <p> </p>
              </div>
              <div className="col-md-6 text-start">
                {' '}
                <Button disabled={!isFormFilled} onClick={() => saveBooking()}>
                  Proceed Booking
                </Button>{' '}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PaxDetails;
