import dayjs from 'dayjs';
import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityReview {
  id?: number;
  reviewDate?: string | null;
  noOfStars?: number | null;
  reviewdUser?: string | null;
  commnet?: string | null;
  activity?: IActivity | null;
  approved?: boolean | null;
  bookingId?: number | null;
  recommend?: boolean | null;
}

export const defaultValue: Readonly<IActivityReview> = {};
